import React,{useState, useContext, useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {constructionDocument} from '@gull'
import axios from 'axios'
import { IsAuthContext } from 'app/App';
import { useParams } from "react-router-dom";
import swal from "sweetalert2";
import { useForm } from "react-hook-form";

const EditPriceForm =  (props) =>{
    const t =useTranslate()
    const [inputValue,setInputValue]=useState('')
    const [validButton,setValidButton]=useState(false)
    const [switchDiscountedItems,setSwitchDiscountedItems]=useState(false)
    const { posid } = useParams();
    const {authParam} = useContext(IsAuthContext);
    const idsLocalStorage= (props.module=='pos') ? localStorage.getItem("pos").split(',') : [0,0]
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    const [activePromo,setActivePromo] = useState(false)

    let currentShop = authParam.sections.find(value=>value.id == posid)

    let addOn = (props.isFor == 'percent') ? '%' : '-'
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    let placeHolder //on détermine le placeHolder de chaque formulaires
    if(props.isFor=='percent'){
        placeHolder=t('discountPercent')

    }else if(props.isFor=='net'){
        placeHolder=t('discountNet')

    }else{
        placeHolder=t('newPrice')
    }

    useEffect(function () {
        if(props.item.note && props.item.note!=null){
            setValue('memo',props.item.note)
        }
        
    }, [props.item.note])

    useEffect(function () {
        //Sur le pos, quand on applique une remise sur l'ensemble du panier, on vérifie si il y a des articles en promotion ou remsé pour afficher la checkbox
        if(props.forGroup && props.module=='pos'){
            let actifPromo = props.groupId.find(value=> value.promotion!=null || value.remise!=0)
            setActivePromo(actifPromo)
        }
        
    }, [props.groupId])

    const handleChange = (e) =>{
        if(props.isFor=='percent' || digit==0){
            setInputValue(e.target.value.replace(/\D/g,''))
        }else{
            setInputValue(e.target.value.replace(/[^0-9\.]/g, ''))
        }
    }

    const callBackConstruct = (response) => {
        props.closeModal('price')
    };

    const submit = async (data) =>{
        if(props.module=='pos'){
            props.setDisabledPay(true)
            setValidButton(true)
            let obj={
                id:props.cookies.current_ticket,
                modif_manual_type:props.isFor,
                modif_manual_value:data.amount,
                modif_manual_reason:data.reason
            }

            if(props.forGroup){
                //Dans la cas d'une remisa manuelle sur la totalité du panier
                let arrIdsItem = []
                //groupId est la liste des articles 
                props.groupId.map(value=>{
                    if(switchDiscountedItems){
                        //Si switchDiscountedItems est true, on récupère que les articles sans remises et promotion
                        if(value.promotion==null && value.remise==0) arrIdsItem.push(value.id)
                    }else{
                        arrIdsItem.push(value.id)
                    }
                })
                obj['group_detail_id'] =arrIdsItem

            }else{
                //Dans le cas d'une remise manuelle sur un article
                obj['detail_id']=props.item.id
            }

            if(data.memo!=''){
                obj['modif_manual_memo']=data.memo
            }

            const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{   
                    setValidButton(false)
                    props.closeModal('price')
                    props.setCurrentTicket(response.data.data)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
            setValidButton(false)
        }else{
            let typeModifPrice = props.isFor=='percent' ? 'percent' : props.isFor=='net' ? 'franche' : 'prixfixe'
            let obj = {
                modif_manual_type:typeModifPrice,//{type possible: percent, franche, prixfixe}
                modif_manual_value:data.amount,
                modif_manual_note:data.reason,
            }
            if(props.forGroup) obj.group_detail_id=props.groupId
            else obj.detail_id=props.item.id
            constructionDocument(setValidButton,props.id,callBackConstruct,t,obj,props.setItemsBilling,props.setAllInfos)
        }
    }

    return(
        <form onSubmit={handleSubmit(submit)}>
            {(activePromo) &&
                <div className="mb-2">
                    <input type="checkbox" checked={switchDiscountedItems} className="form-group" onChange={()=>setSwitchDiscountedItems(!switchDiscountedItems)}/>&nbsp;&nbsp;
                    <label>{t('dontApplyDiscountedItem')}</label>
                </div>
            }
            <div className="input-group mb-3">
                {(props.isFor!='modif') ? 
                    <div className="input-group-prepend">
                        <div className="input-group-text rounded-0" >{addOn}</div>
                    </div>
                :
                    false
                }
                <input 
                    autoComplete='off' 
                    className='form-control mb-1' 
                    placeholder={placeHolder} 
                    value={inputValue} 
                    type="text" 
                    aria-describedby="basic-addon1" 
                    {...register('amount',{required: true, onChange :(e) => handleChange(e)})}
                />
            </div>
            {errors.amount?.type === 'required' && (
                <div className="text-danger mt-1 ml-2">
                    {t('fieldRequired')}
                </div>
            )}
            {(props.module=='pos') &&
                <select type="text" className="form-control mb-3" {...register('reason',{required: true})}>
                    <option value="">{'-- '+t('labelReason')+' --'}</option>
                    {(currentShop.raisonmodifprix != null) ?
                        currentShop.raisonmodifprix.map((value,index)=>(
                            <option key={index} value={value}>{value}</option>
                        ))
                    :
                        false
                    }
                </select>
            }
            {errors.reason?.type === 'required' && (
                <div className="text-danger mt-1 ml-2">
                    {t('fieldRequired')}
                </div>
            )}

            <label htmlFor="Memo">Note :</label>
            <textarea className="form-control" rows="8" {...register('memo')}></textarea>
            <button disabled={validButton} className="btn btn-primary mt-3">
                {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </form>
    )
}

export default EditPriceForm;