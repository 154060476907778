import React, { useEffect, useState,useContext} from 'react';
import { IsAuthContext } from 'app/App';
import { Loading, CardPerso, BtnImportModel, DeleteActionButton,ContraintesCustomFields, CsvActionButton, EditModalActionButton, EditCustomField, SubmitForm, BtnAccessReadWrite } from "@gull";
import { Modal, Button } from "react-bootstrap";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { CSVReader } from 'react-papaparse';
import swal from "sweetalert2";

const AdministrationCustomForm = (props) => {
    const t =useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue, reset } = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [csvData,setCsvData] = useState([])
    const [showCsv,setShowCsv] = useState(true)
    const [resetCsv,setResetCsv] = useState(false)
    const [showModal,setShowModal] = useState(false)
    const [modalEditId,setModalEditId] = useState(false)
    const {authParam} = useContext(IsAuthContext);

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        setSuccess(false)
        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/custom_fields", {
            form: data.form,
            name: data.name,
            required: data.required,
            free: data.free,
            list: csvData,
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                props.setCustomFields(response.data.datas)
                reset()
                setValue('free',false)
                setResetCsv(true)
                setShowCsv(true)
                setSuccess(true)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
            setResetCsv(false)
            setCsvData([])
        },1000)
        
    }

    const removeCustomField = async (customFieldsId) => {
        const response  = await axios.delete(process.env.REACT_APP_API_URL+"api/custom_fields/"+customFieldsId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                let updateList = props.customFields.filter(value=>value.id!=customFieldsId)
                props.setCustomFields(updateList)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                swal.fire(t('unknowError'), "", "error");
            }
            else if(error.response.status==403){
                swal.fire(t('forbiddenGeneral'), "", "error");
            }
            else {
                swal.fire(t('unknowError'), "", "error");
            }
        })
        setValidButton(false)
    }

    const handleOnDrop = (data,file) => {
        setApiError(false)
        //On calcule le poids du fichier en mégaoctets
        const fileSizeInMegabytes = file.size / (1024 * 1024);
        
        if (fileSizeInMegabytes <= 7) {
            let recupData = data.map(value=>value.data[0])
            setCsvData(recupData);
        }else{
            setApiError(t('fileCsvToLarge'))
        }
    };

    const handleOnError = (err, file, inputElem, reason) => {
        setCsvData([])
    };
    
    const handleOnRemoveFile = (data) => {
        setCsvData([])
    };

    const handleChangeFree = () => {
        if(showCsv){
            setCsvData([])
        }
        setShowCsv(!showCsv)
    }

    const handleChange = (e,name) =>{
        let inputValue = e.target.value.replace(/[^\w\s-]/g, '')
        setValue(name,inputValue)
    }

    return (<>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('editPersonalField')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditCustomField id={modalEditId} setCustomFields={props.setCustomFields} />
            </Modal.Body>
        </Modal>
        <CardPerso header={t('customField')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <input type="hidden" defaultValue={props.from}  {...register('form')}/>
                    <label htmlFor="nomcategorie">{t('categoryName')}</label>
                    <input id="name" className="form-control" type="text" {...register('name', {required: true, onChange:(e) => handleChange(e,'name')})}/>
                    {errors.name?.type === 'required' && (
                    <div className="text-danger mt-1 ml-2">
                        {t('categoryRequired')}
                    </div>
                    )}
                </div>
                <div className="form-check form-switch">
                    <input type="checkbox" className="form-check-input"  {...register('required')}/>
                    <label title="" className="form-check-label">{t('requiredField')}</label>
                </div>
                <div className="form-check form-switch">
                    <input type="checkbox" className="form-check-input"  {...register('free', {onChange:() => handleChangeFree()})} />
                    <label title="" className="form-check-label">{t('freeField')}</label>
                </div>
                {(showCsv) &&
                    <div className="form-group mt-3">
                        <div className="row mb-1">
                            <div className="col-6">
                                <label>{t('dataListpossible')}</label>
                            </div>
                            <div className="col-6 text-end">
                                <BtnImportModel
                                    modele={//Passer false si aucun modle à télécharger
                                        {
                                            fileName:t('template')+' '+t('customField'),
                                            data:[
                                                [t('firstValue')],
                                                [t('secondValue')],
                                                [t('thirdValue')],
                                                ["..."],
                                            ]
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <CSVReader
                            onDrop={handleOnDrop}
                            isReset={resetCsv}
                            onError={handleOnError}
                            onRemoveFile={handleOnRemoveFile}
                            addRemoveButton
                            config={{
                                delimiter:authParam.company.advanceparams.csv_separator,
                                encoding:authParam.company.advanceparams.csv_encode
                            }}
                        >
                            <span>{t('dropCsv')}</span>
                        </CSVReader>
                    </div>
                }
                <SubmitForm error={apiError} success={success} validButton={validButton} />
            </form>
            <hr className="offset-2 col-8"/>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{t('tableName')}</th>
                        <th>{t('tableContraintes')}</th>
                        <th>Permission</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {(props.customFields) && props.customFields.map((value,index)=>
                        <tr key={index}>
                            <td>{value.name}</td>
                            <td><ContraintesCustomFields required={value.required} free={value.free} /></td>
                            <td><BtnAccessReadWrite fieldName={value.name} accessAction={'article_'+value.name+'_@!'} company={props.company} allAccess={props.allAccess} setRelance={props.setRelance}/></td>
                            <td>
                                <span style={{marginRight: '10px'}}><EditModalActionButton setShowEdit={setShowModal} setModalEditId={setModalEditId} hisId={value.id} /></span>
                                <span style={{marginRight: '10px'}}><DeleteActionButton deleteFunctionArgument={value.id} toDelete={value.name} actionType={'typeDeleteX'} nameType={'delete'} customClickEvent={removeCustomField} /></span>
                                {(!value.free) &&
                                    <CsvActionButton fieldName={value.name} listData={value.list} />
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </CardPerso>
    </>)
}

export default AdministrationCustomForm;