import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FormatedPrice } from "@gull";
import axios from 'axios'
import swal from "sweetalert2";

const LineRemboursementRecord = (props) =>{
    const t =useTranslate()
    const [newPaymentMode,setNewPaymentMode]=useState('')
    const [noteRefundOther,setNoteRefundOther] = useState('')

    const [validButton,setValidButton]=useState(false) 

    const idsLocalStorage=(props.from=='pos' || (props.from=='commande' && props.module=='pos')) ? localStorage.getItem("pos").split(',') : [0,0]

    useEffect(function () {
        setNewPaymentMode(props.value.mode)
        setNoteRefundOther(props.value.note)
    }, [props.value])

    const submitApiRemboursementMode = async (obj) =>{
        setValidButton(true)
        const response = await axios.put(process.env.REACT_APP_API_URL+"api/pos/ticket/late_edit_ticket_remboursement/"+props.ticketId+'/'+props.value.id, obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{  
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setValidButton(false)
    }

    const submitRemboursementMode = async () =>{
        submitApiRemboursementMode({
            remboursement_mode:newPaymentMode,
            remboursement_note:noteRefundOther
        })
    }

    const handleChangePaymentMode = (e) =>{
        setNewPaymentMode(e.target.value)
        
        if(e.target.value == 'espece'){
            //on exécute le submit si le mode de remboursement est espece
            submitApiRemboursementMode({remboursement_mode:e.target.value})
        }
    }

    const handleChangeNote = (e) =>{
        setNoteRefundOther(e.target.value)
    }
    
    return(<>
            <tr className="position-relative">
                <td style={{width:'70%'}}>
                    <select disabled={(props.module=='pos' && props.value.pos_id!=idsLocalStorage[0]) || validButton} value={newPaymentMode} className="form-control" type="text" onChange={(e)=>handleChangePaymentMode(e)}>
                        <option value="">-- SELECT --</option>
                        <option value="espece">{t('espece')}</option>
                        <option value="autre">{t('other')}</option>
                    </select>
                    
                    {(newPaymentMode=='autre') ?
                        <textarea disabled={validButton} value={noteRefundOther} rows={5} className="form-control mt-1" onChange={(e)=>handleChangeNote(e)}></textarea>
                    :
                        false
                    }
                </td>
                <td className="text-end">
                    {(props.module=='pos') ? 
                        <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.montant} />
                    :
                        <input disabled type="text" className="form-control" value={props.value.montant}/>
                    }
                    {(newPaymentMode=='autre') ?
                        <button disabled={noteRefundOther=='' || validButton} className="btn btn-sm btn-primary mt-1" onClick={()=>submitRemboursementMode()}>
                            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    :
                        false
                    }
                </td>
            </tr>
        </>
    )    
}

export default LineRemboursementRecord;