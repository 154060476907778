import React from "react";

const FormatedPrice = (props)=>{

    let isInterger = (Number.isInteger(+props.priceToFormat)) ? true : false

    /* 
        langRegion représente le code langue et le code pays

        Ex : 'en-CA' 
              en = English (language code)
              CA = Canada (country code)
        
        Ex : 'fr-FR' 
              fr = French (language code)
              FR = France (country code)
    */

    const formatedPrice = new Intl.NumberFormat(props.langRegion, {
        style: 'currency',
        currency: (props.devise && props.devise!==null) ? props.devise : 'XPF',
        //on utilise la fonction formatedPrice dans le cas où digit est égale à 2 (deux chiffres apres la vigule)
        //mais si le chiffre passé n'a pas chiffre après, il ne faut pas que l'on passe .00 apres le chiffre
        //je passe donc 0 à digit pour que la fonction formate ce nombre comme si on avait configuré digit à 0
        minimumFractionDigits: (isInterger) ? 0 : props.digit 
    });

    const espace_mille = (nombre)=>{
        let number
        let prepend

        let numberSplited = (isInterger) ? false : nombre.toFixed(+props.digit).toString().split('.')//on vérifie si le chiffre est un chiffre à virgule pour le séparer sinon on récupère le chiffre envoyé en props 
        let toCompare = numberSplited ? +numberSplited[0] :  Math.round(nombre)
        let digitNumber = (isInterger || +props.digit==0) ? '' : ','+numberSplited[1]
        if(toCompare.toString().startsWith("-")){
            number = toCompare.toString().replace('-','')
            prepend = '-'
        }else{
            number = toCompare
            prepend = ''
        }
    
        if(number<=999) return prepend+number.toString()+digitNumber;
        if(number<=999999) return prepend+number.toString().slice(0,number.toString().length-3)+' '+number.toString().slice(-3)+digitNumber;
        if(number<=999999999) return prepend+number.toString().slice(0,number.toString().length-6)+' '+number.toString().slice(-6,-3)+' '+number.toString().slice(-3)+digitNumber;
    
    }

    return(
        <>
            <span className={props.style}>{
                (props.priceToFormat && props.priceToFormat!=0) ? 
                    espace_mille(+props.priceToFormat)+props.devise
                :   0+props.devise
                // (props.priceToFormat && props.digit==0) ? 
                //     espace_mille(props.priceToFormat)+props.devise 
                // : 
                //     (props.priceToFormat && props.digit!=0) ? 
                //         espace_mille(props.priceToFormat)+props.devise
                //         // formatedPrice.format(props.priceToFormat) 
                //     :   0
            }</span>
        </>
    )
}

export default FormatedPrice;