import {devises_list} from 'app/devises'
export const numberToWordsFr = ( nombre, devise ) =>{
        const toLetter = {
            0 : "zéro",
            1 : "un",
            2 : "deux",
            3 : "trois",
            4 : "quatre",
            5 : "cinq",
            6 : "six",
            7 : "sept",
            8 : "huit",
            9 : "neuf",
            10 : "dix",
            11 : "onze",
            12 : "douze",
            13 : "treize",
            14 : "quatorze",
            15 : "quinze",
            16 : "seize",
            17 : "dix-sept",
            18 : "dix-huit",
            19 : "dix-neuf",
            20 : "vingt",
            30 : "trente",
            40 : "quarante",
            50 : "cinquante",
            60 : "soixante",
            70 : "soixante-dix",
            80 : "quatre-vingt",
            90 : "quatre-vingt-dix",
        };
        
        let numberToLetter = "";
        let deviseLetter = devises_list.find(value=>value.code == devise);
        let nb = parseFloat(nombre.toString().replace(" ", ""));

        if (nombre.toString().length > 15) return "dépassement de capacité";
        if (isNaN(nb)) return "Nombre non valide";
        // if(devise == "XPF"){
        //     deviseLetter = " francs "
        // }
        // devises_list.find(devise=>devise.code == devise)

        let reste;
        let quotient

        let n = nombre.toString().length;
        switch( n ){
            case 1:
                numberToLetter = toLetter[nb];
                break;
            case 2:
                if(  nb > 19  ){
                    quotient = Math.floor(nb / 10);
                    reste = nb % 10;
                    if(  nb < 71 || (nb > 79 && nb < 91)  ){
                        if(  reste == 0  ) numberToLetter = toLetter[quotient * 10];
                        if(  reste == 1  ) numberToLetter = toLetter[quotient * 10] + "-et-" + toLetter[reste];
                        if(  reste > 1   ) numberToLetter = toLetter[quotient * 10] + "-" + toLetter[reste];
                    }else numberToLetter = toLetter[(quotient - 1) * 10] + "-" + toLetter[10 + reste];
                }else numberToLetter = toLetter[nb];
                break;

            case 3:
                quotient = Math.floor(nb / 100);
                reste = nb % 100;
                if(  quotient == 1 && reste == 0   ) numberToLetter = "cent";
                if(  quotient == 1 && reste != 0   ) numberToLetter = "cent" + " " + numberToWordsFr(reste);
                if(  quotient > 1 && reste == 0    ) numberToLetter = toLetter[quotient] + " cents";
                if(  quotient > 1 && reste != 0    ) numberToLetter = toLetter[quotient] + " cent " + numberToWordsFr(reste);
                break;
            case 4 :
            case 5 :
            case 6 :
                quotient = Math.floor(nb / 1000);
                reste = nb - quotient * 1000;
                if(  quotient == 1 && reste == 0   ) numberToLetter = "mille";
                if(  quotient == 1 && reste != 0   ) numberToLetter = "mille" + " " + numberToWordsFr(reste);
                if(  quotient > 1 && reste == 0    ) numberToLetter = numberToWordsFr(quotient) + " mille";
                if(  quotient > 1 && reste != 0    ) numberToLetter = numberToWordsFr(quotient) + " mille " + numberToWordsFr(reste);
                break;
            case 7:
            case 8:
            case 9:
                quotient = Math.floor(nb / 1000000);
                reste = nb % 1000000;
                if(  quotient == 1 && reste == 0  ) numberToLetter = "un million";
                if(  quotient == 1 && reste != 0  ) numberToLetter = "un million" + " " + numberToWordsFr(reste);
                if(  quotient > 1 && reste == 0   ) numberToLetter = numberToWordsFr(quotient) + " millions";
                if(  quotient > 1 && reste != 0   ) numberToLetter = numberToWordsFr(quotient) + " millions " + numberToWordsFr(reste);
                break;
            case 10:
            case 11:
            case 12:
                quotient = Math.floor(nb / 1000000000);
                reste = nb - quotient * 1000000000;
                if(  quotient == 1 && reste == 0  ) numberToLetter = "un milliard";
                if(  quotient == 1 && reste != 0  ) numberToLetter = "un milliard" + " " + numberToWordsFr(reste);
                if(  quotient > 1 && reste == 0   ) numberToLetter = numberToWordsFr(quotient) + " milliards";
                if(  quotient > 1 && reste != 0   ) numberToLetter = numberToWordsFr(quotient) + " milliards " + numberToWordsFr(reste);
                break;
            case 13:
            case 14:
            case 15:
                quotient = Math.floor(nb / 1000000000000);
                reste = nb - quotient * 1000000000000;
                if(  quotient == 1 && reste == 0  ) numberToLetter = "un billion";
                if(  quotient == 1 && reste != 0  ) numberToLetter = "un billion" + " " + numberToWordsFr(reste);
                if(  quotient > 1 && reste == 0   ) numberToLetter = numberToWordsFr(quotient) + " billions";
                if(  quotient > 1 && reste != 0   ) numberToLetter = numberToWordsFr(quotient) + " billions " + numberToWordsFr(reste);
                break;
        }
        /*respect de l'accord de quatre-vingt*/
        if( numberToLetter.substring(+numberToLetter.length - 12, 12) == "quatre-vingt" ) numberToLetter += "s";

        return numberToLetter+deviseLetter.name;
    }