import React, { useState, useRef, useContext } from "react";
import { IsAuthContext } from 'app/App';
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso, MatchCsv, BtnImportModel } from "@gull";
import axios from 'axios'
import { CSVReader } from 'react-papaparse';
import { CSVDownloader } from 'react-papaparse'

const ImportForm = (props) => {
    const t = useTranslate()

    const [showValidButton,setShowValidButton] = useState(false)
    const [preSendError,setPreSendError] = useState(false)
    const [csvHeader,setCsvHeader] = useState([])
    const [errorCsv,setErrorCsv] = useState(false)
    const {authParam} = useContext(IsAuthContext)

    const handleOnDrop = (data,file) => {
        props.setApiError(false)
        props.setSuccess(false)
        setErrorCsv(false)
        setPreSendError(false)
        setShowValidButton(false)

        //On calcule le poids du fichier en mégaoctets
        const fileSizeInMegabytes = file.size / (1024 * 1024);
    
        if(fileSizeInMegabytes <= 7) {
            if(data.length>1000){
                setErrorCsv(t('objectifCsvFileToLong'))
            }
            else if(data[0].data.length!=0){
                setCsvHeader(data[0].data)
                props.setCsvData(data)
                let verifDoublon = new Array()
                let valid=true
                props.selectMatch.current.forEach((r)=>{
                    if(r.value!='no'){
                        if(!verifDoublon.includes(r.value)){
                            verifDoublon.push(r.value)
                        }
                        else{
                            valid=false
                        }
                    }
                })
                if(!valid){
                    setPreSendError(t('doublonCsv'))
                }
                else{
                    let valid=true
                    props.optionMatchCsv.forEach((v,index) =>{
                        if(v.match(/\*$/)){
                            if(props.selectMatch.current[index].value=="no") valid=false
                        }
                    })
                    if(valid){
                        setShowValidButton(true)
                    }
                }
            }
            else setErrorCsv(t('noDataInCsv'))
        }else{
            setErrorCsv(t('fileCsvToLarge'))
        }
    };
    
    const handleOnError = (err, file, inputElem, reason) => {
        setErrorCsv(t('unknowError'))
        setCsvHeader([])
    };
    
    
    const handleOnRemoveFile = (data) => {
        props.setApiError(false)//no
        props.setSuccess(false)//no
        setErrorCsv(false)
        setCsvHeader([])
        setPreSendError(false)
        props.selectMatch.current = []//no
    };
   

    return(
        <>
            {(props.modele) &&
                <div className="form-group col-12">
                    <div className="col-12 mb-2">
                        <div className="row">
                            <div className="col-11">
                                <label htmlFor="fichier">Csv</label>
                            </div>
                            <div className="col-1" style={{textAlign:'right'}}>
                                <BtnImportModel
                                    modele={props.modele}
                                />
                            </div>
                        </div>
                    </div>
                    {(errorCsv) &&
                        <div className="alert alert-danger">{errorCsv}</div>
                    }
                    <CSVReader
                        onDrop={handleOnDrop}
                        onError={handleOnError}
                        addRemoveButton
                        onRemoveFile={handleOnRemoveFile}
                        config={{
                            delimiter:authParam.company.advanceparams.csv_separator,
                            encoding:authParam.company.advanceparams.csv_encode
                        }}
                    >
                    <span>{t('dropCsv')}</span>
                    </CSVReader>
                </div>
            }
            {(csvHeader.length!=0) &&
                <div className="col-12 mt-3">
                    <big>
                        {props.optionMatchCsv.map((value,index) =>
                            <MatchCsv  
                                key={index}
                                index={index}
                                optionMatchCsv={props.optionMatchCsv} 
                                toMatch={value} 
                                csvHeader={csvHeader} 
                                ref={props.selectMatch} 
                                preSendError={preSendError} 
                                setPreSendError={setPreSendError} 
                                showValidButton={showValidButton} 
                                setShowValidButton={setShowValidButton} 
                            />
                        )}
                    </big>
                    {(preSendError) &&
                        <div className="alert alert-danger text-center">{preSendError}</div>
                    }
                    {(props.success) ? <div className={"alert alert-"+props.success.colorAlert+" mt-3 text-center"}>{props.success.message}</div> : false}
                    {(props.apiError) ? <div className="alert alert-danger mt-3 text-center" dangerouslySetInnerHTML={{ __html: props.apiError }}></div> : false}
                    {(showValidButton) &&
                        <div className="col-12 text-center">
                            <button disabled={props.validButton} className="btn btn-primary" onClick={() => props.goImport()}>
                                {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnImport')}
                            </button>
                        </div>
                    }
                </div>
            }
            {(errorCsv) &&
                <div className="alert alert-danger text-center">{errorCsv}</div>
            }            
        </>
    )
}

export default ImportForm;
