import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice} from '@gull'

const CurrencyLineForm = (props) => {
    const t = useTranslate();

    let deviseAppend = (+props.currency < 1) ? '¢' : props.devise
    let formatCurrency = (+props.currency < 1) ? +props.currency * 100 : props.currency
    let digit = (+props.currency < 1) ? 2 : 0

    return(
        <div className="form-group">
            <div className="row">
                <div className="col-8">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span 
                                className="input-group-text text-white" 
                                style={{backgroundColor:"#323e48", borderColor:"#323e48"}}
                            >
                                {formatCurrency+' '+deviseAppend}
                            </span>
                        </div>
                        <input 
                            onFocus={()=>props.onfocusAction(props.currency+props.suppId)} 
                            style={{textAlign: "right"}} 
                            id={props.currency+props.suppId} 
                            type="number" 
                            min="0" 
                            className="form-control" 
                            value={props.detailCash[props.currency].nbr} 
                            onChange={(e) => props.calcSum(e,props.currency)} 
                        />
                    </div>
                </div>
                <div className="col-4">
                    <p style={{float:"right"}}>
                        <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={digit} priceToFormat={props.detailCash[props.currency].nbr*props.detailCash[props.currency].multiple} />
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CurrencyLineForm;