import React, {useState, useEffect,useContext} from 'react';
import axios from 'axios';
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso, Loading, ConfigSubmit, SelectFields, DeleteUserConfBtn} from "@gull";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';

const useLoadCustomInterface = (interfaceToLoad) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [reloadingConf, setReloadingConf]=useState(false)
    const [draggableContent, setDraggableContent]=useState([])
    const [defaultConfig, setDefaultConfig]=useState(false)
    const [emptyConf, setEmptyConf]=useState(false)
    const [noDefaultConf, setNoDefaultConf]=useState(false)

    useEffect(function () {
        (async function() {
            setReloadingConf(true)
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_interface/"+interfaceToLoad
            ).then((response) => {
                setLoading(false)
                setReloadingConf(false)
                if(defaultConfig){
                    if(!response.data.default_conf){
                        setNoDefaultConf(true)
                        setEmptyConf(false)
                    }else{
                        setEmptyConf(false)
                        setNoDefaultConf(false)
                        setDraggableContent(response.data.default_conf.details)
                    }
                }else{
                    if(!response.data.user_conf){
                        setEmptyConf(true)
                        setDraggableContent({})
                    }else{
                        setEmptyConf(false)
                        setDraggableContent(response.data.user_conf.details)
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [defaultConfig])
    return [loading,reloadingConf,draggableContent,setDraggableContent, setDefaultConfig, defaultConfig,emptyConf, setEmptyConf,noDefaultConf,setNoDefaultConf]
}

const FiltresCustomisation = (props) => {
    const { authParam } = useContext(IsAuthContext)
    const t = useTranslate();
    const [loading,reloadingConf,draggableContent,setDraggableContent,setDefaultConfig,defaultConfig,emptyConf, setEmptyConf,noDefaultConf,setNoDefaultConf]=useLoadCustomInterface(props.interface)

    const removeField = (valueToDelete) =>{
        let newDraggableContent = draggableContent.filter(value => value!= valueToDelete)
        setDraggableContent(newDraggableContent)
    }

    //Fonction qui retourne un nouveau tableau avec les nouvelles positions de chaque valeurs
    function onDragEnd(result) {
        //Si on sort du champ de drag and drop, on stop l'action 
       if (!result.destination) return;
        //On crée un nouveau tableau en récupérant chaque valeurs
        const items = Array.from(draggableContent);
        //On remplace les anciennes positons par les nouvelles positions
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        //On met à jour nos données 
        setDraggableContent(items);
    }

    const admin = authParam.modules.find((item) => item.name === "admin")

    if(loading && props.from==='tab'){
        return (<Loading></Loading>);
    }else if(loading){
        return (<span className='d-flex justify-content-center'><span>&nbsp;</span><div className="spinner-border spinner-border-lg"></div></span>)
    }

    return(
        <div className='col'> 
            {(admin)&&
                <div className='row mb-4'>
                    <div className="col-10 form-check form-switch">
                        <input type="checkbox" className="form-check-input"  onChange={()=>setDefaultConfig(!defaultConfig)} />
                        <label title="" className="form-check-label">{t('defaultConfig')}</label>
                    </div>
                </div>
            }
            <div className='row mb-3'>
                <h2 className='border-start border-dark'>
                    {(defaultConfig) ? t('defaultConfig')+' - '+t(props.moduleName) : t('personalizedConfig')+' - '+t(props.moduleName)}
                </h2>
            </div>
            {(reloadingConf) ?
                <div className="spinner-border spinner-border-lg"></div>
            :
                <>
                    {(!emptyConf ) && (!defaultConfig) && <DeleteUserConfBtn interface={props.interface} setDatas={setDraggableContent} setEmptyConf={setEmptyConf} setReload={props.setReload} />}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="row">
                            <div  className='col-3'>
                            <SelectFields {...props} setDatas={setDraggableContent} datas={draggableContent} multipleCards='false'  setEmptyConf={setEmptyConf} setNoDefaultConf={setNoDefaultConf}/>
                                <CardPerso header={(defaultConfig) ? t('defaultConfig') : t('personalizedConfig')} >
                                    {(loading) ?
                                        <div className='d-flex justify-content-center'>
                                            <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                                        </div>
                                    :
                                        <Droppable droppableId='fields' >
                                            {(provided)=> (
                                                <ul {...provided.droppableProps} ref={provided.innerRef} style={{paddingLeft:"0", listStyle: 'none'}}>
                                                    {(emptyConf) ?
                                                        <li style={{listStyle: 'none', textAlign: 'center'}}>{t('noUserConf')}</li>
                                                    :
                                                        (!defaultConfig && draggableContent.length===0) ?
                                                            <li style={{listStyle: 'none', textAlign: 'center'}}>{t('persoConfigVide')}</li>
                                                        :
                                                            (defaultConfig && noDefaultConf) ?
                                                                <li style={{listStyle: 'none', textAlign: 'center'}}>{t('noDefaultConf')}</li>
                                                            :
                                                                (defaultConfig && draggableContent.length===0) ?
                                                                    <li style={{listStyle: 'none', textAlign: 'center'}}>{t('defautConfigVide')}</li>
                                                                :
                                                                    (draggableContent.length>0) && draggableContent.map((value, index) => {
                                                                        return(
                                                                            <Draggable key={'field'+index} draggableId={'field'+index} index={index}>
                                                                                {(provided) => (
                                                                                    <div className="row">
                                                                                        <li
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            ref={provided.innerRef}
                                                                                            className="badge badge-primary bg-primary mb-1 p-2" 
                                                                                            value={value}
                                                                                        >
                                                                                            <big>
                                                                                                {
                                                                                                (value==='name')? t('itemName') : 
                                                                                                (value==='price')? t('priceTtcB2c') :
                                                                                                (value==='price_b2b')? t('priceHtB2b') :
                                                                                                (value==='indice')? t('prmpIndice') :
                                                                                                (value==='image')? t('imageLink') :
                                                                                                (value==='quota_commande')? t('quantity') :
                                                                                                (value==='commande')? t('orderDate') :
                                                                                                (value==='tgc')? t('tgc', {taxe:authParam.company.nomenclature_tgc}) :
                                                                                                (value==='expedition')? t('expectedDeliveryDate') :
                                                                                                (value==='reception')? t('expectedReceiptDate') :
                                                                                                (value==='user')? t('receivedBy') :
                                                                                                (value.startsWith('name_'))? t('packagingName')+' '+value.split('_')[1] :
                                                                                                (value.startsWith('reference_'))? t('barcodePackaginglist')+' '+value.split('_')[1] :
                                                                                                (value.startsWith('quantity_'))? t('packagedQuantity')+' '+value.split('_')[1] :
                                                                                                (value.startsWith('price_') && value!='price_b2b')? t('price')+' '+value.split('_')[1] :
                                                                                                t(value) ? t(value) : 
                                                                                                value
                                                                                                }
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip>
                                                                                                            {t('remove')}
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                <span style={{cursor:'pointer', marginLeft:'0.5rem'}} onClick={()=>removeField(value)}>
                                                                                                    <FontAwesomeIcon icon={faTimesCircle}  />
                                                                                                </span>
                                                                                                </OverlayTrigger>
                                                                                            </big>
                                                                                        </li>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })
                                                    }
                                                    
                                                    {provided.placeholder}
                                                </ul>
                                            )}
                                        </Droppable>
                                    }
                                </CardPerso>
                            </div>
                        </div>
                    </DragDropContext>
                    {(!defaultConfig && !emptyConf || defaultConfig && !noDefaultConf) && <ConfigSubmit interface={props.interface} config={defaultConfig} datas={draggableContent} setReload={props.setReload} setSendData={props.setSendData} setCustomInterface={props.setCustomInterface}/>}
                </>
            }
        </div>
    )
}
export default FiltresCustomisation;