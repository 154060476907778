import React, { useState,useEffect,useRef } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice} from '@gull'
import PadFidelitePayment from './FideliteComp/PadFidelitePayment'
import axios from 'axios'
import swal from "sweetalert2";

const FidelitePaymentForm = (props) =>{
    const t = useTranslate();
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    const [fidelitePayMode,setFidelitePayMode]=useState([])
    const [errorPay,setErrorPay]=useState(false)
    const [modePaiementVal,setModePaiementVal]=useState('')
    const [amountVal,setAmountVal]=useState('')
    const [depositVal,setDepositVal]=useState('')
    const [itemRecompenseId,setItemRecompenseId]=useState(false)
    const [valueCashBack,setValueCashBack]=useState(+props.clientFideliteInfo.valeur_cfp) 
    const [load,setLoad]=useState({
        btnAdd:false,
        scanGiftCard:false,
        tpeAdd:false,
        tpeCancel:false
    })

    let viewButtonTabsquare = (props.section.advanceparams && props.section.advanceparams.hasOwnProperty('modal_paiement') && props.section.advanceparams.modal_paiement=='tab_square') ? true : false
    const [relanceSubmit,setRelanceSubmit] = useState(Date.now())
    const [blockSubmit,setBlockSubmit] = useState(false)
    const fidelitePayRef = useRef(null)

    useEffect(function () {
        if(props.clientFideliteInfo.mode_fidelite=='recompense'){
            let arrIdsFreeItems = Object.values(props.clientFideliteInfo.data).flat()
            // ici on récupère tous les articles du ticket qui font parti de la liste des récompenses
            let arrItemsRecompense = []
            props.currentTicket.ticket_detail.map(value=>{
                if(value.article && arrIdsFreeItems.includes(value.article.id)){
                    arrItemsRecompense.push(value)
                }
            })
            setFidelitePayMode(arrItemsRecompense)
            
        }else if(props.clientFideliteInfo.mode_fidelite=='giftcard'){
            //Ici on récupère tous les montants des giftcard que le client peut bénéfinier en fidélité 
            let arrAmountGiftCardAvailable = []
            Object.entries(props.clientFideliteInfo.data).map(([point,montantGiftCard])=>{
                arrAmountGiftCardAvailable.push(montantGiftCard)
            })

            // ici on construit une liste de paymentmode giftcard en gardant que les giftcards dispo en récompense qui font parti du panier 
            let arrGiftCardsRecompense = []
            // ici on construit un arr avec les montants des giftcards disponible en récomponse, pour vérifier si il existe ps deja afin d'éviter les doublons sur le select 
            let arrGiftCardsRecompenseMontant = []
            props.currentTicket.ticket_detail.map(value=>{
                if(value.special=='gift_card' && arrAmountGiftCardAvailable.includes(value.prix_remise.toString()) && !arrGiftCardsRecompenseMontant.includes(value.prix_remise.toString())){
                    arrGiftCardsRecompense.push(value)
                    arrGiftCardsRecompenseMontant.push(value.prix_remise.toString())
                }
            })

            setFidelitePayMode(arrGiftCardsRecompense)
        }else if(props.clientFideliteInfo.mode_fidelite=='cashback'){
            //Si le montant total du ticket est supérieur au montant total du cashback, "valueCashBack" sera égale au cashback dispo
            //Si le montant total du ticket est inférieur au cashback dispo, "valueCashBack" sera égale au total pour na pas payer plus 
            if(+props.clientFideliteInfo.valeur_cfp > +props.valueDue) setValueCashBack(+props.valueDue)
        }
    }, [props.clientFideliteInfo])

    useEffect(function () {
        if(amountVal!='' && !blockSubmit) addPayment()
        setBlockSubmit(false)
    }, [relanceSubmit,amountVal])

    useEffect(function () {
        if(props.valueDue!=0 && props.valueDue!=0.0 && props.currentPayment=='fidelite'){
            setTimeout(() => {
                if(fidelitePayRef && fidelitePayRef.current){
                    fidelitePayRef.current.focus()
                }
            }, 500);
        }
    }, [props.currentPayment,props.valueDue])

    const handleChange = (e,name,padValue) =>{
        let amount = (padValue) ? padValue : e.target.value
        switch (name) {
            case 'modepaiement':
                if(props.clientFideliteInfo.mode_fidelite=='recompense'){
                    let splitData = amount.split(',')
                    setModePaiementVal(amount)
                    setAmountVal(+splitData[0])
                    setDepositVal(+splitData[0])
                    setItemRecompenseId(splitData[1])
                }else{
                    setModePaiementVal(amount)
                    setAmountVal(amount)
                    setDepositVal(amount)
                }
            break;
            case 'amount':
                if(+amount <= +props.clientFideliteInfo.valeur_cfp){
                    setAmountVal(amount.replace(/\D/g,''))
                    setDepositVal(amount.replace(/\D/g,''))
                }       
            break;
        }
        if(padValue){ 
            setRelanceSubmit(Date.now)
            props.setBtnDisabled(true)
        }else{
            setBlockSubmit(true)
        }
    }

    const apiAddPayment = async (obj) =>{
        setLoad({...load,btnAdd:true})

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{  
                props.setCurrentTicket(response.data.data)
                //Si le client n'a plus assez de points de fidélité pour bénéficier d'offre de fidélité, on masque le btn de fidélité 
                if(response.data.fidelite_client.liste_fidelite_client.length==0){ 
                    props.setShowBtnFidelite(false)
                }
                let objFideliteInfo = {
                    data:response.data.fidelite_client.liste_fidelite_client,
                    mode_fidelite:response.data.fidelite_client.mode_fidelite,
                    total_point_fidelite_client:response.data.total_point_fidelite_client,
                    valeur_cfp:(response.data.fidelite_client.valeur_cfp) ? response.data.fidelite_client.valeur_cfp : null,
                }
                props.setClientFideliteInfo(objFideliteInfo)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setModePaiementVal('')
        setAmountVal('')
        setDepositVal('')
        setErrorPay(false)
        setLoad({...load,btnAdd:false})
        props.setBtnDisabled(false)
    }

    const addPaymentDue = () =>{
        setAmountVal(+valueCashBack)
        setDepositVal(+valueCashBack)
    }

    const addPayment = () =>{
        props.setBtnDisabled(true)
        let modePayment = (props.clientFideliteInfo.mode_fidelite=='giftcard') ? 'fidelite' : props.clientFideliteInfo.mode_fidelite

        var data={
            id:props.cookies.current_ticket,
            paiement_mode:modePayment,
            paiement_montant:+amountVal,
            paiement_verse:+depositVal,
            paiement_rendu:0
        }

        if(props.clientFideliteInfo.mode_fidelite=='recompense') data['article']=itemRecompenseId
        
        if(props.clientFideliteInfo.mode_fidelite!='cashback' && (modePaiementVal==='' || depositVal==='' || amountVal==='')){
            setErrorPay(t('allFieldsRequired'))
            props.setBtnDisabled(false)

        }else if(props.clientFideliteInfo.mode_fidelite=='cashback' && (depositVal==='' || amountVal==='')){
            setErrorPay(t('allFieldsRequired'))
            props.setBtnDisabled(false)
        }else{
            apiAddPayment(data)
        }
        setTimeout(()=>{
            setErrorPay(false)
        },1500)
    }

    let initAmountCashBack = (+props.clientFideliteInfo.valeur_cfp <= +props.valueDue) ? props.clientFideliteInfo.valeur_cfp : props.valueDue

    return (
        <div>
            {(!viewButtonTabsquare && props.clientFideliteInfo.mode_fidelite!='cashback') ?
                <div className="row input-group mb-2">
                    <div className="col">
                        <label htmlFor="modepaiement">{t('toApplyTo')}</label>
                        <select value={modePaiementVal} type="text" className="form-control" onChange={(e)=>handleChange(e,'modepaiement')}>
                            <option value="">-- Select --</option>
                            {
                                fidelitePayMode.map((value,index)=>{
                                    if(props.clientFideliteInfo.mode_fidelite=='giftcard'){
                                        let objGc={}
                                        Object.entries(props.clientFideliteInfo.data).map(([point,montantGiftCard])=>{
                                            objGc[montantGiftCard]=point
                                        })

                                        return( 
                                            <option key={index} value={value.prix_remise}>
                                                {t('giftcard')+' : '+value.prix_remise+props.devise+' - '+objGc[value.prix_remise]+' Pts'}
                                            </option>
                                        )
                                    }else{
                                        let valuePrice = +value.prix_remise / +value.quantity
                                        return( 
                                            <option key={index} value={valuePrice+','+value.article.id}>
                                                {value.article.name}
                                            </option>
                                        )
                                    }
                                })
                            }
                        </select>
                    </div>
                </div>
            :
                (viewButtonTabsquare && props.clientFideliteInfo.mode_fidelite!='cashback') ?
                    <div className="row mb-2">
                        {fidelitePayMode.map((value,index)=>{
                            if(props.clientFideliteInfo.mode_fidelite=='giftcard'){
                                let objGc={}
                                Object.entries(props.clientFideliteInfo.data).map(([point,montantGiftCard])=>{
                                    objGc[montantGiftCard]=point
                                })

                                return( 
                                    <PadFidelitePayment
                                        key={index}
                                        mode='fidelite'
                                        text={t('giftcard')+' : '+objGc[value.prix_remise]+' Pts'}
                                        amount={value.prix_remise}
                                        handleChange={handleChange}
                                        btnDisabled={props.btnDisabled}
                                        devise={props.devise}
                                        digit={props.digit}
                                    />
                                )
                            }else{
                                let valuePrice = +value.prix_remise / +value.quantity
                                return( 
                                    <PadFidelitePayment
                                        key={index}
                                        mode='recompense'
                                        text={value.article.name}
                                        amount={valuePrice}
                                        articleId={value.article.id}
                                        handleChange={handleChange}
                                        btnDisabled={props.btnDisabled}
                                        devise={props.devise}
                                        digit={props.digit}
                                    />
                                )
                            }
                        })}
                    </div>
                : 
                    (viewButtonTabsquare && props.clientFideliteInfo.mode_fidelite=='cashback') ?
                        <div className="row mb-2">
                            <PadFidelitePayment
                                mode='cashback'
                                text={t('cashback')}
                                amount={(amountVal!='') ? amountVal : initAmountCashBack}
                                handleChange={handleChange}
                                btnDisabled={props.btnDisabled}
                                devise={props.devise}
                                digit={props.digit}
                            />
                        </div>
                    : 
                        false
            }

            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="amount">{t('amount')}</label>
                    <input autoComplete="off" ref={fidelitePayRef} disabled={props.clientFideliteInfo.mode_fidelite!='cashback' || props.btnDisabled} className='form-control'  value={amountVal} type="text" onChange={(e)=>handleChange(e,'amount')} />
                </div>
            </div>

            {(errorPay) ? <div className="alert alert-danger mb-3">{errorPay}</div> : false}
            {(props.clientFideliteInfo.mode_fidelite=='cashback' && !viewButtonTabsquare) ?
                <>
                    <button disabled={props.btnDisabled || load.btnAdd} className="btn btn-dark mt-1" onClick={()=>addPaymentDue()}>
                        <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={+valueCashBack} />
                    </button>&nbsp;
                </>
            :
                false
            }
            {!viewButtonTabsquare &&
                <button disabled={props.btnDisabled || load.btnAdd} className="btn btn-primary mt-1" onClick={()=>addPayment()}>
                    {load.btnAdd ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('add')}
                </button>
            }
        </div>
    )
};

export default FidelitePaymentForm;