import React, {useContext} from "react";
import { IsAuthContext } from 'app/App';
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso} from "@gull";
import { espace_mille } from "app/espace_mille";

const ClientDetails = (props) => {
    const { authParam } = useContext(IsAuthContext);
    const t = useTranslate();
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    return(
        <div className="row">
            <div className="col-12">
                <CardPerso header={t('customerInfos')}>
                    <div className="row mb-3">
                        <div className="col">
                            <div className='row mb-3'>
                                <div className='fs-3 fw-bold text-center border-bottom border-dark pb-2'>{t('principalInfo')}</div>
                            </div>
                            <div className="row">
                                <div className="col-10 mx-auto" >
                                    <div className="row">
                                        <div className="col-4">
                                            <div className='row mb-3'>
                                                <label className='fs-5 text fw-bold' htmlFor="type">Type :</label>
                                                {(props.client.type) ?
                                                    <div>{props.client.type}</div>
                                                :
                                                    <div>--</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='row mb-3'>
                                                <label className='fs-5 text fw-bold' htmlFor="name">{t('tableName')+' :'}</label>
                                                <div>{props.client.name}</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='row mb-3'>
                                                <label className='fs-5 text fw-bold' htmlFor="firstname">{t('tableFirstName')+' :'}</label>
                                                <div>{props.client.firstname}</div>
                                            </div>
                                        </div>
                                        {(authParam.company.clientform && authParam.company.clientform.civilite[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="civilite">{t('civilite')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.civilite) ?
                                                        <div>{props.client.infos.civilite}</div>
                                                    :
                                                        <div>--</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                        {(authParam.company.clientform && authParam.company.clientform.datedenaissance[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="civilite">{t('dateDeNaissance')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.datedenaissance) ?
                                                        <div>{props.client.infos.datedenaissance}</div>
                                                    :
                                                        <div>--</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                        <div className="col-4">
                                            <div className='row mb-3'>
                                                <label className='fs-5 text fw-bold' htmlFor="civilite">{t('totalCumulLoyaltyPoint')+' :'}</label>
                                                {<div>{espace_mille(+props.client.total_point_fidelite_client,digit)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className='row mb-3'>
                                <div className='fs-3 fw-bold text-center border-bottom border-dark pb-2'>{t('diverseInfo')}</div>
                            </div>
                            <div className="row">
                                <div className="col-10 mx-auto">
                                    <div className="row ">
                                        <div className="col-4">
                                            <div className='row mb-3'>
                                                <label className='fs-5 text fw-bold' htmlFor="type">Email :</label>
                                                {(props.client.email) ?
                                                    <div>{props.client.email}</div>
                                                :
                                                    <div>--</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='row mb-3'>
                                                <label className='fs-5 text fw-bold' htmlFor="type">{t('tablePhone')+' :'}</label>
                                                {(props.client.tel) ?
                                                    <div>{props.client.tel}</div>
                                                :
                                                    <div>--</div>
                                                }
                                            </div>
                                        </div>
                                        {(authParam.company.clientform && authParam.company.clientform.autretel[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="autretel">{t('tableOtherPhone')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.autretel) ?
                                                        <div>{props.client.infos.autretel}</div>
                                                    :
                                                        <div>--</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                        {(authParam.company.clientform && authParam.company.clientform.adresse[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="adresse">{t('labelAdress')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.adresse) ?
                                                        <div>{props.client.infos.adresse}</div>
                                                    :
                                                        <div>--</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                        {(authParam.company.clientform && authParam.company.clientform.quartier[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="quartier">{t('quartier')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.quartier) ?
                                                        <div>{props.client.infos.quartier}</div>
                                                    :
                                                        <div>--</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                        {(authParam.company.clientform && authParam.company.clientform.ville[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="ville">{t('labelVille')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.ville) ?
                                                        <div>{props.client.infos.ville}</div>
                                                    :
                                                        <div>--</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                        {(authParam.company.clientform && authParam.company.clientform.pays[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="pays">{t('labelPays')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.pays) ?
                                                        <div>{props.client.infos.pays}</div>
                                                    :
                                                        <div>--</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                        {(authParam.company.clientform && authParam.company.clientform.codepostal[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="codepostal">{t('labelCodePostal')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.codepostal) ?
                                                        <div>{props.client.infos.codepostal}</div>
                                                    :
                                                        <div>--</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                        {(authParam.company.clientform && authParam.company.clientform.newsletter[0]) ?
                                            <div className="col-4">
                                                <div className='row mb-3'>
                                                    <label className='fs-5 text fw-bold' htmlFor="newsletter">{t('inscriptionNewsletter')+' :'}</label>
                                                    {(props.client.infos && props.client.infos.newsletter) ?
                                                        <div className='text-success'>{t('yes')}</div>
                                                    :
                                                        <div className='text-danger'>{t('no')}</div>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            false
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardPerso>
            </div>
        </div>
    )

}

export default ClientDetails;