export const articleListErp = [
    {dataField:'name',text:'tableName',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_name_@!', permissionShown:''},
    {dataField:'sku',text:'SKU',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_sku_@!', permissionShown:''},
    {dataField:'parent_name',text:'parent_name',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_parentname_@!', permissionShown:''},
    {dataField:'reference',text:'reference',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_reference_@!', permissionShown:''},
    {dataField:'reference2',text:'reference2',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_reference2_@!', permissionShown:''},
    // {dataField:'pr',text:'pr',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_pr_@!', permissionShown:''},
    {dataField:'archived',text:'archived',formatter:false,editable:true,editType:'select',sort:false, allowAccessName:'article_archive_@!', permissionShown:''},
    {dataField:'prmp',text:'prmp',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_prmp_@!', permissionShown:''},
    {dataField:'ht',text:'priceHtB2c',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_priceht_@!', permissionShown:'read'},
    {dataField:'price_b2b',text:'priceHtB2b',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_priceb2b_@!', permissionShown:'read'},
    {dataField:'margeN',text:'margeB2c',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_margenet_@!', permissionShown:'read'},
    {dataField:'margeP',text:'margePB2c',formatter:'percent',editable:false,editType:false,sort:false, allowAccessName:'article_margepercent_@!', permissionShown:'read'},
    {dataField:'margeNB2b',text:'margeNB2b',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_margeb2b_@!', permissionShown:'read'},
    {dataField:'margePB2b',text:'margePB2b',formatter:'percent',editable:false,editType:false,sort:false, allowAccessName:'article_margepercentb2b_@!', permissionShown:'read'},
    {dataField:'price',text:'priceTtcB2c',formatter:'price',editable:true,editType:'input',sort:true, allowAccessName:'article_pricettc_@!', permissionShown:''},
    {dataField:'ttcb2b',text:'priceTtcB2b',formatter:'price',editable:false,editType:false,sort:true, allowAccessName:'article_pricettcb2b_@!', permissionShown:''},
    {dataField:'tgc',text:'tgc',formatter:'percent',editable:true,editType:'input',sort:true, allowAccessName:'article_tgc_@!', permissionShown:''},
    {dataField:'fournisseur',text:'fournisseur',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_fournisseur_@!', permissionShown:''},
    {dataField:'marque',text:'marque',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_marque_@!', permissionShown:''},
    {dataField:'firstlevelcat',text:'Classification',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_firstlevelcat_@!', permissionShown:''},
    {dataField:'secondlevelcat',text:'secondlevelcat',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_secondlevelcat_@!', permissionShown:''},
    {dataField:'thirdlevelcat',text:'thirdlevelcat',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_thirdlevelcat_@!', permissionShown:''},
    {dataField:'fourthlevelcat',text:'fourthlevelcat',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_fourthlevelcat_@!', permissionShown:''},
    {dataField:'fifthlevelcat',text:'fifthlevelcat',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_fifthlevelcat_@!', permissionShown:''},
    {dataField:'image',text:'Image',formatter:false,editable:false,editType:false,sort:true, allowAccessName:'article_image_@!', permissionShown:''},
    {dataField:'havestock',text:'itemHaveStock',formatter:false,editable:true,editType:'select',sort:false, allowAccessName:'article_havestock_@!', permissionShown:''},
    {dataField:'provisoire',text:'temporary',formatter:false,editable:false,editType:false,sort:false, allowAccessName:'article_provisoire_@!', permissionShown:'read'},
    {dataField:'categorie_douane',text:'categorie_douane',formatter:false,editable:true,editType:'input',sort:false, allowAccessName:'article_categorie_douane_@!', permissionShown:''},
    {dataField:'ecotaxe',text:'ecotaxe',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_ecotaxe_@!', permissionShown:''},
    {dataField:'last_pr',text:'last_pr',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_last_pr_@!', permissionShown:''},
    {dataField:'buy_price',text:'buy_price',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_buy_price_@!', permissionShown:'read'},
    {dataField:'last_date_buy',text:'last_date_buy',formatter:'date',editable:false,editType:false,sort:false, allowAccessName:'article_last_date_buy_@!', permissionShown:'read'},
    {dataField:'last_purchase_price',text:'last_purchase_price',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_last_purchase_price_@!', permissionShown:'read'},
    {dataField:'last_purchase_price_devise',text:'last_purchase_price_devise',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_last_purchase_price_devise_@!', permissionShown:'read'},
];