import React, {useState,useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FormatedPrice,CardPerso,ColumDataTableReorganize} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from 'react-moment';
import { IsAuthContext } from 'app/App';
import { modePaiementMustBeTranslate } from 'app/modePaiementDefaultAll';
import { espace_mille } from 'app/espace_mille';
import {scrollXDataTable} from 'app/scrollXDataTable';
import { useCookies} from 'react-cookie';
import {Link} from 'react-router-dom'

const useLoad = (clientId,tabKey) => {
    const t =useTranslate()
    const [allTickets,setAllTickets]=useState([])
    const [sizeDatas,setSizeDatas]=useState(0)
    const [load,setLoad]=useState({
        allTicket:false,
    })
    
    useEffect(function () {
        (async function() {
            if(tabKey=='ticket'){
                setLoad({...load,allTicket:true})
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_histo_ticket/"+clientId+'/1/50'
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{   
                        setAllTickets(response.data.datas)
                        setSizeDatas(response.data.count)
                        setLoad({...load,allTicket:false})
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoad({...load,allTicket:false})
            }
        })()
    }, [clientId,tabKey])

    return [allTickets,setAllTickets,load,sizeDatas,setSizeDatas]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            pagination={ paginationFactory(props.paginationOptions) }
            onTableChange={ props.handleTableChange }
            noDataIndication={t('tableNoData')}
            defaultSorted={props.defaultSorted}
            hover
            condensed
            responsive
        />
    );
};

const HistoTicketClient = (props) => {
    const t =useTranslate()
    const [allTickets,setAllTickets,load,sizeDatas,setSizeDatas]=useLoad(props.clientId,props.tabKey)   
    const [perPage,setPerPage] = useState(50)
    const [currentPage,setCurrentPage] = useState(1)
    const { authParam } = useContext(IsAuthContext)

    const [cookies, setCookie] = useCookies(['client_histo_ticket_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: sizeDatas
    };

    let dataTable = new Array
    Object.keys(allTickets).length!=0 && Object.entries(allTickets).map(([keyName,value],ind)=>{
        let modeList =''
        value.remboursement_records.length!=0 && value.remboursement_records.map((item)=>{
            let refundName = (item.mode=='espece') ? t('refundEspece') : t('refundOther')
            modeList += refundName+': '+espace_mille(item.montant,digit)+devise
        })
        value.paiement_records.length!=0 && value.paiement_records.map((item,index)=>{
            let modeName = modePaiementMustBeTranslate.includes(item.mode) ? t(item.mode) : item.mode
            if(index==0 && modeList==''){
                modeList += modeName+': '+espace_mille(item.montant,digit)+devise
            }else{
                modeList += ' / '+modeName+': '+espace_mille(item.montant,digit)+devise
            }
            
        })
        let obj = {
            ticket: value.bar,
            masque:'',
            amount: <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={value.montant} />,
            lines: value.lines,
            nb_articles: value.nbr_articles,
            date: <Moment format="DD/MM/YYYY HH:mm:ss">{value.date_validation}</Moment>,
            mode_pay: modeList,
            id: value.id,
            caissier: (value.rh!=null) ? value.rh.firstname+' '+value.rh.name : '',
            action: 
                <span className="d-flex justify-content-between align-items-center mt-1" style={{marginLeft:'0.2em'}}>
                    <Link className="btn btn-light btn-sm" to={'/erp/caisse/ticket/'+value.id}>{t('detail')}</Link>
                </span>,
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    let defaultSorted = [
        {
          dataField: "date",
          order: "desc"
        }
    ];

    let columns = [
        {
            dataField: "action",
            text: 'Action',
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
            // style: (cell, row, rowIndex, colIndex) => {
            //     return {
            //         padding:'0',
            //     };
            // },
        },
        {
            dataField: "ticket",
            text: t('ticket')+' n°',
            editable: false,
        },
        {
            dataField: "amount",
            text: t('amount'),
            editable: false,
        },
        {
            dataField: "lines",
            text: t('linesNumber'),
            editable: false,
        },
        {
            dataField: "nb_articles",
            text: t('itemsNumber'),
            editable: false,
        },
        {
            dataField: "mode_pay",
            text: t('paymentDetail'),
            editable: false,
        },
        {
            dataField: "date",
            text: 'Date',
            editable: false,
        },
        {
            dataField: "caissier",
            text: t('cashier'),
            editable: false,
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.client_histo_ticket_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.client_histo_ticket_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("client_histo_ticket_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const handleTableChange = async (type, { page, sizePerPage }) => {
        setPerPage(sizePerPage)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_histo_ticket/"+props.clientId+'/'+page+"/"+sizePerPage
        ).then((response) => {
            setAllTickets(response.data.datas)
            setSizeDatas(response.data.count)
            setCurrentPage(page)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return(<>
        <CardPerso saClass='position-relative'>
            <div className="mb-2">
                <ColumDataTableReorganize 
                    columnOrganize={columnOrganize}
                    setColumnOrganize={setColumnOrganize}
                    setCookie={setCookie}
                    cookieName='client_histo_ticket_reorganize'
                    showColumnOrganize={showColumnOrganize}
                    setShowColumnOrganize={setShowColumnOrganize}
                    btnSwitch={false}//pour affiche ou non le btn swith
                    initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                />
            </div>
            {(load.allTicket) ? 
                <div className="d-flex justify-content-center"><span className="spinner-border spinner-border-sm"></span></div> 
            :
                (columnOrganize.length!=0) &&
                    <small>
                        <ToolkitProvider
                            keyField="index"
                            data={dataTable}
                            columns={ columnOrganize }
                        >
                            {(propst) => (
                                <div className="position-relative">
                                    <CustomDataTable 
                                        propst={propst}
                                        paginationOptions={paginationOptions}
                                        handleTableChange={handleTableChange}
                                        columns={columns}
                                        defaultSorted={defaultSorted}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </small>
            }
        </CardPerso>
        </>
    )
    
}

export default HistoTicketClient;