import React, { useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";

const EditDetailArticleSelect = (props) =>{
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();

    useEffect(function () {
        if(props.defaultValue!=null) setValue(props.liaisonName,props.defaultValue)
    }, [])

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            {(props.liaisons) ?
                <select disabled={props.validButton} className='form-control' {...register(props.liaisonName)}>
                    <option value="">{t('freeField')}</option>
                    {props.liaisons[props.liaisonName] && props.liaisons[props.liaisonName].map((value,index)=>
                            <option key={index} value={value.id}>{value.name}</option>
                        )
                    }
                </select>
            :
                <select disabled={props.validButton} className='form-control' {...register(props.liaisonName)}>
                    <option value={(props.liaisonName=='havestock') ? "non" : "0"}>{t('no')}</option>
                    <option value={(props.liaisonName=='havestock') ? "oui" : "1"}>{t('yes')}</option>
                </select>
            }
            <button disabled={props.validButton} className="btn btn-sm btn-primary mt-3">
                {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </form>
    )
};

export default EditDetailArticleSelect;

