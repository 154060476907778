import React, { useState,useContext } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import FreeItemCustomerReward from './Comp/FreeItemCustomerReward'
import CashBackAvailable from './Comp/CashBackAvailable'
import GiftCardAvailable from './Comp/GiftCardAvailable'
import { IsAuthContext } from 'app/App';
import { PeripheriqueContext } from 'app/App';

const CustomerRewards = (props) =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext)
    const { peripheriqueParam } = useContext(PeripheriqueContext) 

    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    switch (props.clientFideliteInfo.mode_fidelite) { 
        case 'cashback':
            return (
                <CashBackAvailable 
                    clientFideliteInfo={props.clientFideliteInfo}
                    devise={devise}
                    digit={digit}
                    from={props.from}
                />
            )
        break;
        case 'recompense':
            return (
                <FreeItemCustomerReward 
                    clientFideliteInfo={props.clientFideliteInfo}
                    posid={props.posid}
                    setDisabledPay={props.setDisabledPay}
                    setDisabledCloudButton={props.setDisabledCloudButton}
                    setRelanceFocus={props.setRelanceFocus}
                    setCurrentTicket={props.setCurrentTicket} 
                    clientId={props.clientId}
                    cashierId={props.cashierId}
                    cookies={props.cookies}
                    setCookie={props.setCookie}
                    affichageClient={props.affichageClient}
                    devise={devise}
                    digit={digit}
                    peripheriqueParam={peripheriqueParam}
                    setShowModalFidelite={props.setShowModalFidelite}
                    from={props.from}
                />
            )
        break;
        case 'giftcard':
            return (
                <GiftCardAvailable 
                    clientFideliteInfo={props.clientFideliteInfo}
                    posid={props.posid}
                    devise={devise}
                    digit={digit}
                    currentTicket={props.currentTicket}
                    setCurrentTicket={props.setCurrentTicket} 
                    clientId={props.clientId}
                    cashierId={props.cashierId}
                    cookies={props.cookies}
                    setCookie={props.setCookie}
                    affichageClient={props.affichageClient}
                    peripheriqueParam={peripheriqueParam}
                    valueInputClient={props.valueInputClient}
                    setShowModalFidelite={props.setShowModalFidelite}
                    from={props.from}
                />
            )
        break;
    }
};

export default CustomerRewards;