import React, {useState} from "react";
import { ControlAccess } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import swal from "sweetalert2";
import { Modal} from "react-bootstrap";

const DeleteLineButton = (props) =>{
    const t =useTranslate()
    const [showRestriction,setShowRestriction]=useState(false) 

    const callApi = async () =>{
        if(props.from=='lineItem'){
            props.setLoading({...props.loading,delete:true})
            props.setDisabledPay(true)
        }else{
            props.setLoading(true)
        }
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+props.idsLocalStorage[1]+"/"+props.idsLocalStorage[0],{
            delete_detail_id: props.item.id,
            id:props.cookies.current_ticket
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                if(props.from=='lineItem'){
                    props.setLoading({...props.loading,delete:false})
                }else{
                    props.setLoading(false)
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        props.setRelanceFocus(Date.now())
    }

    const removeArticle = () =>{
        if(!props.restrictionDeleteLineItem.go){
            setShowRestriction(true)
        }else{
            callApi()
        }
    }

    const callback = () =>{ 
        callApi()
        setShowRestriction(false)
        props.setRestrictionDeleteLineItem({...props.restrictionDeleteLineItem,go:false})
    }

    return(
        <>
            <Modal show={showRestriction} onHide={() => setShowRestriction(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginTop:'80px'}}>
                        <ControlAccess section={props.section} focus={true} restriction={props.restrictionDeleteLineItem} moduleId={props.posid} setRestriction={props.setRestrictionDeleteLineItem} callback={callback} />
                    </div>
                </Modal.Body>
            </Modal>
            <FontAwesomeIcon icon={faTimesCircle}  className='pointer' onClick={()=>removeArticle()}/>
        </>
    )
}

export default DeleteLineButton;