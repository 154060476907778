//Pour tout ajout de mode de paiements CB, si celui ci est un paiement en plusieurs fois, ajouter à son nom '_multiple'
export const modePaiementCb = [
    'amex',
    'jade',
    'jcb',
    'emv',
    'emv_touch',
    'emv_multiple',
    'amex_touch',
    'amex_multiple',
];