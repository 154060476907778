import React, {useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from 'app/App';
import {CurrencyLineForm} from '@gull'

const CaisseCloseForm = (props) =>{
    const t = useTranslate()
    const { authParam } = useContext(IsAuthContext);
    let devise = authParam.company.devise;

    const onfocusAction = (inputId) =>{
        let element = document.getElementById(inputId)
        if(element){ 
            if(element==document.activeElement){
                element.select()
            }else{
                element.blur()
            }
        }
    }

    let suppId = props.idComplete ? props.idComplete : ''

    return(
        <> 
            {(authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.billet.length!=0 && Object.keys(props.detailCash).length!=0) ?
                <>
                    {authParam.company.advanceparams.ventilation_espece.billet.map(billet=>{
                        return(
                            <CurrencyLineForm
                                key={billet}
                                devise={devise}
                                currency={billet}
                                onfocusAction={onfocusAction}
                                detailCash={props.detailCash}
                                calcSum={props.calcSum}
                                suppId={suppId}
                            />
                        )
                    })}
                    {authParam.company.advanceparams.ventilation_espece.piece.map(piece=>{
                        return(
                            <CurrencyLineForm
                                key={piece}
                                devise={devise}
                                currency={piece}
                                onfocusAction={onfocusAction}
                                detailCash={props.detailCash}
                                calcSum={props.calcSum}
                                suppId={suppId}
                            />
                        )
                    })}
                </>
            :
                t('noExistingCurrency')
            }
        </>
    )
}

export default CaisseCloseForm;