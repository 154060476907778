import React, { useState,useEffect,useContext} from "react";
import { IsAuthContext } from 'app/App';
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso,Loading,ModalChangePaymentMethod} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { useParams } from "react-router-dom";
import GeneralInfos from './Comp/GeneralInfos'
import DetailItems from './Comp/DetailItems'

const useLoad = (ticketId) => {
    const t = useTranslate()
    const [loading,setLoading]=useState(true)
    const [ticketDetail,setTicketDetail]=useState({})
    const [section,setSection]=useState(false)
    const [alertDifferenceTotal,setAlertDifferenceTotal] = useState(false)
    const [totalAchat,setTotalAchat]=useState(0)
    const [pointsFidelite,setPointsFidelite]=useState({
        total:0,
        acquis:0,
        cashbackTotal:0,
        cashbackAcquis:0,
        mode:null
    }) 
    
    useEffect(function () {
        (async function() {
            if(Object.keys(ticketDetail).length!=0){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+ticketDetail.section_id
                ).then((response) => {
                    setSection(response.data)
                }).catch((error) => {
                    if(error=='end_session') return;
                    setLoading(false)
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoading(false)
            }
        })()
    }, [ticketDetail])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/ticket/get_by_id/"+ticketId
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{ 
                    setTicketDetail(response.data.data)
                    setPointsFidelite({
                        total:response.data.total_point_fidelite_client,
                        acquis:response.data.total_point_fidelite_ticket,
                        cashbackTotal:response.data.fidelite_client.valeur_cfp ? response.data.fidelite_client.valeur_cfp : 0,
                        cashbackAcquis:response.data.valeur_cfp_ticket ? response.data.valeur_cfp_ticket : 0,
                        mode:response.data.fidelite_client.mode_fidelite
                    })
                    let totalPanier = 0
                    response.data.data.ticket_detail.map(item=>{
                        totalPanier+=item.prix_remise
                    })
                    let totalPaiement = 0
                    response.data.data.paiement_records.map(item=>{
                        totalPaiement+=item.montant
                    })
                    let totalRemboursement = 0
                    response.data.data.remboursement_records.map(item=>{
                        totalRemboursement+=item.montant
                    })
                    setTotalAchat(totalPanier)
                    if((totalRemboursement!=0 && +totalRemboursement != Math.abs(+totalPanier)) || (totalPaiement!=0 && Math.abs(+totalPanier) != +totalPaiement)) setAlertDifferenceTotal(true)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    return [loading,ticketDetail,section,setTicketDetail,pointsFidelite,totalAchat,setTotalAchat,setAlertDifferenceTotal,alertDifferenceTotal]
}

const DetailTicketAdmin = () => {
    const t = useTranslate()
    const { ticketId } = useParams();
    const [loading,ticketDetail,section,setTicketDetail,pointsFidelite,totalAchat,setTotalAchat,setAlertDifferenceTotal,alertDifferenceTotal] = useLoad(ticketId)
    const { authParam } = useContext(IsAuthContext);
    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    let actualShop = (Object.keys(ticketDetail).length!=0) ? authParam.sections.find(shop=>shop.id == ticketDetail.section_id) : false
    const moduleErp = authParam.modules.find(value=>value.name=='erp')

    if(loading){
        return (<Loading></Loading>);
    }

    return(
        <div className="row">
            <div className="col">
                {(Object.keys(ticketDetail).length!=0) ?
                    <CardPerso header={t('receiptDetail')}>
                        {alertDifferenceTotal &&
                            <div className="alert alert-danger">
                                {t('errorMsgTotalPay')}
                            </div>
                        }
                        <div className="row">
                            <div className="col-5">
                                {(section) ?
                                    <GeneralInfos 
                                        ticketDetail={ticketDetail}
                                        pointsFidelite={pointsFidelite}
                                        moduleErp={moduleErp}
                                        section={section}
                                        setTicketDetail={setTicketDetail}
                                        digit={digit}
                                        devise={devise}
                                        totalAchat={totalAchat}
                                        setTotalAchat={setTotalAchat}
                                    />
                                :
                                    false
                                }
                            </div>
                            <div className="col-4">
                                {(ticketDetail.ticket_detail && ticketDetail.ticket_detail.length!=0) && ticketDetail.ticket_detail.map((value,ind)=>{
                                    let style = (ind%2 == 0) ? 'stripeBg stripePadding2 fw-bold' : 'stripePadding2 fw-bold';

                                    if(section){
                                        return(
                                            <DetailItems 
                                                key={ind}
                                                style={style}
                                                value={value}
                                                digit={digit}
                                                devise={devise}
                                                section={section}
                                                posid={ticketDetail.section_id}
                                                setTicketDetail={setTicketDetail}
                                                caisseIdent={ticketDetail.caisse_ident_id}
                                                pos_id={ticketDetail.pos_id}
                                                ticektId={ticketDetail.id}
                                                from='erp'
                                            />
                                        )
                                    }else{
                                        return(false)
                                    }
                                })}
                            </div>
                            <div className="col-3">
                                {(actualShop)?
                                    <ModalChangePaymentMethod
                                        barTicket={ticketDetail.bar}
                                        currentShop={actualShop}
                                        devise={devise}
                                        digit={digit}
                                        posid={ticketDetail.section_id}
                                        ticketDetail={ticketDetail}
                                        caisseIdent={ticketDetail.caisse_ident_id}
                                        pos_id={ticketDetail.pos_id}
                                        module='erp'
                                        from='ticket'
                                        totalAchat={totalAchat}
                                        setAlertDifferenceTotal={setAlertDifferenceTotal}
                                    />
                                :
                                    false
                                }
                            </div>
                            
                        </div>
                    </CardPerso>
                :
                    false
                }
            </div>
        </div>
    )
}

export default DetailTicketAdmin;