import React, {useState,useEffect,useContext} from "react";
import { IsAuthContext } from 'app/App';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import { HeadInfos, Loading, ClientForm} from "@gull";
import FusionClientForm from './Comp/FusionClientForm'
import ClientDetails from './Comp/ClientDetails'
import swal from "sweetalert2";
import { Modal } from "react-bootstrap";

const useLoadClient = (clientId,tabKey) => {
    const [loading,setLoading]=useState(true)
    const [client,setClient]=useState(false)
    const [relance,setRelance]=useState(Date.now()) 
    const t = useTranslate();

    useEffect(function () {
        (async function() {
            if(tabKey=='customerProfil'){
                    const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/fidelite/get_fidelite_client/"+clientId
                ).then((response) => {
                    let obj = {...response.data.client,total_point_fidelite_client:response.data.total_point_fidelite_client}
                    setClient(obj)
                    setLoading(false)
                }).catch((error) => {
                    if(error=='end_session') return;
                    setLoading(false)
                    if(error.response==undefined){
                        swal.fire(t('unknowError'), "", "error");
                    }
                    else if(error.response.status==403){
                        swal.fire(t('forbiddenGeneral'), "", "error");
                    }
                    else {
                        swal.fire(t('unknowError'), "", "error");
                    }
                })
            }
        })()
    }, [clientId,relance,tabKey])

    return [loading,client,setClient,setRelance]
}

const  HandleShowModal = (props) => {
    const t = useTranslate();

    const handleClick = (name) => {
        if(name=='edit'){
            props.setShow(true)
        }else{
            props.setShowFusion(true)
        }
    }

    return (<div>
        <button className="btn btn-sm btn-primary" onClick={() => handleClick('edit')}>{t('edit')}</button>&nbsp;&nbsp;
        <button className="btn btn-sm btn-info" onClick={() => handleClick('fusion')}>{t('mergeWithCustomer')}</button>
    </div>
    )
}

const ProfilClient = (props) => {
    const [loading,client,setClient,setRelance] = useLoadClient(props.clientId,props.tabKey);
    const { authParam } = useContext(IsAuthContext);
    const t = useTranslate();
    const [show, setShow] = useState(false)
    const [showFusion, setShowFusion] = useState(false)

    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    const closeModal = () =>{
        setShow(false)
    }
    
    if(loading){
        return (<Loading></Loading>);
    }
    
    return (<>
        <Modal size="xl" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('editClient')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ClientForm 
                    setShowModal={setShow}
                    clientid={client.id}
                    setValue={setClient}
                    closeModal={closeModal}
                    module='erp'
                />
            </Modal.Body>
        </Modal>
        <Modal size="xl" show={showFusion} onHide={() => setShowFusion(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('mergeWithCustomer')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FusionClientForm 
                    setShowFusion={setShowFusion}
                    currentClient={props.clientId}
                    client={client}
                    setRelance={setRelance}
                />
            </Modal.Body>
        </Modal>
        <div className="col">
            <div className="row">
                <div className="col-8">
                    <HeadInfos  titre={t('customer')+' :'+' '+client.name+' '+client.firstname}/>
                </div>
                <div className="col-4">
                    <div className="row text-center ">
                        <HandleShowModal setShow={setShow} setShowFusion={setShowFusion}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-8 mx-auto">
                    <ClientDetails client={client} devise={devise} digit={digit}/>
                </div>
            </div>
        </div>
    </>
    )
}

export default ProfilClient;