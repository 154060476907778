import React, { useState,useEffect,lazy,Suspense } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {ColumDataTableReorganize,ProfilActionButton,FormatedPrice,BtnShowDocSaved,Loading} from '@gull'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useCookies} from 'react-cookie';
import {scrollXDataTable} from 'app/scrollXDataTable';
import Moment from 'react-moment';
import { Modal } from "react-bootstrap";

const ModalSavedFacture = lazy(() => import('../../modal/ModalSavedFacture'));

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            defaultSortDirection={props.defaultSortDirection}
            rowStyle={ props.rowStyle }
            hover
            condensed
            responsive
        />
    );
};

const DossierListDocumentTable = (props) =>{
    const t = useTranslate();
    let { SearchBar } = Search;

    const [perPage,setPerPage]=useState(50)
    const [cookies, setCookie] = useCookies(['dossier_facture_table_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false)
    const [filter,setFilter]=useState('all'); 
    const [showModalDocument,setShowModalDocument]=useState(false) 
    const [textModal,setTextModal]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)
    var maxCarac = 15;
    
    let dataTable = new Array
    props.folderDetail.facture.length!=0 && props.folderDetail.facture.map((value,ind)=>{
        if(filter=='all' || value.type==filter){
            let status='';
            let finalise=false;
            if(value.cancelled) status=t('canceled');
            else if(value.type=='quotation' || value.type=='creditNote' || value.type=='billingDeposit') status='';
            else if(value.date_validation){
                status=<>{t('completed')} - <Moment format="DD/MM/YYYY">{value.date_validation}</Moment></>
                finalise=true
            }
            else status=t('notCompleted');
            let obj = {
                type: t(value.type),
                bar: value.bar,
                createdAt: <Moment format="DD/MM/YYYY">{value.date_finalisation}</Moment>,
                status: status,
                client: value.client_infos.name+' '+value.client_infos.firstname,
                fournisseur: value.fournisseur!=null ? value.fournisseur.name : '',
                superieur: (value.divers_infos!=null && value.divers_infos.superieur_infos!=null && value.divers_infos.superieur_infos.length>maxCarac) ? <span>{value.divers_infos.superieur_infos.replace( /(<([^>]+)>)/ig, '').substring(0,maxCarac)}<span className='lien-to' onClick={()=>setTextModal(value.divers_infos.superieur_infos.replace( /(<([^>]+)>)/ig, ''))}>...</span></span> : (value.divers_infos!=null && value.divers_infos.superieur_infos!=null) ?value.divers_infos.superieur_infos.replace( /(<([^>]+)>)/ig, '') : '', 

                inferieur: (value.divers_infos!=null && value.divers_infos.inferieur_infos!=null && value.divers_infos.inferieur_infos.length>maxCarac) ? <span>{value.divers_infos.inferieur_infos.replace( /(<([^>]+)>)/ig, '').substring(0,maxCarac)}<span className='lien-to' onClick={()=>setTextModal(value.divers_infos.inferieur_infos.replace( /(<([^>]+)>)/ig, ''))}>...</span></span> : (value.divers_infos!=null && value.divers_infos.inferieur_infos!=null) ? value.divers_infos.inferieur_infos.replace( /(<([^>]+)>)/ig, '') : '', 
                amount: <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.ttc} />,
                id: value.id,
                finalise:finalise,
                action: 
                    <span className="d-flex justify-content-between">
                        <ProfilActionButton path={'/invoice/details/'+value.id} />
                    </span>,
                index: ind + 1,
            }
            dataTable.push(obj)
        }
    })

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "action",
            text: "Actions",
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
        },
        {
            dataField: "type",
            text: t('document'),
            editable:false,
            sort:false,
        },
        {
            dataField: "bar",
            text: t('referencePo'),
            editable:false,
            sort:false,
        },
        {
            dataField: "createdAt",
            text: t('createdAt'),
            editable:false,
            sort:false,
        },
        {
            dataField: "status",
            text: t('status'),
            editable:false,
            sort:false,
        },
        {
            dataField: "client",
            text: t('customer'),
            editable:false,
            sort:false,
        },
        {
            dataField: "fournisseur",
            text: t('administrationFournisseurForm'),
            editable:false,
            sort:false,
        },
        {
            dataField: "amount",
            text: t('amountTtc'),
            editable:false,
            sort:false,
        },
        {
            dataField: "superieur",
            text: t('topText'),
            editable:false,
            sort:false,
        },
        {
            dataField: "inferieur",
            text: t('bottomText'),
            editable:false,
            sort:false,
        },
    ];

    const rowStyle = (row) => {
        const style = {};

        if(row.status==t('canceled')) {
            style.backgroundColor = '#fccfcf';//annulé
        }
        else if(row.finalise){
            style.backgroundColor = '#d9fcd2';//finalisé
        }
        else if(row.status==t('notCompleted')){
            style.backgroundColor = '#ffffbb';
        }
        
        return style;
    };

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.dossier_facture_table_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.dossier_facture_table_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie('dossier_facture_table_reorganize',arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: dataTable.length,
    };

    const handleFilter = (e) => {
        setFilter(e.target.value)
    }

    return (
        <div>
            <Modal show={textModal} onHide={() => setTextModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {textModal}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={showModalDocument} onHide={()=>setShowModalDocument(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('documentSaved')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(showModalDocument=='saved') ?
                        <Suspense fallback={<Loading></Loading>}>
                            <ModalSavedFacture 
                                // company={company}
                                folderParam={'/'+props.folderDetail.id}
                            />
                        </Suspense>
                    :
                        false
                    }
                </Modal.Body>
            </Modal>
            {columnOrganize.length!=0 &&
                <small>
                    <ToolkitProvider
                        keyField="index"
                        data={dataTable}
                        columns={columnOrganize}
                        search
                    >
                    {(propst) => (
                        <>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <ColumDataTableReorganize 
                                        columnOrganize={columnOrganize}
                                        setColumnOrganize={setColumnOrganize}
                                        setCookie={setCookie}
                                        cookieName='dossier_facture_table_reorganize'
                                        showColumnOrganize={showColumnOrganize}
                                        setShowColumnOrganize={setShowColumnOrganize}
                                        btnSwitch={false}//pour affiche ou non le btn swith
                                        initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                                    />&nbsp;&nbsp;&nbsp;
                                    <BtnShowDocSaved 
                                        btnStyle="btn btn-sm btn-info"
                                        setShowModalDocument={setShowModalDocument}
                                    />
                                </div>
                                <div className="col-4">
                                    <select className="form-control" value={filter} onChange={(e) => handleFilter(e)}>
                                        <option value="all">-- Select --</option>
                                        <option value="creditNote">{t('creditNote')}</option>
                                        <option value="purchaseOrderB2b">{t('purchaseOrderB2b')}</option>
                                        <option value="deliveryNote">{t('deliveryNote')}</option>
                                        <option value="returnNote">{t('returnNote')}</option>
                                        <option value="quotation">{t('quotation')}</option>
                                        <option value="billing">{t('billing')}</option>
                                        <option value="billingBuy">{t('billingBuy')}</option>
                                        <option value="billingDeposit">{t('billingDeposit')}</option>
                                    </select>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <span className="mb-2 me-1">{t('tableSearch')}:</span>
                                    <SearchBar {...propst.searchProps} className="mb-2" />
                                </div>
                            </div>
                            <div className="position-relative">
                                <CustomDataTable 
                                    propst={propst}
                                    paginationOptions={paginationOptions}
                                    columns={columns}
                                    defaultSortDirection="asc"
                                    rowStyle={rowStyle}
                                />
                            </div>
                        </>
                    )}
                    </ToolkitProvider>
                </small>
            }
        </div>
    )
};

export default DossierListDocumentTable;