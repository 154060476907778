import React, {useState,useEffect} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso, RedirectSwitchBtn, DescriptionRecords,ColumDataTableReorganize} from "@gull";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import swal from "sweetalert2";
import Moment from 'react-moment';
import { useCookies} from 'react-cookie';
import {scrollXDataTable} from 'app/scrollXDataTable';

const LocationHtml = ({zone,bins}) => {
    return(<>
        {zone.section.name}<br/>{zone.name+bins}
    </>)
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            pagination={ paginationFactory(props.paginationOptions) }
            onTableChange={ props.handleTableChange }
            noDataIndication={t('tableNoData')}
            wrapperClasses="no-sticky"//Ajouter cette class permet de d'éviter d'avoir le style sticky sur la première colonne du datatable pour certain datable qui n'ont pas de colonne action
            hover
            condensed
            responsive
        />
    );
};

const RecordTable = (props) => {
    const t = useTranslate();
    const [perPage,setPerPage] = useState(50)
    const [currentPage,setCurrentPage] = useState(1)

    const [cookies, setCookie] = useCookies(['record_table_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let type = {
        po: t('purchaseOrder'),
        shipment: t('shipmentVoucher'),
        entre: t('entreStockRecords'),
        simple: t('stockDeplacement'),
        cancelEntre: t('cancellationStockEntry'),
        ajust: t('stockAdjustment'),
        to: t('transferOrder'),
        ticket: t('ticket'),
        commande: t('salesOrder'),
        reassortInterne: t('internalRestock'),
        inventaire: t('inventory'),
        resolveNegative: t('resolveNegative'),
        facture: t('billing'),
        billing: t('billing'),
        toRecords: t('transferOrder'),
        quotation: t('quotation'),
        purchaseOrderB2b: t('purchaseOrderB2b'),
        poB2b: t('poB2bMouvement'),
        deliveryNote: t('deliveryNote'),
        deliveryNoteOutStock: t('deliveryNoteOutStock'),
        creditNote: t('creditNote'),
        returnNote: t('returnNote'),
        rn: t('returnNoteReturnInStock'),
        billingBuy: t('billingBuy'),
        billingOutStock: t('billingOutStock'),
    }

    const handleChangeFilter = (e) =>{
        props.setFilterValue(e.target.value)
    }

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: props.sizeDatas,
    };

    let articleRecordsList = new Array

    props.articleRecord.map((value,ind)=>{
        let bins = (value.bins!==null) ? (value.bins.fifth) ? ' - '+value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth+'-'+value.bins.fifth : (value.bins.fourth) ? ' - '+value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth : (value.bins.third) ? ' - '+value.bins.first+'-'+value.bins.second+'-'+value.bins.third : (value.bins.second) ? ' - '+value.bins.first+'-'+value.bins.second : value.bins.first : '';
        let obj = {
            id: value.id,
            quota: value.quota,
            type: type[value.type],
            description: <DescriptionRecords value={value}/>,
            created_at: <Moment format="DD/MM/YYYY HH:mm:ss">{value.created_at}</Moment>,
            create_by : (value.user) ? value.user.name : '',
            client: value.client!=null ? value.client.name+' '+value.client.firstname : "",
            redirige: <RedirectSwitchBtn urlDetails={value.redirige} zone_id={value.zone_id} description={value.description}/>,
            location: (value.zone!==null) ? <LocationHtml zone={value.zone} bins={bins}/> : '',
            index: ind + 1,  
        }
        articleRecordsList.push(obj)
    })

    let columns = [
        {
            dataField: "quota",
            text: t('quantity'),
            editable: false
        },
        {
            dataField: "type",
            text: 'Type',
            editable: false
        },
        {
            dataField: "description",
            text: 'Description',
            editable: false
        },
        {
            dataField: 'client',
            text: t('customer'),
            editable: false
        },
        {
            dataField: "location",
            text: t('location'),
            editable: false
        },
        {
            dataField: "created_at",
            text: t('registrationDate'),
            editable: false
        },
        {
            dataField: "create_by",
            text: t('createBy'),
            editable: false
        },
    ];

    if(props.module=='erp'){
        columns.push(
            {
                dataField: "redirige",
                text: "Actions",
                editable: false,
                sort: false
            },
        )
    }

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.record_table_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.record_table_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("record_table_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const handleTableChange = async (type, { page, sizePerPage }) => {
        setPerPage(sizePerPage)
        let filtre = (props.filterValue == '') ? null : props.filterValue
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/articlerecords/"+props.id+"/"+sizePerPage+"/"+page, {
            type:filtre
        }).then((response) => {
            props.setArticleRecord(response.data.datas)
            props.setSizeDatas(response.data.count)
            setCurrentPage(page)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return(
        <div>
            <CardPerso header={t('relatedRecord')}>
                <div className="mb-2 d-flex">
                    <ColumDataTableReorganize 
                        columnOrganize={columnOrganize}
                        setColumnOrganize={setColumnOrganize}
                        setCookie={setCookie}
                        cookieName='record_table_reorganize'
                        showColumnOrganize={showColumnOrganize}
                        setShowColumnOrganize={setShowColumnOrganize}
                        btnSwitch={false}//pour affiche ou non le btn swith
                        initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                    />
                    <div className="row d-flex justify-content-end position-relative" style={{width:"100%"}}>
                        <div className="col-6">
                            <select value={props.filterValue} className='form-control' onChange={(e)=>handleChangeFilter(e)}>
                                <option value="">{t('noFilter')}</option>
                                <option value="po">{t('po')}</option>
                                <option value="inventaire">{t('inventory')}</option>
                                <option value="reassortInterne">{t('internalRestock')}</option>
                                <option value="to">{t('transferOrder')}</option>
                                <option value="simple">{t('simpleMove')}</option>
                                <option value="ajust">{t('stockAdjustment')}</option>
                                <option value="ticket">{t('ticket')}</option>
                                <option value="commande">{t('salesOrder')}</option>
                                <option value="shipment">{t('shipmentVoucher')}</option>
                                <option value="entre">{t('entreStockRecords')}</option>
                                <option value="cancelEntre">{t('cancellationStockEntry')}</option>
                                <option value="resolveNegative">{t('resolveNegative')}</option>
                                <option value="billing">{t('billing')}</option>
                                <option value="quotation">{t('quotation')}</option>
                                <option value="purchaseOrderB2b">{t('purchaseOrderB2b')}</option>
                                <option value="poB2b">{t('poB2bMouvement')}</option>
                                <option value="deliveryNote">{t('deliveryNote')}</option>
                                <option value="deliveryNoteOutStock">{t('deliveryNoteOutStock')}</option>
                                <option value="creditNote">{t('creditNote')}</option>
                                <option value="returnNote">{t('returnNote')}</option>
                                <option value="rn">{t('returnNoteReturnInStock')}</option>
                                <option value="billingBuy">{t('billingBuy')}</option>
                            </select>
                        </div>
                        {(props.loadFiltered) ? 
                            <div className="loadFilterRestock"><div className="spinner-border spinner-border-sm"></div></div>
                        :
                            false
                        }
                    </div>
                </div>
                {columnOrganize.length!=0 && 
                    <small>
                        <ToolkitProvider
                        keyField="index"
                        data={articleRecordsList}
                        columns={columnOrganize}
                        >
                        {(propst) => (
                            <>
                                <CustomDataTable 
                                    propst={propst}
                                    paginationOptions={paginationOptions}
                                    handleTableChange={handleTableChange}
                                    columns={columns}
                                />
                            </>
                        )}
                        </ToolkitProvider>
                    </small>
                }
            </CardPerso>
        </div>
    )
}

export default RecordTable;