import React, {useState,useEffect,useContext} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso,Loading,FormatedPrice} from '@gull'
import { useParams} from "react-router-dom";
import axios from 'axios';
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import NameDossier from './CompDetailDossier/NameDossier'
import ClientDossier from './CompDetailDossier/ClientDossier'
import MemoDossier from './CompDetailDossier/MemoDossier'
import DossierListDocumentTable from './CompDetailDossier/DossierListDocumentTable'

const useLoad = (folderId) =>{
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [folderDetail,setFolderDetail] = useState({}) 

    useEffect(function () {
        (async function() {
            if(folderId){
                setLoading(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/dossier_b2b/get_one/"+folderId
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                        setLoading(false)
                    }
                    else{   
                        setFolderDetail(response.data.data)
                        setLoading(false)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setLoading(false)
                })
            }
        })()
    }, [folderId])

    return [loading,folderDetail,setFolderDetail]
}

const DetailDossierFactureListe = (props) => {
    const t = useTranslate();
    // const { id } = useParams();
    const { authParam } = useContext(IsAuthContext);
    const [loading,folderDetail,setFolderDetail] = useLoad(props.folderId)
    const [onEdit,setOnEdit] = useState({
        name:false,
        memo:false
    })

    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    const handleEdit = (name) =>{
        setOnEdit({...onEdit,[name]:!onEdit[name]})
    }

    const onSubmit = async (setValidButton,data) => {
        setValidButton(true)

        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/facture/dossier_b2b/edit/"+folderDetail.id, data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{ 
                setFolderDetail(response.data.data)
                setOnEdit({
                    name:false,
                    memo:false
                })
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }
            else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }
            else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
        setValidButton(false)
    };

    if(loading && props.bigLoader){
        return(
            <Loading></Loading>
        )
    }

    return(
        <div>
            {loading ? 
                <div className='d-flex justify-content-center mt-3'><div className="spinner-border spinner-border-sm"></div></div>
            :
                <>
                    <div className="row">
                        <div className="col-6">
                            <h3 className='fw-bold'>{t('folder')+' n° '+folderDetail.nbr}</h3>
                            <NameDossier 
                                folderDetail={folderDetail} 
                                onEdit={onEdit} 
                                handleEdit={handleEdit}
                                onSubmit={onSubmit}
                            />
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className='col-3 form-group'>
                                    <label>{t('company')}</label>
                                    <div>{folderDetail.other_company!=null ? folderDetail.other_company.name : authParam.company.name}</div>
                                </div>
                                <div className='col-6 form-group'>
                                    <label>{t('customer')}</label>
                                    <ClientDossier
                                        folderDetail={folderDetail}
                                        linkOnCLient={props.linkOnCLient}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div>
                            <CardPerso header='Memo'>
                                <MemoDossier 
                                    folderDetail={folderDetail} 
                                    onEdit={onEdit} 
                                    handleEdit={handleEdit}
                                    onSubmit={onSubmit}
                                />
                            </CardPerso>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div>
                            <CardPerso header={t('allDocuments')}>
                                <DossierListDocumentTable 
                                    folderDetail={folderDetail} 
                                    devise={devise} 
                                    digit={digit}
                                />
                            </CardPerso>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default DetailDossierFactureListe;