import React from "react";
import {Type} from 'react-bootstrap-table2-editor';
import {FormatedPrice} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";

const apiCall = async (row,done,t,obj) => {
    const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/article/edit/"+row.article_id,obj
    ).then((response) => {
        if(!response.data.valid){
            let interms='';
            var keys = Object.keys(response.data.errors);
            for(var i = 0; i < keys.length; i++){
                interms+=response.data.errors[keys[i]].join(',')+','
            }
            interms=interms.split(',')
            let messageError='';
            interms.forEach((interm)=>{
                let finalMessage=interm.split('/!');
                if(finalMessage.length==2){
                    messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                }
                else{
                    messageError+=t(interm)+','
                }
            })
            messageError=messageError.replace('undefined','');
            return done({
                valid: false,
                message: messageError
            });
        }
        else{
            return done()
        }
    }).catch((error) => {
        if(error=='end_session') return;
        let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
        return done({
            valid: false,
            message: messageError
        });
        
    })
};

const articleEditionDatatable = async (row,field,newValue,done,numeroEnumereOuPrice,t,authParam) => {
    let obj = {
        [field]:newValue
    }

    let alertAccess = authParam.access.find(access=>access.action == 'article_'+field+'_@!'+'alert')
    
    if(numeroEnumereOuPrice){
        //Si numeroEnumereOuPrice est true, c'est que l'on est sur l'édition d'un champ qui concerne les énumérés de conditionnement
        if(field.startsWith('name_')){
            //Si on edit le champ name_, on ajoute les autres champs qui concernent les énumérés de conditionnement
            //Car si on crée un conditionnement depuis le data table, le back à besoin d'avoir le name_ et reference_ de renseigné pour pouvoir créer un conditionnement
            //donc par défaut lorsqu'on modifie un champ qui concerne les énumérés, on envoie les 3 valeurs possible name_, reference_, quantity_
            obj['reference_'+numeroEnumereOuPrice] = row['reference_'+numeroEnumereOuPrice]
            //Si on crée un conditionnement depuis le data table et que aucune quantité n'a été renseigné, on envoie 0 
            obj['quantity_'+numeroEnumereOuPrice] = (row['quantity_'+numeroEnumereOuPrice]=='' || row['quantity_'+numeroEnumereOuPrice]==null) ? 0 : row['quantity_'+numeroEnumereOuPrice]

        }else if(field.startsWith('reference_')){
            obj['name_'+numeroEnumereOuPrice] = row['name_'+numeroEnumereOuPrice]
            //Si on crée un conditionnement depuis le data table et que aucune quantité n'a été renseigné, on envoie 0 
            obj['quantity_'+numeroEnumereOuPrice] = (row['quantity_'+numeroEnumereOuPrice]=='' || row['quantity_'+numeroEnumereOuPrice]==null) ? 0 : row['quantity_'+numeroEnumereOuPrice]

        }else if(field.startsWith('quantity_')){
            obj['name_'+numeroEnumereOuPrice] = row['name_'+numeroEnumereOuPrice]
            obj['reference_'+numeroEnumereOuPrice] = row['reference_'+numeroEnumereOuPrice]
        }
    }

    let fieldName = t(field) ? t(field) : field

    if(alertAccess){
        swal
        .fire({
            title: t('confirmArticleEdition',{articleField:fieldName})+" ?",
            icon: "warning",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('btnYes'),
            cancelButtonText: t('btnNo'),
        })
        .then((result) => {
            if (result.value) {
                apiCall(row,done,t,obj)
            }else{
                return done()
            }
        });
    }else{
        apiCall(row,done,t,obj)
    }
}

export default articleEditionDatatable;