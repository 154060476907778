import React, {useState, useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { ProfilActionButton, FormatedPrice,CarouselImage} from "@gull";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import axios from 'axios'
import swal from "sweetalert2";
import {scrollXDataTable} from 'app/scrollXDataTable';
import { Modal } from "react-bootstrap";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';

const BtnSell = (props) =>{
    const t = useTranslate();

    const handleClick = () => {
        props.sellItem(props.itemId) 
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('addToCart')}
                </Tooltip>
            }
        >
            <span onClick={() => handleClick()} style={{cursor:'Pointer', color:'#ff00ff'}}><FontAwesomeIcon icon={faCartPlus} /></span>
        </OverlayTrigger>  
    )
}


const useLoadInit = (palier,clientFideliteInfo) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(false)
    const [sizeDatas,setSizeDatas]=useState(0)
    const [articleList,setArticleList]=useState([]) 
    const [perPage,setPerPage]=useState(50)
    const [currentPage,setCurrentPage]=useState(1) 
 
    useEffect(function () {
        (async function() {
            if(palier){
                setLoading(true)
                const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/get_all_in_list/"+perPage+'/'+currentPage,{
                    articles:clientFideliteInfo.data[palier]
                }).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                        setLoading(false)
                    }
                    else {
                        setArticleList(response.data.datas)
                        setSizeDatas(response.data.count)
                        setLoading(false)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    if(error.response==undefined){
                        swal.fire(t('unknowError'), "", "error");
                    }
                    else if(error.response.status==403){
                        swal.fire(t('forbiddenGeneral'), "", "error");
                    }
                    else {
                        swal.fire(t('unknowError'), "", "error");
                    }
                    setLoading(false)
                })
            }
        })()
    }, [palier])
    
    return [loading,setSizeDatas,sizeDatas,perPage,setPerPage,currentPage,setCurrentPage,articleList,setArticleList]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            onTableChange={ props.handleTableChange }
            pagination={ paginationFactory(props.paginationOptions) }
            noDataIndication={t('tableNoData')}
            hover
            condensed
            responsive
        />
    );
};

const ListFreeItemCustomerReward = (props) =>{
    const t = useTranslate();
    const [loading,setSizeDatas,sizeDatas,perPage,setPerPage,currentPage,setCurrentPage,articleList,setArticleList] = useLoadInit(props.showList,props.clientFideliteInfo)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    //Pour le cookie
    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    //Pour le carousel
    const [showCarousel,setShowCarousel]=useState(false) 
    const [modalItemName,setModalItemName]=useState('') 
    const [modalImage,setModalImage]=useState('') 

    const sellItem = async (articleid) =>{
        //setLoadingSearchItem(true)
        props.setDisabledPay(true)
        props.setDisabledCloudButton(true)
        
        let data  
        if(articleid){//Si on recherche l'article à la main
            data = { 
                article_id:articleid
            }
        }
        
        if(props.cookies.current_ticket){
            data['id']=props.cookies.current_ticket

        }else{
            if(props.cashierId){
                data['rh_id']=props.cashierId
            }
            if(props.clientId){
                data['client_id']=props.clientId
            }
        }
        
        let currentTicketCookie

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                currentTicketCookie=response.data.data.id
                if(!props.cookies.current_ticket){
                    props.setCookie("current_ticket",currentTicketCookie,{ path: "/", expires: expire_date }) 
                }
                
                let articleAfficheur
                if(articleid){
                    articleAfficheur = response.data.data.ticket_detail.find(value=>value.article_id == articleid)
                }

                if(props.peripheriqueParam && props.peripheriqueParam.afficheur && props.peripheriqueParam.afficheur.model=='aures_2_l'){
                    props.affichageClient(articleAfficheur.article.name,articleAfficheur.prix_remise,articleAfficheur.quantity,0,props.devise,t,props.peripheriqueParam)
                    //le 0 sur la variable 'raison' est passé pour définir que les infos ne concerne pas le total
                }
                
                props.setShowModalFidelite(false)
            }
            //setLoadingSearchItem(false)
        }).catch((error) => {

            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        //setLoadingSearchItem(false)
        props.setRelanceFocus(Date.now())
        props.setDisabledCloudButton(false)
    }

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let datas = new Array
    articleList.forEach((value,ind)=>{
        let url = (props.from=='histoErp') ? '/erp/article/edit/'+value.id : '/pos/'+props.posid+'/item-base/details/'+value.id
        let obj = {
            name: value.name,
            masque:'',
            price: value.price,
            marque: (value.marque) ? value.marque.name : '-',
            reference: value.reference,
            image: (value.image!=null) ? <img className="vignetteBaseArticle" src={value.image.split(';')[0]} alt={value.name} onClick={()=>openModalCarousel(value.image,value.name)}/> : '',
            id: value.id,
            action: 
                <span className="d-flex justify-content-between">
                    <ProfilActionButton  path={url} openWindow={true}/> 
                    {(props.from=='actionClientBarLeft') && <BtnSell sellItem={sellItem} itemId={value.id}/>}
                    {/* <button className="btn btn-sm btn-success" onClick={()=>sellItem(value.id)}>{t('addItem')}</button> */}
                </span>,
            index: ind + 1,
        }
        datas.push(obj)
    })

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: sizeDatas
    };

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "action",
            text: "Actions",
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
        },
        {
            dataField: "name",
            text: t('tableName'),
            editable: false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "price",
            text: t('priceTtc'),
            editable: false,
            sort: true,
            headerSortingStyle,
            formatter: (rowData) => (<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={rowData} />),
        },
        {
            dataField: "marque",
            text: t('marque'),
            editable: false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "image",
            text: "Image",
            editable: false,
            sort: false,
            headerSortingStyle,
        },
    ];

    const handleTableChange = async (type, { page, sizePerPage}) => {
        setPerPage(sizePerPage)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/get_all_in_list/"+sizePerPage+"/"+page,{
            articles:props.clientFideliteInfo.data[props.showList]
        }).then((response) => {
            setArticleList(response.data.datas)
            setSizeDatas(response.data.count)
            setCurrentPage(page)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const openModalCarousel = (image,itemName) =>{
        setModalImage(image)
        setModalItemName(itemName)
        setShowCarousel(true)
    }

    const closeModalCarousel = () =>{
        setModalImage('')
        setModalItemName('')
        setShowCarousel(false)
    }

    return (
        <div>
            <Modal show={showCarousel} onHide={()=>closeModalCarousel()}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalItemName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CarouselImage images={modalImage} />
                </Modal.Body>
            </Modal>
            {(loading) ?
                <span className="d-flex justify-content-center align-items-center" ><div className="spinner-border spinner-border-md"></div></span>
            :
                <small>
                    <ToolkitProvider
                    keyField="index"
                    data={ datas }
                    columns={ columns }
                >
                    {(propst) => (
                        <div className="position-relative">
                            <CustomDataTable 
                                propst={propst}
                                paginationOptions={paginationOptions}
                                handleTableChange={handleTableChange}
                                columns={columns}
                            />
                        </div>
                    )}
                    </ToolkitProvider>
                </small>
            }
        </div>
    )
};

export default ListFreeItemCustomerReward;