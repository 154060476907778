import React, {useState, useRef} from "react";
import {InpDataList} from '@gull';
import { setNestedObjectValues } from "formik";

const EachDataFilter = (props) => {
    const [valueInput,setValueInput] = useState('')
    const inputRef = useRef('')
    const listRef = useRef('')
    const [datas,setDatas] = useState(props.list)
    const [disabledBlur,setDisabledBlur] = useState(false)

    //START Fonction concernant l'input type date
    const [inputValueDate, setInputValueDate] = useState('')

    const handleChangeDateValue = (e) =>{
        setInputValueDate(e.target.value)
    }

    const preventDatePickerOnEnter = (e) => {
        if (e.which === 13) { // enter key
            let updateFilter = props.sendData[props.inputName];
    
            updateFilter.push({id:inputValueDate,label:inputValueDate})
            
            props.setSendData({...props.sendData,[props.inputName]:updateFilter})
            setInputValueDate('')
        }
    }
    //END Fonction concernant l'input type date

    const handleClickFilter = (id,label,nameInput) => {
        setDisabledBlur(true)
        let updateFilter = props.sendData[nameInput]
        updateFilter.push({id:id,label:label})
        props.setSendData({...props.sendData,[nameInput]:updateFilter})        
    }

    const handleSearch = (e,declenchement) => {
        setValueInput(e.target.value);
        let ajour = props.list.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setDatas(ajour)
    }

    const handleFocus = () => {
        if(valueInput!=''){ 
            let ajour = props.list.filter((value)=>value.name.toLowerCase().includes(valueInput.toLowerCase()))
            setDatas(ajour)
        }else{
            setDatas(props.list)
            listRef.current.classList.remove("d-none");
        }
    }

    const handleBlur = () => {
        if(!disabledBlur){
            //On cache la liste seulement si on click à l'extérieur de l'input ou de la liste pour pouvoir sélectionner plusieurs filtres en meme temps
            listRef.current.classList.add("d-none")
            setValueInput('')
        }else{
            //Apres avoir cliqué sur une ligne de la liste, on remet le focus sur l'input sélectionné afin de savoir si on click en dehors de l'input ou si on click sur une ligne de la liste  
            setDisabledBlur(false)
            setTimeout(() => {
                if(inputRef && inputRef.current){
                    inputRef.current.focus()
                }
            }, 300);
        }
    }

    //Fonction Keypress qui à été ajouté pour pouvoir valide une saisie manuelle dans le select data 
    const handleKeyPress = (e) => {
        if(e.charCode==13){
            let updateFilter = props.sendData[props.inputName];
    
            updateFilter.push({id:valueInput,label:valueInput})
            
            props.setSendData({...props.sendData,[props.inputName]:updateFilter})
            setValueInput('')
        }
    }

    let isDate = [
        "commande",
        "expedition",
        "reception"
    ];

    return(<>
        {(isDate.includes(props.inputName)) ?
            <span className="position-relative">
                <input 
                    type='date' 
                    className="form-control" 
                    value={inputValueDate}  
                    name={props.inputName} 
                    onChange={(e)=>handleChangeDateValue(e)} 
                    onKeyDown={(e) => preventDatePickerOnEnter(e)}
                    onFocus={()=>handleFocus()}
                    onBlur={()=>handleBlur()}
                />
                <div ref={listRef} className="d-none" style={{position:'absolute', right:'0'}}>
                    {datas && datas.map((value,ind)=>
                        <li onMouseDown={()=> handleClickFilter(value.id,value.name,props.inputName)} className="list-group-item list-group-item-action pointer text-start" key={ind}>
                            {value.name}
                        </li>
                    )}
                </div>
            </span>
        :
            <InpDataList
                placeholder={props.selectType ? "-- Select --" : ''}
                value={valueInput}
                onClick={handleClickFilter}
                onChange={handleSearch}
                datas={datas}
                declenchement={0}
                listRef={listRef}
                inputRef={inputRef}
                onFocus={handleFocus}
                onBlur={handleBlur}
                inputName={props.inputName}

                onKeyPress={handleKeyPress}
            />
        }
    </>)
}

export default EachDataFilter;