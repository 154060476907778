import React, { useContext } from "react";
import { IsAuthContext } from 'app/App';
import { useTranslate } from 'react-redux-multilingual'
import { CSVDownloader } from 'react-papaparse'

const BtnImportModel = (props) => {
    const t = useTranslate()
    const {authParam} = useContext(IsAuthContext)
    
    return(
        <>
            <CSVDownloader
                data={props.modele.data}
                // type="button"
                filename={props.modele.fileName}
                bom={true}
                className="btn btn-dark btn-sm"
                config={{
                    delimiter:authParam.company.advanceparams.csv_separator,
                    encoding:authParam.company.advanceparams.csv_encode
                }}
            >
                {t('template')}
            </CSVDownloader>
        </>
    )
}

export default BtnImportModel;
