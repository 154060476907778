import React, {useState,lazy, Suspense} from 'react';
import {Loading} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPenAlt, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ModalEditUserAccess = lazy (() => import("../modal/ModalEditUserAccess"));

const BtnAccessReadWrite = (props) => {
    const t = useTranslate();
    const [show,setShow] = useState()

    let modalname = show && show.split('_@!')[1]=='read' ? t('fieldReadingNotAvl') : show && show.split('_@!')[1]=='write' ? t('fieldEditingNotAvl') : show && show.split('_@!')[1]=='alert' ? t('fieldEditingAlertNotAvl') : ''

    const closeModal = () => {
        setShow(false)
    };

   return(
       <div className='position-relative'>
            <Modal size="md" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.fieldName+' : '+modalname}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Suspense fallback={<Loading></Loading>}>
                        <ModalEditUserAccess 
                            module='article' 
                            accessName={show}//Nom de l'action
                            closeModal={closeModal} 
                            company={props.company} 
                            setRelance={props.setRelance} 
                            allAccess={props.allAccess} 
                        />
                    </Suspense>
                </Modal.Body>
            </Modal>
            {(!props.hasOwnProperty('permissionShown') || (props.hasOwnProperty('permissionShown') && props.permissionShown=='') || (props.hasOwnProperty('permissionShown') && props.permissionShown=='read')) &&
                <>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id={`tooltip-${props.index}`}>
                                {t('fieldReading')}
                            </Tooltip>
                        }
                    >
                        <span>
                            <FontAwesomeIcon 
                                icon={faEye} 
                                className="text-success pointer"
                                onClick={()=>setShow(props.accessAction+'read')}
                            />
                        </span>
                    </OverlayTrigger>&nbsp;&nbsp;&nbsp;
                </>
            }
            {(!props.hasOwnProperty('permissionShown') || (props.hasOwnProperty('permissionShown') && props.permissionShown=='') || (props.hasOwnProperty('permissionShown') && props.permissionShown=='write')) &&
                <>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id={`tooltip-${props.index}`}>
                                {t('fieldEditing')}
                            </Tooltip>
                        }
                    >
                        <span>
                            <FontAwesomeIcon 
                                icon={faPenAlt} 
                                className="text-info pointer"
                                onClick={()=>setShow(props.accessAction+'write')}
                            />
                        </span>
                    </OverlayTrigger>&nbsp;&nbsp;&nbsp;
                </>
            }
            {(!props.hasOwnProperty('permissionShown') || (props.hasOwnProperty('permissionShown') && props.permissionShown=='') || (props.hasOwnProperty('permissionShown') && props.permissionShown=='write')) &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id={`tooltip-${props.index}`}>
                            {t('editConfirmation')}
                        </Tooltip>
                    }
                >
                    <span>
                        <FontAwesomeIcon 
                            icon={faCommentDots} 
                            className="text-warning pointer"
                            onClick={()=>setShow(props.accessAction+'alert')}
                        />
                    </span>
                </OverlayTrigger>  
            }
       </div>
   )
}

export default BtnAccessReadWrite;