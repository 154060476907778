import React, {Fragment, useContext } from 'react';
import { Redirect, useLocation, useHistory } from "react-router-dom";
import GullLayout from "app/GullLayout/GullLayout";
import { IsAuthContext } from "../App";
import { PeripheriqueContext } from '../App';
import { Loading } from "@gull";
import axios from 'axios'
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";

const Guard = (props) => {
    const t =useTranslate()
    const location = useLocation();
    const history = useHistory()
    const domain = location.pathname.split('/');
    const globalUserParam = useContext(IsAuthContext);
    const globalPeripheriqueParam = useContext(PeripheriqueContext)

    const verifPeripherique = async (posId,caisse_ident) => {
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/pos/get_one_caisse_ident/"+caisse_ident
        ).then((response) => {
            if(!response.data.valid){
                swal.fire(t('unknowError'), "", "error");
            }
            else{
                let all_peripherique = { // liste des peripherique par type. S'il y a de nouveaux types de peripherique alors il faut les ajouter ici
                    section:posId,
                    caisse_ident:caisse_ident,
                    afficheur: false,
                    imprimante_ticket: false,
                    imprimante_etiquette: false,
                    tpe:false
                }
                response.data.data.peripherique.forEach(value=>{
                    all_peripherique[value.type]={
                        name:value.name,
                        host:value.host,
                        model:value.model,
                        hostname:value.hostname,
                        baudrate:value.baudrate,
                    }
                })
                globalPeripheriqueParam.setPeripheriqueParam(all_peripherique)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const verifyCaisseOpen = async (posId,caisseid,caisse_ident) =>{
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/pos/pos_status/"+posId+'/'+caisseid+'/'+caisse_ident
        ).then((response) => {
            if(!response.data.valid){
                localStorage.removeItem('pos')
                history.push("/openpos/"+domain[2])
            }
            else{
                let valid=true
                if(!globalPeripheriqueParam.peripheriqueParam) valid=false// on verifie qu'il connaisse ses peripherique, si ce n'est pas le cas alors on lui dit d'aller les chercher dans la fonction ci-dessous
                else if(globalPeripheriqueParam.peripheriqueParam.caisse_ident!=caisse_ident) valid=false//ou alors on verifie s'il n'a pas les perihperique d'une autre caisse en memoire
                    
                if(!valid) verifPeripherique(posId,caisse_ident)
            }
        }).catch((error) => {
            localStorage.removeItem('pos')
            history.push("/openpos/"+domain[2])
            // if(error=='end_session') return;
            // swal.fire(t('unknowError'), "", "error");
        })
    }

    if(globalUserParam.authParam.isauth==='wait'){
        return(<Loading></Loading>)
    }
    else if(!globalUserParam.authParam.isauth){
        return <Redirect to="/session/signin" />
    }
    else if(domain[1]=='pos'){
        const open=localStorage.getItem("pos")
        if(open){
            const ids = open.split(',');
            const caisseid = ids[0]
            const caisse_ident = ids[1]
            
            verifyCaisseOpen(domain[2],caisseid,caisse_ident)
        }
        else{
            return <Redirect to={"/openpos/"+domain[2]} />
        }
    }
    else if(domain[1]=='cloturepos'){
        const open=localStorage.getItem("pos")
        if(open){
            const ids = open.split(',');
            const posId = ids[0]
            const caisse_ident = ids[1]
            let valid=true
            if(!globalPeripheriqueParam.peripheriqueParam) valid=false// on verifie qu'il connaisse ses peripherique, si ce n'est pas le cas alors on lui dit d'aller les chercher dans la fonction ci-dessous
            else if(globalPeripheriqueParam.peripheriqueParam.caisse_ident!=caisse_ident) valid=false//ou alors on verifie s'il n'a pas les perihperique d'une autre caisse en memoire
            if(!valid) verifPeripherique(posId,caisse_ident)
        }
    }

    let { route } = props;
    return (<Fragment>
        <GullLayout route={route}></GullLayout>
    </Fragment>)
}

export default Guard;