import React, {useContext,lazy,Suspense} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from 'app/App';
import { Loading } from '@gull';

const BtnEditField = lazy (() => import("./BtnEditField"));
const EditDetailArticleSelect = lazy (() => import("./EditFormComp/EditDetailArticleSelect"));
const EditPriceForm = lazy (() => import("./EditFormComp/EditPriceForm"));
const EditCustomFieldForm = lazy (() => import("./EditFormComp/EditCustomFieldForm"));
const EditHeaderForm = lazy (() => import("./EditFormComp/EditHeaderForm"));

const LineInfoArticle = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);

    let unauthorizedUser = authParam.access.find(access=>access.action==props.allowAccessName+'read')

    if(unauthorizedUser){//Si unauthorizedUser est true, c'est qu'il y a une restriction sur notre compte, on ne peut donc pas faire d'édition 
        return(<></>)
    }else{
        return(
            <div className={props.style}>
                <label>
                    {(props.hasOwnProperty('titleSize') && props.onEdit[props.isFor]) ?
                        false
                    :
                        <span className={`${props.hasOwnProperty('titleSize') ? props.titleSize : 'fs-5 fw-bold'}`}>{props.title+' :'}</span>
                    }&nbsp;
                    {props.editable=='true' && <BtnEditField isFor={props.isFor} allowAccessName={props.allowAccessName} onEdit={props.onEdit} handleEdit={props.handleEdit} />}
                </label>
                {(props.hasOwnProperty('onEdit') && props.onEdit[props.isFor]) ?
                    (props.form=='detailArticle') ?
                        <Suspense fallback={<Loading></Loading>}>
                            <EditDetailArticleSelect onSubmit={props.onSubmit} validButton={props.validButton} liaisons={props.liaisons} liaisonName={props.isFor} defaultValue={props.defaultValueEdit}/>
                        </Suspense>
                    :
                    (props.form=='detailPrice') ?
                        <Suspense fallback={<Loading></Loading>}>
                            <EditPriceForm onSubmit={props.onSubmit} validButton={props.validButton}  editName={props.isFor} defaultValue={props.defaultValueEdit} defaultValue2={props.defaultValueEdit2} authParam={authParam}/>
                        </Suspense>
                    :
                    (props.form=='detailCustomField') ?
                        <Suspense fallback={<Loading></Loading>}>
                            <EditCustomFieldForm onSubmit={props.onSubmit} validButton={props.validButton} editName={props.isFor} defaultValue={props.defaultValueEdit} isFree={props.isFree} list={props.list}/>
                        </Suspense>
                    : 
                    (props.form=='header') ?
                        <Suspense fallback={<Loading></Loading>}>
                            <EditHeaderForm onSubmit={props.onSubmit} validButton={props.validButton} editName={props.isFor} defaultValue={props.defaultValueEdit}/>
                        </Suspense>
                    :
                        false
                :
                    <div>{props.defaultValue}</div>
                }
            </div>
        )
    }
}

export default LineInfoArticle;