import { lazy } from "react";

const Signin = lazy(() => import("./Signin"));

const Error404 = lazy(() => import("./Error"));
const DownloadDocument = lazy(() => import("./DownloadDocument"));
const Sso = lazy(() => import("./Sso"));

const sessionsRoutes = [
  {
    path: "/session/signin",
    component: Signin
  },
  {
    path: "/session/sso",
    component: Sso
  },
  {
    path: "/session/document/download/:id/:token",
    component: DownloadDocument
  },
  {
    path: "/session/404",
    component: Error404
  }
];

export default sessionsRoutes;
