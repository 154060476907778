import React, {useState,useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice,CardPerso } from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { IsAuthContext} from 'app/App';
import DetailGiftCardModal from "../Historique/component/DetailGiftCardModal"
import Moment from 'react-moment';

const useLoad = (clientId,tabKey) => {
    const t =useTranslate()
    const [allGiftCards,setAllGiftCards]=useState([])
    const [load,setLoad]=useState({
        allGiftCard:false,
        oneGiftCard:true
    })

    useEffect(function () {
        (async function() {
            if(tabKey=='giftcard'){
                setLoad({...load,allGiftCard:true})
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/gift_card/get_all_of_client/"+clientId
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{   
                        setAllGiftCards(response.data.datas)
                        setLoad({...load,allGiftCard:false})
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoad({...load,allGiftCard:false})
            }
        })()
    }, [clientId,tabKey])

    return [allGiftCards,load,setLoad]
}

const HistoGiftcardClient = (props) => {
    const t =useTranslate()
    const [allGiftCards,load,setLoad]=useLoad(props.clientId,props.tabKey)
    const { authParam } = useContext(IsAuthContext)
    const [detailGiftCards,setDetailGiftCards]=useState({})
    const [show,setShow]=useState(false)

    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    const getGiftCardDetail = async (giftCardId) =>{
        setShow(true)
        setLoad({...load,oneGiftCard:true})
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/gift_card/get_one/"+giftCardId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError=''; 
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setDetailGiftCards(response.data.data)
                setLoad({...load,oneGiftCard:false})
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({...load,oneGiftCard:false})
    }

    return(<>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('detail')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(load.oneGiftCard) ? 
                    <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                : 
                    <DetailGiftCardModal 
                        detailGiftCards={detailGiftCards}
                        devise={devise}
                        digit={digit}
                    />
                }
            </Modal.Body>
        </Modal>
        <div className="row mt-4 d-flex justify-content-center">
            <CardPerso>
                <table
                    className="table"
                >
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">{t('giftcard')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(load.allGiftCard) ? 
                            <tr className="mt-3 d-flex justify-content-center">
                                <td className="spinner-border spinner-border-sm"></td>
                            </tr>
                        : 
                            allGiftCards.length!=0 && allGiftCards.map((value,ind) =>{
                                
                                let dateExpire = value.gift_card_records.filter(value=>value.is=='credit')
                                let lastExpireDate = dateExpire[+dateExpire.length-1]
                                
                                let style = (ind%2 == 0) ? 'stripeBg stripePadding pointer btnTicket' : 'stripePadding pointer btnTicket';

                                return(
                                    <tr key={value.id} className={style} onClick={()=>getGiftCardDetail(value.id)}>
                                        <td className="row" style={{marginLeft:'0', marginRight:'0'}}>
                                            <strong className="col-4">{value.reference}</strong> 
                                            <span className="col-4 text-center">{t('dateExpire')+': '}<strong>{<Moment format="DD/MM/YYYY">{lastExpireDate.expire}</Moment>}</strong></span>
                                            <span className="col-4 text-end">
                                                <span className="d-inline-block">{<FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={value.disponible} />}</span>
                                            </span>
                                        </td>
                                    </tr>
                                )          
                            })
                        }
                    </tbody>
                </table>
            </CardPerso>
        </div>
        </>
    )
    
}

export default HistoGiftcardClient;