import React, { useState,useEffect,useRef,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { MasterClientSelect,MasterCaissierSelect,FormatedPrice} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf,faEdit } from '@fortawesome/free-solid-svg-icons';
import Ticket from "../../../../app/views/Pdf/Ticket"
import TicketFacture from "../../../../app/views/Pdf/TicketFacture"
import { BlobProvider } from '@react-pdf/renderer';
import { conditionsRetour } from 'app/conditionsRetour';
import DateEdit from './DateEdit'

const GeneralInfos = (props) => {
    const t = useTranslate()
    const [load,setLoad]=useState({
        caissier:false,
        client:false,
        pdf:false,
        invoice:false
    })
    const {authParam} = useContext(IsAuthContext);
    const [valueInputCashier,setValueInputCashier]=useState('')
    const [valueInputClient,setValueInputClient]=useState('')
    const [invalidInput,setInvalidInput]=useState({
        searchCashier: true,
        searchClient: true
    })
    const [datasCashier,setDatasCashier] = useState([])
    const dataListDrop = useRef('')
    const searchInput = useRef('')
    const searchInputClient = useRef('')
    const [datas,setDatas] = useState([])
    const [barObj,setBarObj] = useState({})
    const [rhIdObj,setRhIdObj] = useState({})
    const [editDate,setEditDate]=useState(false)

    const [totalTgc,setTotalTgc]=useState(false)
    const btnPdf = useRef('')
    const btnTicketFacture = useRef('')

    useEffect(function () {//mise à jour des noms caisser et clients si ils sont renseignés et remplissage de a drop liste du cashier
        let initial = []
        let initialBarObj = {};
        let initialRhIdObj = {};
        props.section.rh.forEach((value)=>{
            initial.push({
                id:value.id,
                name:value.name+' '+value.firstname
            })
            initialBarObj={...initialBarObj,[value.bar]:value.name+' '+value.firstname}
            initialRhIdObj={...initialRhIdObj,[value.bar]:value.id}
        })
        setBarObj(initialBarObj)
        setRhIdObj(initialRhIdObj)
        setDatasCashier(initial)
        setDatas(initial)
        if(Object.keys(props.ticketDetail).length!=0){
            let initial = {
                searchCashier:true,
                searchClient:true
            };
            if(props.ticketDetail.rh!=null){
                setValueInputCashier(props.ticketDetail.rh.name+' '+props.ticketDetail.rh.firstname)
                initial.searchCashier=false
            }
            if(props.ticketDetail.client!=null){
                setValueInputClient(props.ticketDetail.client.name+' '+props.ticketDetail.client.firstname+' - '+props.ticketDetail.client.email+' - '+props.ticketDetail.client.tel)
                initial.searchClient=false
            }
            setInvalidInput(initial)
        }

        let totaltgc = {}
        props.ticketDetail.ticket_detail.map(item=>{
            if(item.tgc!=0){//on exécute pas cette action sur le taux 0
                if(totaltgc[item.tgc]){
                    //si le taux de tgc existe deja on ajoute le pris du produit au total existant
                    totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
                }else{
                    //Si aucun taux existe on ajoute la taxe 
                    totaltgc={...totaltgc,[item.tgc]:item.prix_remise}
                }
                
            }
        })
        setTotalTgc(totaltgc)
    }, [])

    let tgcForTicketPdf={}
    Object.entries(totalTgc).forEach(([tgcName,montant])=>{
        //fonction qui calcule le montant de tgc à payer par type de tgc
        let coef = +tgcName / 100 + 1
        let totalAmountTgc = +montant - (Math.round(+montant / +coef))
        tgcForTicketPdf[tgcName] = totalAmountTgc
    })
    //on calcule le total de tgc payé sur le ticket
    let tgcTotal = 0 
    Object.entries(tgcForTicketPdf).map(([key,value])=> 
        tgcTotal+=value
    )

    let thisShop = authParam.sections.length!=0 && authParam.sections.find(value=>value.id==props.ticketDetail.section_id)

    const noRedInput = (isfor) => {
        if(isfor=='searchCashier') setValueInputCashier('')
        if(isfor=='searchClient') setValueInputClient('')
        setInvalidInput({...invalidInput,[isfor]:false})
    }

    const updateCurentTicket = async (isFor,id,name) =>{//fonction exécutée si un ticket est en cours 

        let data={
            id:props.ticketDetail.id,
        }
        if(isFor == 'searchCashier'){
            data['rh_id']=id
            setLoad({...load,caissier:true})
        }else{
            data['client_id']=id
            setLoad({...load,client:true})
        }
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.ticketDetail.section_id+"/"+props.ticketDetail.caisse_ident_id+"/"+props.ticketDetail.pos_id, data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setTicketDetail(response.data.data)
                setLoad({
                    ...load,
                    caissier:false,
                    client:false
                })

                if(isFor == 'searchCashier'){
                    setValueInputCashier(name)
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({
            ...load,
            caissier:false,
            client:false
        })
    }

    const handleSearch = (e,declenchement) => {
        setValueInputCashier(e.target.value)
        if(e.target.value.length>=declenchement){
            let ajour = datasCashier.filter(value=>value.name.toLowerCase().includes(e.target.value.toLowerCase()));
            setDatas(ajour)
            dataListDrop.current.classList.remove("d-none");
        }
        else{
            dataListDrop.current.classList.add("d-none");
        }        
    }

    const handleCLick = (id,valeur) => {
        setValueInputCashier(valeur)
        setInvalidInput({...invalidInput,searchCashier:false})

        updateCurentTicket('searchCashier',id,valeur)
    }

    const handleKeyPress = (e) => {
        if(e.charCode==13){
            if(barObj[e.target.value]){
                setValueInputCashier(barObj[e.target.value])
                setInvalidInput({...invalidInput,searchCashier:false})

                updateCurentTicket('searchCashier',rhIdObj[e.target.value],barObj[e.target.value])

                setTimeout(()=>{
                    searchInput.current.blur()
                },200)
            }
        }
    }

    const handleBlur = () => {
        if(Object.keys(props.ticketDetail).length!=0){
            if(props.ticketDetail.rh!=null){
                setValueInputCashier(props.ticketDetail.rh.name+' '+props.ticketDetail.rh.firstname)
                setInvalidInput({...invalidInput,searchCashier:false})

            }else{
                setValueInputCashier('')
                setInvalidInput({...invalidInput,searchCashier:true})
            }
        }
        dataListDrop.current.classList.add("d-none");
    }

    const handleClick = (isFor) =>{
        setLoad({...load,[isFor]:true})

        if(isFor=='pdf'){
            setTimeout(()=>{
                btnPdf.current.click()
                setLoad({...load,[isFor]:false})
            },1000)
        }else{
            setTimeout(()=>{
                btnTicketFacture.current.click()
                setLoad({...load,[isFor]:false})
            },1000)
        }
    }
    
    return(
        <div className="row">
            <BlobProvider 
                document={(Object.keys(props.ticketDetail).length!=0 && tgcTotal!=NaN) ?
                    <Ticket 
                        thisShop={thisShop}
                        ticketPdf={props.ticketDetail}
                        t={t}
                        devise={props.devise}
                        digit={props.digit}
                        totalAchat={props.totalAchat}
                        tgcTotal={tgcTotal}
                        tgcForTicketPdf={tgcForTicketPdf}
                        pointsFidelite={props.pointsFidelite}
                        moduleParam={props.moduleErp}
                        authParam={authParam}
                    />
                : 
                    false
                }
            >
                {({ url }) => (
                    <a ref={btnPdf} className="d-none" href={url} target="_blank">Print</a>
                )}
            </BlobProvider>
            <BlobProvider 
                document={(Object.keys(props.ticketDetail).length!=0 && tgcTotal!=NaN) ?
                    <TicketFacture 
                        t={t}
                        documentType='billing'
                        companyInfos={thisShop}
                        authParam={authParam}
                        ticketFacture={props.ticketDetail}
                        totalTgc={tgcTotal}
                        totalAchat={props.totalAchat}
                        tgcForTicketPdf={tgcForTicketPdf}
                        devise={props.devise}
                        pointsFidelite={props.pointsFidelite}
                        moduleParam={props.moduleErp}
                    />
                : 
                    false
                }
            >
                {({ url }) => (
                    <a ref={btnTicketFacture} className="d-none" href={url} target="_blank">Print</a>
                )}
            </BlobProvider>
            <div className="form-group col-6">
                <label>{t('referencePo')+' :'}</label>
                <div>{props.ticketDetail.bar}</div>
            </div>
            <div className="form-group col-6">
                <label className="fw-bold">{t('cartTotal')+' :'}</label>
                <div className="fw-bold"><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.totalAchat} /></div>
            </div>
            <div className="form-group col-6 mt-3">
                <label>Date :{<span>&nbsp;<FontAwesomeIcon icon={faEdit} className='fs-5 text-primary pointer' onClick={()=>setEditDate(!editDate)}/></span>}</label><br />
                {(!editDate) ? 
                    <Moment format="DD/MM/YYYY HH:mm">{props.ticketDetail.date_validation}</Moment>
                :
                    <DateEdit ticketDetail={props.ticketDetail} setTicketDetail={props.setTicketDetail} setEditDate={setEditDate}/>
                }
                
            </div>
            <div className="form-group col-6 mt-3">
                <label>{t('shop')+' :'}</label>
                <div>{(props.ticketDetail.section) && props.ticketDetail.section.name}</div>
            </div>
            {(props.ticketDetail.commande && props.ticketDetail.commande!=null) ? 
                <div className="form-group col-6 mt-3">
                    <label>{t('salesOrder')+' :'}</label>
                    <div><Link to={'/erp/caisse/sales-order/'+props.ticketDetail.commande.id}>{props.ticketDetail.commande.bar}</Link></div>
                </div>
            :
                false
            }
            <MasterCaissierSelect
                value={valueInputCashier}
                onClick={handleCLick}
                onChange={handleSearch}
                datas={datas}
                listRef={dataListDrop}
                inputRef={searchInput}
                onBlur={handleBlur}
                className={(invalidInput.searchCashier) ? "form-control is-invalid" : "form-control"}
                onFocus={noRedInput}
                onKeyPress={handleKeyPress}
                load={load}
            />
            <MasterClientSelect 
                setValueInputClient={setValueInputClient}
                valueInputClient={valueInputClient}
                invalidInput={invalidInput}
                setInvalidInput={setInvalidInput}
                noRedInput={noRedInput}
                updateCurentTicket={updateCurentTicket}
                load={load}
                currentTicket={props.ticketDetail}
                searchInputClient={searchInputClient}
            />
            {/* <div className="form-group col-6 mt-3">
                <label>{t('customer')+' :'}</label>
                <div className="row">
                    <ActionClientBarLeft 
                        setValueInputClient={setValueInputClient}
                        valueInputClient={valueInputClient}
                        invalidInput={invalidInput}
                        setInvalidInput={setInvalidInput}
                        noRedInput={noRedInput}
                        updateCurentTicket={updateCurentTicket}
                        load={load}
                        currentTicket={props.ticketDetail}
                        from='erp'
                        module='erp'//à passer pour obligatoirement pour connaitre les options de son module 
                        style="col-7 position-relative"
                        searchInputClient={searchInputClient}
                    />
                </div>
            </div> */}
            <div className="form-group col-6 mt-3">
                <button className="btn btn-sm btn-primary" onClick={()=>handleClick('pdf')}>
                    {load.pdf ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : <span>{t('download')}&nbsp;<FontAwesomeIcon icon={faFilePdf} style={{fontSize:'1em'}} /></span>}
                </button>&nbsp;
                <button className="btn btn-sm btn-info" onClick={()=>handleClick('invoice')}>
                    {load.invoice ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : <span>{t('billing')+' A4'}</span>}
                </button>
            </div>
            {(props.moduleErp.params.fidelite.active) &&
                <div className="form-group col-6 mt-2">
                    <label>{t('loyaltyPoints')+' :'}</label>
                    <div>{t('totalCumulLoyaltyPoint')+' : '+props.pointsFidelite.total}</div>
                    <div>{t('earnedLoyaltyPoint')+' : '+props.pointsFidelite.acquis}</div>
                </div>
            }
        </div>
    )
}

export default GeneralInfos;