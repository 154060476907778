import swal from "sweetalert2";
import axios from "axios";
import https from "https"

const printTagImgFile = (peripheriqueParam,print,setPrint,qteTag,setUrlCertificat,redirectCertificat) =>{
    let bauds = peripheriqueParam.baudrate ? '&baudRate='+peripheriqueParam.baudrate : ''

    const response = axios.get('http://localhost/portcom/printImageFile.php?printerName='+peripheriqueParam.host+'&fileName='+print+'.pdf&orientation=landscape&qteTag='+qteTag+bauds
    ).then((response) => {
        console.log('good')
        setPrint(false)
    }).catch((error) => {
        console.log(error)
        // if (peripheriqueParam.hostname!=null) {
        //     setUrlCertificat('http://localhost/portcom/printImageFile.php?printerName='+peripheriqueParam.host+'&fileName='+print+'.pdf&orientation=landscape&qteTag='+qteTag)
        //     setTimeout(() => {
        //         redirectCertificat.current.click()
        //     }, 500);
        // }
    })
}

const printTagImgFileWms = (peripheriqueParam,print,setPrint,qteTag,base64Img,setUrlCertificat,redirectCertificat,indexListItems,setIndexListItems,from) =>{
    let dataPost;

    if(Array.isArray(base64Img)){
        let arr = []
        for (let i = 0; i < base64Img.length; i++) {
            let obj = {
                printerName:peripheriqueParam.host,
                fileName:print[i],
                orientation:'landscape',
                qteTag:qteTag[i],
                image:base64Img[i],
            }
            if(peripheriqueParam.baudrate) obj['baudRate'] = peripheriqueParam.baudrate

            arr.push(obj)
        }

        dataPost = arr
    }else{
        let obj = {
            printerName:peripheriqueParam.host,
            fileName:print,
            orientation:'landscape',
            qteTag:qteTag,
            image:base64Img,
        }
        if(peripheriqueParam.baudrate) obj['baudRate'] = peripheriqueParam.baudrate

        dataPost = obj
    }

    const response = axios.post('https://'+peripheriqueParam.hostname+'/portcom/printWms.php', dataPost
    ).then((response) => {
        console.log('good')
        setPrint(false)
        if(from && from=='tranche') setIndexListItems(+indexListItems + 1);
    }).catch((error) => {
        console.log(error)
        if (peripheriqueParam.hostname!=null) {
            setUrlCertificat('https://'+peripheriqueParam.hostname+'/portcom/printWms.php')
            setTimeout(() => {
                redirectCertificat.current.click()
            }, 500);
        }
    })
}

export const imprimeEtiquetteImgFile = (peripheriqueParam,print,setPrint,qteTag,t,base64Img,setUrlCertificat,redirectCertificat,indexListItems,setIndexListItems,from) =>{

    if(base64Img){//Impression d'étiquette WMS
        printTagImgFileWms(peripheriqueParam,print,setPrint,qteTag,base64Img,setUrlCertificat,redirectCertificat,indexListItems,setIndexListItems,from)

    }else if(peripheriqueParam && peripheriqueParam.model=='image_file'){
        printTagImgFile(peripheriqueParam,print,setPrint,qteTag,setUrlCertificat,redirectCertificat)

    }else{
        swal.fire(t('noConfiguredPeripheral'), "", "error");
    }
}