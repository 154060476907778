import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import RefundGiftCard from './RefundOption/RefundGiftCard'
import RefundEspece from './RefundOption/RefundEspece'
import RefundOther from './RefundOption/RefundOther'
import {ControlAccess} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";

const RefundForm = (props) =>{
    const t =useTranslate()
    const [refundMode,setRefundMode]=useState(false)
    const [inputValue,setInputValue]=useState({
        inputBarCode:'',
        inputNoteOther:''
    }) 
    const [load,setLoad]=useState(false) 
    const [errorPay,setErrorPay]=useState(false)
    const [isRestriction,setIsRestriction]=useState(false)

    const idsLocalStorage=localStorage.getItem("pos").split(',')

    useEffect(function () {
        if(isRestriction){
            if(isRestriction=='other') props.setRestrictionRemboursementOther({...props.restrictionRemboursementOther,go:false})
            if(isRestriction=='espece') props.setRestrictionRemboursementEsp({...props.restrictionRemboursementEsp,go:false})
            setIsRestriction(false)
        }
    }, [refundMode])

    const refundApi = async (data) =>{
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setInputValue({
                    inputBarCode:'',
                    inputNoteOther:''
                })
                setLoad(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad(false)
        setTimeout(()=>{
            setErrorPay(false)
        },1500)
    }

    const callback = () =>{
        if(refundMode=='other'){ 
            props.setRestrictionRemboursementOther({...props.restrictionRemboursementOther,go:true})
            setIsRestriction('other')
        }
        if(refundMode=='espece'){ 
            props.setRestrictionRemboursementEsp({...props.restrictionRemboursementEsp,go:true})
            setIsRestriction('espece')
        }
    }
    
    return(<>
        {(!refundMode) ?
            <div className="d-flex flex-column justify-content-between align-items-center" style={{height:'80%'}}>
                <label className="fw-bold text-center">{t('chooseRefundMethod')}</label>
                <div className="row" style={{width:'90%'}}>
                    <button className="p-3 btn btn-lg btn-success" onClick={()=>setRefundMode('espece')}>
                        {t('espece')}
                    </button>
                </div>
                {(props.modulePos.params.gift_card.active) &&
                    <div className="row" style={{width:'90%'}}>
                        <button className="p-3 btn btn-lg btn-warning" onClick={()=>setRefundMode('giftcard')}>
                            {t('giftcard')}
                        </button>
                    </div>
                }
                <div className="row" style={{width:'90%'}}>
                    <button className="p-3 btn btn-lg btn-info" onClick={()=>setRefundMode('other')}>
                        {t('other')}
                    </button>
                </div>
            </div>
        :
            <>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    {(refundMode=='espece') ? <label className="fw-bold text-center">{t('refundEspece')}</label> : false}
                    {(refundMode=='giftcard') ? <label className="fw-bold text-center">{t('refundGiftcard')}</label> : false}
                    {(refundMode=='other') ? <label className="fw-bold text-center">{t('refundOther')}</label> : false}
                    <button className="btn btn-sm btn-danger" onClick={()=>setRefundMode(false)}>{t('retour')}</button>
                </div>
                {((!props.restrictionRemboursementEsp.go && refundMode=='espece') || (!props.restrictionRemboursementOther.go && refundMode=='other')) ?
                    <div style={{marginTop:'85px'}}>
                        <ControlAccess 
                            section={props.section} 
                            focus={true}
                            restriction={(refundMode=='espece') ? props.restrictionRemboursementEsp : props.restrictionRemboursementOther} 
                            setRestriction={(refundMode=='espece') ? props.setRestrictionRemboursementEsp : props.setRestrictionRemboursementOther} 
                            moduleId={props.posid} 
                            callback={callback}
                        />
                    </div>
                :
                    (refundMode=='giftcard') ?
                        <RefundGiftCard
                            total={(Number.isInteger(+props.total)) ? props.total : props.total.toFixed(props.digit)}
                            cookies={props.cookies}
                            currentTicket={props.currentTicket}
                            clientId={props.clientId}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            errorPay={errorPay}
                            setErrorPay={setErrorPay}
                            load={load}
                            setLoad={setLoad}
                            refundApi={refundApi}
                        />
                    :
                        (refundMode=='espece') ?
                            <RefundEspece
                                total={(Number.isInteger(+props.total)) ? props.total : props.total.toFixed(props.digit)}
                                cookies={props.cookies}
                                load={load}
                                setLoad={setLoad}
                                refundApi={refundApi}
                            />
                        :
                            (refundMode=='other') ?
                                <RefundOther
                                    total={(Number.isInteger(+props.total)) ? props.total : props.total.toFixed(props.digit)}
                                    cookies={props.cookies}
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                    errorPay={errorPay}
                                    setErrorPay={setErrorPay}
                                    load={load}
                                    setLoad={setLoad}
                                    refundApi={refundApi}
                                />
                            :
                                false
                }
            </>
        }
    </>
    )
}

export default RefundForm;