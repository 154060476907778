import React, { useState,useRef } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { BlobProvider, Page, View, Document} from '@react-pdf/renderer';
import ItemTagsBarCode from "app/views/Pdf/ItemTagsBarCode"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const IconBtn = (props) =>{
    const t =useTranslate()

    return(
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    {t('generateABarCodePdf')}
                </Tooltip>
            }
        >
            <div onClick={()=>props.handleClick()}>
                <FontAwesomeIcon className="text-dark" icon={faBarcode}/>
            </div>
        </OverlayTrigger>  
    )    
}

const GenerateBarCodeBtnA4 = (props) =>{
    const t = useTranslate();
    const [itemsWithBarCode,setItemsWithBarCode]=useState(false) 
    const btnPdf = useRef(null);

    //Ici on crée un array avec tous les index possible avec lesquels une ligne de code barre peut commencer, car sur chaque lignes, on retourne 4 codes barres 
    //L'array se termine à lindex 48 car sur un datatable on ne retourne au maximum 50 lignes par appels 
    let indexAffiche = [0,4,8,12,16,20,24,28,32,36,40,44,48]

    const handleClick = () => {
        let arr = []

        props.articles.map(value=>{
            if(value.reference!=null && value.reference!='') arr.push(value)
        })

        setItemsWithBarCode(arr)

        setTimeout(() => {
            //on génère le pdf
            if(btnPdf) btnPdf.current.click()
        }, 1000);
        setTimeout(() => {//on réinitialise la liste d'article pour éviter que le générateur de pdf re rend les pdf pour éviter les ralentissements 
            setItemsWithBarCode(false)
        }, 1500);
    };

    return (
        <>
            {props.icon == 'false' ?
                <button className='btn btn-sm btn-success' onClick={()=>handleClick()} >{t('generateMasseBarCodePdf')}</button>
            :
                <IconBtn handleClick={handleClick}/>
            }
            {itemsWithBarCode ?
                <BlobProvider document={
                    <Document>
                        <Page size="A4">
                                {itemsWithBarCode.length!=0 && itemsWithBarCode.map((value,ind)=>{
                                    if(indexAffiche.includes(ind)){
                                        return( 
                                            <View key={value.id} style={{display:'flex', flexDirection:'row', justifyContent:'center', marginBottom: (ind==32) ? 100 : '' }}>
                                                <ItemTagsBarCode articleBarCode={value.reference} articleName={(value.name.length>30) ? value.name.substring(0,27)+'...' : value.name} articlePrice={value.price+' '+props.devise} />

                                                {(itemsWithBarCode[ind+1]) && <ItemTagsBarCode articleBarCode={itemsWithBarCode[ind+1].reference} articleName={(itemsWithBarCode[ind+1].name.length>30) ? itemsWithBarCode[ind+1].name.substring(0,27)+'...' : itemsWithBarCode[ind+1].name} articlePrice={itemsWithBarCode[ind+1].price+' '+props.devise} />}

                                                {(itemsWithBarCode[ind+2]) && <ItemTagsBarCode articleBarCode={itemsWithBarCode[ind+2].reference} articleName={(itemsWithBarCode[ind+2].name.length>30) ? itemsWithBarCode[ind+2].name.substring(0,27)+'...' : itemsWithBarCode[ind+2].name} articlePrice={itemsWithBarCode[ind+2].price+' '+props.devise} />}

                                                {(itemsWithBarCode[ind+3]) && <ItemTagsBarCode articleBarCode={itemsWithBarCode[ind+3].reference} articleName={(itemsWithBarCode[ind+3].name.length>30) ? itemsWithBarCode[ind+3].name.substring(0,27)+'...' : itemsWithBarCode[ind+3].name} articlePrice={itemsWithBarCode[ind+3].price+' '+props.devise} />}
                                            </View>
                                        ) 
                                    }
                                })}
                        </Page>
                    </Document>
                }>

                    {({ url }) => (
                        <a ref={btnPdf} className="d-none" href={url} target="_blank">Print</a>
                    )}
                </BlobProvider>
            :
                false
            }
        </>
    )
};

export default GenerateBarCodeBtnA4;