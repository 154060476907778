import React, { useState, useRef, useContext } from "react";
import { IsAuthContext } from 'app/App';
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso,ImportForm } from "@gull";
import axios from 'axios'

const OneDay = (props) => {
    const t = useTranslate()
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        setSuccess(false)
        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/pos/objectif/"+props.sectionid,data).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
               reset()
               setSuccess(t('successOperation'))
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
    }

    return(
        <CardPerso header={t('createOneDayGoals')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-md-6">
                    <label htmlFor="day">{t('day')}</label>
                    <input className="form-control" type="date"{...register('day',{required: true})}/>
                    {errors.day?.type === 'required' && (
                    <div className="text-danger col-12 mt-1 ml-2">
                        {t('dayRequired')}
                    </div>
                    )}
                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="ca">CA TTC:</label>
                        <div className="input-group">
                            <input className="form-control" type="number" defaultValue={0} {...register('cattc',{required: true,min: 0,max: 2000000000})}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    {props.devise}
                                </span>
                            </div>
                            {errors.ca?.type === 'required' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('caRequired')}
                            </div>
                            )}
                            {errors.ca?.type === 'min' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('caMin0')}
                            </div>
                            )}
                            {errors.ca?.type === 'max' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('caMax2000000000')}
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="ca">CA HT:</label>
                        <div className="input-group">
                            <input className="form-control" type="number" defaultValue={0} {...register('ca',{required: true,min: 0,max: 2000000000})}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    {props.devise}
                                </span>
                            </div>
                            {errors.ca?.type === 'required' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('caRequired')}
                            </div>
                            )}
                            {errors.ca?.type === 'min' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('caMin0')}
                            </div>
                            )}
                            {errors.ca?.type === 'max' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('caMax2000000000')}
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="iv">IV:</label>
                        <div className="input-group">
                            <input className="form-control" step="any" type="number" defaultValue={0} {...register('iv',{required: true,min: 0,max: 999})}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    {t('items')}
                                </span>
                            </div>
                            {errors.iv?.type === 'required' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('ivRequired')}
                            </div>
                            )}
                            {errors.iv?.type === 'min' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('ivMin0')}
                            </div>
                            )}
                            {errors.iv?.type === 'max' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('ivMax999')}
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="pm">PM TTC:</label>
                        <div className="input-group">
                            <input className="form-control" type="number" defaultValue={0} {...register('pmttc',{required: true,min: 0,max: 2000000000})}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    {props.devise}
                                </span>
                            </div>
                            {errors.pm?.type === 'required' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('pmRequired')}
                            </div>
                            )}
                            {errors.pm?.type === 'min' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('pmMin0')}
                            </div>
                            )}
                            {errors.pm?.type === 'max' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('pmMax2000000000')}
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="pm">PM HT:</label>
                        <div className="input-group">
                            <input className="form-control" type="number" defaultValue={0} {...register('pm',{required: true,min: 0,max: 2000000000})}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    {props.devise}
                                </span>
                            </div>
                            {errors.pm?.type === 'required' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('pmRequired')}
                            </div>
                            )}
                            {errors.pm?.type === 'min' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('pmMin0')}
                            </div>
                            )}
                            {errors.pm?.type === 'max' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('pmMax2000000000')}
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="tf">TF:</label>
                        <div className="input-group">
                            <input className="form-control" type="number" defaultValue={0} {...register('tf',{required: true,min: 0,max: 100})}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    %
                                </span>
                            </div>
                            {errors.tf?.type === 'required' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('tfRequired')}
                            </div>
                            )}
                            {errors.tf?.type === 'min' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('tfMin0')}
                            </div>
                            )}
                            {errors.tf?.type === 'max' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('tfMax100')}
                            </div>
                            )}
                        </div>
                    </div>
                </div>
                {(success) ? <div className="alert alert-success mt-3" dangerouslySetInnerHTML={{ __html: success }}></div> : false}
                {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                <button disabled={validButton} className="btn btn-primary mt-3">
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </form>
        </CardPerso>
    )
}

const ObjectifCreate = (props) => {
    const t = useTranslate()
    const {authParam} = useContext(IsAuthContext)
    const moduleErp = authParam.modules.find(value=>value.name=='erp')
    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;
    const [csvData,setCsvData] = useState([])
    const selectMatch = useRef(new Array())
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)
    let optionMatchCsv = [
        'Date*',
        'CA TTC*',
        'CA HT*',
        'PM TTC*',
        'PM HT*',
        'IV*',
        'TF*'
    ]

    const goImport = async () => {
        setValidButton(true)
        setApiError(false)
        setSuccess(false)
        let matchColonne = {}
        optionMatchCsv.forEach((optm,index)=>{
            matchColonne[optm.replace('*','')]=(selectMatch.current[index].value!="no") ? selectMatch.current[index].value : null
        })
        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/pos/objectif/csvimport/"+props.sectionid,{
            match: matchColonne,
            data: csvData,
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'<br>')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                setSuccess({
                    colorAlert: (response.data.totalLines==response.data.totalImport) ? 'success' : 'warning',
                    message:t('importedRows')+response.data.totalImport+' / '+response.data.totalLines
                })
                if(response.data.errors.length!=0){
                    let messageError='';
                    var clefs = Object.keys(response.data.errors);
                    for(var j = 0; j < clefs.length; j++){
                        let interms='';
                        var keys = Object.keys(response.data.errors[clefs[j]]);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[clefs[j]][keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        interms.forEach(interm=>{
                            if(interm!=''){
                                messageError+='<strong>'+clefs[j].replace('#',t('row'))+'</strong>: '+t(interm)+'<br>'
                            }
                        })
                    }
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
    }

    return (<>
        <OneDay {...props} devise={devise} />
        {(moduleErp.params.import.active) &&
            <CardPerso header={t('importItem')}>
                <ImportForm 
                    goImport={goImport}
                    optionMatchCsv={optionMatchCsv}
                    selectMatch={selectMatch}
                    validButton={validButton}
                    apiError={apiError}
                    success={success}
                    setApiError={setApiError}
                    setSuccess={setSuccess}
                    setCsvData={setCsvData}
                    modele={//Passer false si aucun modle à télécharger
                        {
                            fileName:t('template')+' '+t('objectifs'),
                            data:[
                                {
                                    "Date": "2021-10-19",
                                    "CA TTC": "200000",
                                    "CA HT": "180000",
                                    "PM TTC": "8500",
                                    "PM HT": "7650",
                                    "IV": "2.6",
                                    "TF": "25"
                                }
                            ]
                        }
                    }
                />
            </CardPerso>
        }
    </>)
}

export default ObjectifCreate;
