import React, { useState,useEffect,useContext } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {ColumDataTableReorganize,ProfilActionButton,FormatedPrice} from '@gull'
import axios from 'axios';
import swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useCookies} from 'react-cookie';
import {scrollXDataTable} from 'app/scrollXDataTable';
import Moment from 'react-moment';
import { IsAuthContext } from 'app/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt} from '@fortawesome/free-solid-svg-icons'
import {useHistory} from 'react-router-dom'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const useLoad = (folderParam) =>{
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [savedDocuments,setSavedDocuments]=useState([]) 

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/saved_facture/get_all_in_company"+folderParam
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");

                }else{  
                    setSavedDocuments(response.data.datas)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
            setLoading(false)
        })()
    }, [])

    return [loading,savedDocuments]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            defaultSortDirection={props.defaultSortDirection}
            hover
            condensed
            responsive
        />
    );
};

const BtnFinalizeDoc = (props) => {
    const t = useTranslate();
    const history = useHistory();
    const [validButton,setValidButton]=useState(false) 

    const getSavedDoc = async () => {
        setValidButton(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/saved_facture/retrieve/"+props.value.id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                history.push('/invoice/document/create-add-items/'+props.value.facture_id)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
            
        })
        setValidButton(false)
    };

    return(<>
        {validButton ? 
            <div className="spinner-border spinner-border-sm"></div> 
        : 
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('finalize')}
                    </Tooltip>
                }
            >
                <div><FontAwesomeIcon className="text-primary pointer" icon={faExchangeAlt} onClick={()=>getSavedDoc()}/></div>
            </OverlayTrigger> 
        }
    </>)
};

const ModalSavedFacture = (props) =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [loading,savedDocuments]=useLoad(props.folderParam)

    const [perPage,setPerPage]=useState(50)
    const [cookies, setCookie] = useCookies(['saved_facture_list_table_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    //Pour le filtre sur le type de doc
    const [selectedDocType,setSelectedDocType]=useState('') 
    const [allSavedDocsList,setAllSavedDocsList]=useState([]) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    useEffect(function () {
        if(selectedDocType!=''){
            let filteredList = savedDocuments.filter(value=>value.facture.type==selectedDocType)
            setAllSavedDocsList(filteredList)
        }else{
            setAllSavedDocsList(savedDocuments)
        }
    }, [selectedDocType,savedDocuments])

    
    
    let dataTable = new Array
    allSavedDocsList.map((value,ind)=>{
        let obj = {
            type: t(value.facture.type),
            createdAt: <Moment format="DD/MM/YYYY">{value.created_at}</Moment>,
            client: value.facture.client_infos.name+' '+value.facture.client_infos.firstname,
            amount: <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={value.facture.ttc} />,
            id: value.id,
            action: 
                <span className="d-flex justify-content-between">
                    <BtnFinalizeDoc value={value}/>
                </span>,
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "action",
            text: "Actions",
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
        },
        {
            dataField: "type",
            text: t('documentType'),
            editable:false,
            sort:true,
            headerSortingStyle
        },
        {
            dataField: "createdAt",
            text: t('createdAt'),
            editable:false,
            sort:true,
            headerSortingStyle
        },
        {
            dataField: "client",
            text: t('customer'),
            editable:false,
            sort:true,
            headerSortingStyle
        },
        {
            dataField: "amount",
            text: t('amountTtc'),
            editable:false,
            sort:true,
            headerSortingStyle
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.saved_facture_list_table_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.saved_facture_list_table_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie('saved_facture_list_table_reorganize',arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: dataTable.length,
    };

    if(loading){
        return(<div className='d-flex justify-content-center'><div className="spinner-border spinner-border-sm"></div></div>)
    }

    return (
        <div>
            {/* <CardPerso header={t('roughDraftList')}> */}
                {columnOrganize.length!=0 &&
                    <small>
                        <ToolkitProvider
                            keyField="index"
                            data={dataTable}
                            columns={columnOrganize}
                            striped
                        >
                        {(propst) => (
                            <>
                                <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <ColumDataTableReorganize 
                                            columnOrganize={columnOrganize}
                                            setColumnOrganize={setColumnOrganize}
                                            setCookie={setCookie}
                                            cookieName='saved_facture_list_table_reorganize'
                                            showColumnOrganize={showColumnOrganize}
                                            setShowColumnOrganize={setShowColumnOrganize}
                                            btnSwitch={false}//pour affiche ou non le btn swith
                                            initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                                        />
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className="form-group">
                                        <label>{t('filterTheSelection')}</label>
                                        <select className='form-control' onChange={(e)=>setSelectedDocType(e.target.value)}>
                                            <option value="">{t('allDocuments')}</option>
                                            <option value="billing">{t('billing')}</option>
                                            <option value="quotation">{t('quotation')}</option>
                                            <option value="creditNote">{t('creditNote')}</option>
                                            <option value="billingBuy">{t('billingBuy')}</option>
                                            <option value="purchaseOrderB2b">{t('purchaseOrderB2b')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <CustomDataTable 
                                        propst={propst}
                                        paginationOptions={paginationOptions}
                                        columns={columns}
                                        defaultSortDirection="asc"
                                    />
                                </div>
                            </>
                        )}
                        </ToolkitProvider>
                    </small>
                }
            {/* </CardPerso> */}
        </div>
    )
};

export default ModalSavedFacture;




