import React, {useState,useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso,ColumDataTableReorganize} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from 'react-moment';
import { IsAuthContext} from 'app/App';
import { espace_mille } from 'app/espace_mille';
import { modePaiementMustBeTranslate } from 'app/modePaiementDefaultAll';
import { useCookies} from 'react-cookie';
import {scrollXDataTable} from 'app/scrollXDataTable';
import {Link} from 'react-router-dom'

const useSearchClients = (clientId,tabKey) => {
    const t =useTranslate()
    const [allSalesOrder,setAllSalesOrder]=useState([])
    const [sizeDatas,setSizeDatas]=useState(0)
    const [load,setLoad]=useState({
        allTicket:false,
    })

    useEffect(function () {
        (async function() {
            if(tabKey=='salesOrder'){
                setLoad({...load,allTicket:true})
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_histo_commande/"+clientId+'/1/50'
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{   
                        setAllSalesOrder(response.data.datas)
                        setSizeDatas(response.data.count)
                        setLoad({...load,allTicket:false})
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoad({...load,allTicket:false})
            }
        })()
    }, [clientId,tabKey])

    return [load,allSalesOrder,setAllSalesOrder,sizeDatas,setSizeDatas]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            pagination={ paginationFactory(props.paginationOptions) }
            onTableChange={ props.handleTableChange }
            noDataIndication={props.load.allTicket ? <div className="spinner-border spinner-border-sm"></div> : t('tableNoData')}
            defaultSorted={props.defaultSorted}
            rowStyle={ props.rowStyle }
            hover
            condensed
            responsive
        />
    );
};  

const HistoCommandeClient = (props) => {
    const t =useTranslate()
    const [load,allSalesOrder,setAllSalesOrder,sizeDatas,setSizeDatas]=useSearchClients(props.clientId,props.tabKey)
    const [perPage,setPerPage] = useState(50)
    const [currentPage,setCurrentPage] = useState(1)
    const { authParam } = useContext(IsAuthContext)

    const [cookies, setCookie] = useCookies(['histo_commande_client_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: sizeDatas
    };

    let dataTable = new Array
    allSalesOrder.length!=0 && allSalesOrder.map((value,ind)=>{
        let modeList =''
        value.ticket.paiement_records.length!=0 && value.ticket.paiement_records.map((item,index)=>{
            let modeName = modePaiementMustBeTranslate.includes(item.mode) ? t(item.mode) : item.mode
            if(index==0){
                modeList += modeName+': '+espace_mille(item.montant,digit)+devise
            }else{
                modeList += ' / '+modeName+': '+espace_mille(item.montant,digit)+devise
            }
            
        })
        let obj = {
            ticket: value.bar,
            client: (value.ticket.client!=null) ? value.ticket.client.name+' '+value.ticket.client.firstname : '',
            originShop:value.ticket.section.name,
            collectZone: (value.section_collect!=null  ) ? value.section_collect.name : '',
            dateCreation: <Moment format="DD/MM/YYYY HH:mm">{value.ticket.created_at}</Moment>,
            dateCollect: (value.prevu!=null) ? <Moment format="DD/MM/YYYY HH:mm">{value.prevu}</Moment> : '',
            id: value.id,
            cancel:value.cancel,
            status:value.ticket.status,
            statut: (value.ticket.status>0) ? t('finalized') : (value.cancel>0) ? t('canceled') : t('enCours'),
            mode_pay: modeList,
            action: 
                <span className="d-flex justify-content-between align-items-center mt-1" style={{marginLeft:'0.2em'}}>
                    <Link className="btn btn-light btn-sm" to={'/erp/caisse/sales-order/'+value.id}>{t('detail')}</Link>
                </span>,
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    let defaultSorted = [
        {
          dataField: "date",
          order: "desc"
        }
    ];

    let columns = [
        {
            dataField: "action",
            text: 'Action',
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
            // style: (cell, row, rowIndex, colIndex) => {
            //     return {
            //         padding:'0',
            //     };
            // },
        },
        {
            dataField: "ticket",
            text: t('salesOrder')+' n°',
            editable: false,
        },
        {
            dataField: "client",
            text: t('customer'),
            editable: false,
        },
        {
            dataField: "originShop",
            text: t('origin_section'),
            editable: false,
        },
        {
            dataField: "collectZone",
            text: t('collectZone'),
            editable: false,
        },
        {
            dataField: "dateCreation",
            text: t('createdAt'),
            editable: false,
        },
        {
            dataField: "dateCollect",
            text: t('expectedCollectDate'),
            editable: false,
        },
        {
            dataField: "mode_pay",
            text: t('paymentDetail'),
            editable: false,
        },
        {
            dataField: "statut",
            text: t('status'),
            editable: false,
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.histo_commande_client_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.histo_commande_client_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("histo_commande_client_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const handleTableChange = async (type, { page, sizePerPage }) => {
        setPerPage(sizePerPage)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_histo_commande/"+props.clientId+'/'+page+"/"+sizePerPage,
        ).then((response) => {
            setAllSalesOrder(response.data.datas)
            setSizeDatas(response.data.count)
            setCurrentPage(page)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const rowStyle = (row, rowIndex) => {
        const style = {};

        if(rowIndex!=0){
            if (row.cancel != 0) {
            style.backgroundColor = '#fccfcf';//annulé

            }else if(row.status != 0){
            style.backgroundColor = '#d9fcd2';//finalisé

            }else{
                style.backgroundColor = '#ffffbb';//en cours
            }
        }
        return style;
    };

    return(<>
        <CardPerso saClass='position-relative'>
            <div className="mb-2">
                <ColumDataTableReorganize 
                    columnOrganize={columnOrganize}
                    setColumnOrganize={setColumnOrganize}
                    setCookie={setCookie}
                    cookieName='histo_commande_client_reorganize'
                    showColumnOrganize={showColumnOrganize}
                    setShowColumnOrganize={setShowColumnOrganize}
                    btnSwitch={false}//pour affiche ou non le btn swith
                    initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                />
            </div>
            {(load.allTicket) ? 
                <div className="d-flex justify-content-center"><span className="spinner-border spinner-border-sm"></span></div> 
            :
                columnOrganize.length!=0 && 
                    <small>
                        <ToolkitProvider
                            keyField="index"
                            data={dataTable}
                            columns={ columnOrganize }
                        >
                            {(propst) => (
                                <div className="position-relative">
                                    <CustomDataTable
                                        propst={propst}
                                        paginationOptions={paginationOptions}
                                        handleTableChange={handleTableChange}
                                        load={load}
                                        defaultSorted={defaultSorted}
                                        rowStyle={rowStyle}
                                        columns={columns}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </small>
            }
        </CardPerso>
        </>
    )
    
}

export default HistoCommandeClient;