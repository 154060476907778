import React,{useState,useEffect,useRef,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {CreateNotification} from '@gull'
import axios from "axios";
import TicketCommande from "../../../../../app/views/Pdf/TicketCommande"
import { BlobProvider } from '@react-pdf/renderer';
import { conditionsRetour } from 'app/conditionsRetour';
import { epson_tm_h6000VOrder } from 'app/imprime';
import { PeripheriqueContext,IsAuthContext } from '../../../../../app/App';
import swal from "sweetalert2";
import { useCookies} from 'react-cookie';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import SaleOrderDataForm from './OrderFormNextComp/SaleOrderDataForm'
import TransferOrderTransform from './OrderFormNextComp/TransferOrderTransform'

const useLoad = () => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [customfields,setCustomFields]=useState(false)
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/all/commande"
            ).then((response) => {
                setLoading(false)
                setCustomFields(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])
    return [loading,customfields]
}

const OrderFormNext = (props) =>{
    const t =useTranslate()
    const [loading,customfields]=useLoad()
    const [cookies ,setCookie, removeCookie] = useCookies(['current_ticket']);
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [dataSaleOrder,setDataSaleOrder]=useState({})
    const [load,setLoad]=useState({
        pdf:false,
        print:false,
        alert:false,
        alertOffice:false,
        finalize:false,
        cancel:false,
        espece:false,
        gift_card:false,
        autre:false,
        editCustomFields:false,
        editDate:false,
        editNote:false,
        shop:false

    })
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    const {authParam} = useContext(IsAuthContext);

    const [totalAchat,setTotalAchat]=useState(0)
    const [totalTgc,setTotalTgc]=useState(false)
    const [showBtnRefund,setShowBtnRefund]=useState(false)
    const [edit,setEdit]=useState(false)
    const [showToTransform,setShowToTransform]=useState(false)
    const [showAlertForm,setShowAlertForm]=useState(false)
    const [messageErrorDetailItem,setMessageErrorDetailItem]=useState(false) 

    const btnPdf = useRef('')
    const btnDetailArticle = useRef('')
    const refundGiftcard = useRef('')
    const refundOther = useRef('')

    const [optionRefund,setOptionRefund]=useState(false)
    const [valueInput,setValueInput]=useState({
        gift_card:'',
        autre:'',
        note:'',
        time:'',
        date:''
    })
    const history = useHistory();
    const { register, formState: { errors }, handleSubmit, setValue, reset} = useForm();

     //Pour le cookie
     let current_date = new Date()
     let timestampdate = current_date.setDate(current_date.getDate() + 90)
     let expire_date = new Date(timestampdate)

    useEffect(function () {
        setDataSaleOrder(props.ticketCommande)

        if(optionRefund=='gift_card'){
            if(refundGiftcard.current){
                refundGiftcard.current.focus()
            }
        }else if(optionRefund=='autre'){
            if(refundOther.current){
                refundOther.current.focus()
            }
        }

        var total = 0
        let totaltgc = {}
        props.ticketCommande.ticket.ticket_detail.map(item=>{
            total+=item.prix_remise
            if(item.tgc!=0){//on exécute pas cette action sur le taux 0
                if(totaltgc[item.tgc]){
                    //si le taux de tgc existe deja on ajoute le pris du produit au total existant
                    totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
                }else{
                    //Si aucun taux existe on ajoute la taxe 
                    totaltgc={...totaltgc,[item.tgc]:item.prix_remise}
                }
                
            }
        })
        setTotalAchat(total)
        setTotalTgc(totaltgc)

        //on met à jour les infos date, heure et note
        let dateprevu = (props.ticketCommande.prevu!=null) ? props.ticketCommande.prevu.split(' ') : false
        setValueInput({
            note:(props.ticketCommande.note!=null) ? props.ticketCommande.note : '',
            time:(dateprevu) ? dateprevu[1] : '',
            date:(dateprevu) ? dateprevu[0] : ''
        })


        Object.entries(props.ticketCommande.custom_fields).map(([key,value])=>{//on remplie les champs des customs fields
            setValue(key,value)
        })
    }, [optionRefund])

    let tgcForTicketPdf={}
    Object.entries(totalTgc).forEach(([tgcName,montant])=>{
        //fonction qui calcule le montant de tgc à payer par type de tgc
        let coef = +tgcName / 100 + 1
        let totalAmountTgc = +montant - (Math.round(+montant / +coef))
        tgcForTicketPdf[tgcName] = totalAmountTgc
    })
    //on calcule le total de tgc payé sur le ticket
    let tgcTotal = 0 
    Object.entries(tgcForTicketPdf).map(([key,value])=> 
        tgcTotal+=value
    )

    let thisShop = authParam.sections.length!=0 && authParam.sections.find(value=>value.id==props.ticketCommande.ticket.section_id)

    const cancelSaleOrder = async (e,module,remboursementMode) =>{
        if(e) e.preventDefault()
        setSuccess(false)
        setApiError(false)
        setLoad({...load,[module]:true}) 

        let obj ={
            remboursement_accompte:remboursementMode, 
        }

        if(remboursementMode == 'gift_card'){
            obj['gift_card_reference']=valueInput.gift_card

        }else if(remboursementMode == 'autre'){
            obj['note']=valueInput.autre
        }
        
        const test = await axios.put(process.env.REACT_APP_API_URL+"api/pos/commande/cancel/"+props.ticketCommande.id+'/'+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(t(messageError));
            }else{  
                setLoad({...load,[module]:false})
                setSuccess(t('successOperation'))
                if(props.from=='histoClient'){
                    let salesOrder = props.allCommande.map(value=>value.id)
                    let indexToEdit = salesOrder.indexOf(props.ticketCommande.id)
                    let newData = props.allCommande.filter(value=>value.id!=props.ticketCommande.id)

                    newData.splice(indexToEdit,0, response.data.data)

                    props.setAllCommande(newData)
                }
                if(props.cookies && props.cookies.current_ticket && props.cookies.current_ticket == props.ticketCommande.ticket.id){
                    props.resetSale('salesOrderApi')
                    props.removeCookie('current_ticket',{ path: "/"})
                }
                if(props.actualShop && props.actualShop.interface=='restauration' && props.allCommandes && props.setAllCommandes){
                    //Dans le cas d'une interface de restauration on retire la la commande de la liste de l'écran principal
                    let newData = props.allCommandes.filter(value=>value.id!=props.ticketCommande.id)
                    props.setAllCommandes(newData)
                }

                setDataSaleOrder(response.data.data)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            setApiError(t('unknowError'))
        })
        setLoad({...load,[module]:false})
    }

    const onSubmit = async (data,name,sectionCollectId) =>{
        var now = new Date();
        var annee = now.getFullYear();
        var mois = now.getMonth() +1;
        var jour = now.getDate();

        var moisFormat = (mois>9) ? mois : '0'+mois;
        var jourFormat = (jour>9) ? jour : '0'+jour;

        let formatedTime = valueInput.time.split(':')//on reformate l'heure pour envoyer un format HH:mm à la place de HH:mm:ss

        let obj = {}

        if(name=='editDate'){
            if(valueInput.date=='' && valueInput.time!=''){
                obj['date']=annee+'-'+moisFormat+'-'+jourFormat
                obj['heure']=formatedTime[0]+':'+formatedTime[1]
            }else{
                if(valueInput.time==''){
                    obj['heure']=null
                }else{
                    obj['heure']=formatedTime[0]+':'+formatedTime[1]
                }
    
                if(valueInput.date==''){
                    obj['date']=null
                }else{
                    obj['date']=valueInput.date
                }
            }
            setLoad({...load,editDate:true})
        }else if(name=='editNote'){
            obj['note']=valueInput.note
            setLoad({...load,editNote:true})
        }else if(name=='shop'){
            obj['section_collect']=sectionCollectId
            setLoad({...load,shop:true})
        }else{
            (data) && Object.entries(data).map(([key,value])=>{
                obj[key]=value
            })
            setLoad({...load,editCustomFields:true})
        }

        const response = await axios.put(process.env.REACT_APP_API_URL+"api/pos/commande/edit/"+props.ticketCommande.id+"/"+props.ticketCommande.ticket.section_id, obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{   
                if(props.from=='histoClient'){
                    //mise à jours des données du datatable 
                    let salesOrder = props.allCommande.map(value=>value.id)
                    let indexToEdit = salesOrder.indexOf(props.ticketCommande.id)

                    let newData = props.allCommande.filter(value=>value.id!=props.ticketCommande.id)

                    newData.splice(indexToEdit,0, response.data.data)

                    props.setAllCommande(newData)
                    setDataSaleOrder(response.data.data)
                }else{
                    setDataSaleOrder(response.data.data)
                }
                setLoad({
                    ...load,
                    editCustomFields:false,
                    editDate:false,
                    editNote:false,
                    shop:false
                })
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
        setLoad({
            ...load,
            editCustomFields:false,
            editDate:false,
            editNote:false,
            shop:false
        })
    }

    const handleClick = (name) =>{
        switch (name) {
            case 'print':
                if(Object.keys(dataSaleOrder).length!=0) epson_tm_h6000VOrder('print',props.ticketCommande.ticket.section_id,dataSaleOrder,t,props.devise,peripheriqueParam,false,authParam)
            break;
            case 'pdf':
                setLoad({...load,pdf:true})
                setTimeout(()=>{
                    btnPdf.current.click()
                    setLoad({...load,pdf:false})
                },1000)
            break;
            case 'to_Transform':
                setShowToTransform(true)
                props.setModalTitle(t('transferOrder'))
            break;
            case 'finalize':
                //on récupère uniquemenet mes articles qui ne sont pas des services
                let realItem = dataSaleOrder.ticket.ticket_detail.filter(value=>value.article.havestock)

                //on vérifie si tous les articles ont été collectés 
                let itemNotCollected = realItem.find(value=>{
                    if(value.reste){
                        return value

                    }else if(value.have_to_collect){
                        return value

                    }else if(value.en_to){
                        return value

                    }
                })

                if(itemNotCollected && !messageErrorDetailItem){//Si certains articles de la commande n'ont pas été collecté 
                    if(btnDetailArticle.current) btnDetailArticle.current.click()
                    setMessageErrorDetailItem(true)

                }else{//Si tous les articles ont été collecté 
                    if(props.from=='histoClient' || props.from=='toList'){
                        //Quand on réouvre un bon de commande client depuis l'historique de commande client du POS
                        const response = axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
                            id:props.ticketCommande.ticket.id,
                            delete_block_quantity:true//param utilisé pour savoir le ticket en cours vient d'une commande client qui est en cours de finalisation 
                        }).then((response) => {
                            if(!response.data.valid){
                                removeCookie('current_ticket',{ path: "/"})
                            }else{   
                                setCookie("current_ticket",response.data.data.id,{ path: "/", expires: expire_date })
                                history.push('/pos/'+props.posid);
                            }
                        }).catch((error) => {
                            if(error=='end_session') return;
                            let messageError
                            if(error.response==undefined){
                                messageError='unknowError'
                            }else if(error.response.status==403){
                                messageError='forbiddenGeneral'
                            }else {
                                messageError='unknowError'
                            }
                            swal.fire(t(messageError), "", "error");
                        })
                    }else{
                        props.setIsSalesOrder(props.ticketCommande)//on passe le contenu de la commande à isSalesOrder pour permettre de vérifier si la commande qu'on souhaite finalier à un caisser de renseigné ou pas
                        props.setCookie("current_ticket",props.ticketCommande.ticket.id,{ path: "/", expires: props.expire_date }) 

                        if(props.actualShop && props.actualShop.interface=='restauration' && props.allCommandes && props.setAllCommandes){
                            //Dans le cas d'une interface restauraiton
                            let newData = props.allCommandes.filter(value=>value.id!=props.ticketCommande.id)
                            props.setAllCommandes(newData)
                        }
                        props.closeModal()
                    }
                    setMessageErrorDetailItem(false)
                }

            break;
            case 'cancel':
                swal
                .fire({
                    title: t('confirmCancelSaleOrder')+" ?",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: t('btnYes'),
                    cancelButtonText: t('btnNo'),
                })
                .then((result) => {
                    if (result.value) {
                        if(props.ticketCommande.ticket.paiement_records.length!=0){
                            setShowBtnRefund(true)
                        }else{
                            //on passe le parametre espece en cas de remboursement espece ou si il n'y a pas eu d'acompte versé
                            cancelSaleOrder(false,name,'espece')
                        }
                    }
                });
            break;
            case 'espece':
                cancelSaleOrder(false,name,'espece')
            break;
            case 'gift_card':
                setOptionRefund('gift_card')//on affiche le scan de la gift card
            break;
            case 'autre':
                setOptionRefund('autre')//on affiche un textarea
            break;
            case 'edit':
                setEdit(true)
            break;
            case 'alertOffice':
                setShowAlertForm(true)
                props.setModalTitle(t('createNotification'))
            break;
        }
    }

    const handleChange = (e,name) =>{
        setValueInput({...valueInput,[name]:e.target.value})
    }

    const handleEdit = (name,e) =>{
        switch (name) {
            case 'shop':
                onSubmit(false,name,e.target.value)
            break;
            case 'editDate':
                onSubmit(false,name,false)
            break;
            case 'editNote':
                onSubmit(false,name,false)
            break;
        }
    }
    
    return(<>
        {(Object.keys(dataSaleOrder).length!=0 && tgcTotal!=NaN) ?
            <BlobProvider 
                document={
                    <TicketCommande
                        ticketPdf={dataSaleOrder}
                        thisShop={thisShop}
                        t={t}
                        devise={props.devise}
                        digit={props.digit}
                        totalAchat={totalAchat}
                        tgcForTicketPdf={tgcForTicketPdf}
                        tgcTotal={tgcTotal}
                        authParam={authParam}
                    />
                
                }
            >
                {({ url }) => (
                    <a ref={btnPdf} className="d-none" href={url} target="_blank">Print</a>
                )}
            </BlobProvider>
        : 
            ''
        }
        {(Object.keys(dataSaleOrder).length!=0) ?
            (showToTransform) ? 
                <TransferOrderTransform 
                    dataSaleOrder={dataSaleOrder}
                    setShowToTransform={setShowToTransform}
                    setShow={props.setShow}
                    setModalTitle={props.setModalTitle}
                    setGetNewData={props.setGetNewData}
                />
            :
                (showAlertForm) ?
                    <div>  
                        <div className="col">
                            <button className="btn btn-sm btn-danger mb-2" onClick={()=>setShowAlertForm(false)}>
                                {t('btnBack')}
                            </button>
                        </div>
                        <CreateNotification authParam={authParam} setShowModal={setShowAlertForm} from='alertSaleOrder'/>
                    </div>
                :
                    <SaleOrderDataForm
                        ticketCommande={props.ticketCommande}
                        dataSaleOrder={dataSaleOrder}
                        setDataSaleOrder={setDataSaleOrder}
                        handleEdit={handleEdit}
                        load={load}
                        valueInput={valueInput}
                        handleChange={handleChange}
                        onSubmit={onSubmit}
                        loading={loading}
                        customfields={customfields}
                        handleClick={handleClick}
                        optionRefund={optionRefund}
                        cancelSaleOrder={cancelSaleOrder}
                        refundGiftcard={refundGiftcard}
                        refundOther={refundOther}
                        edit={edit}
                        setEdit={setEdit}
                        devise={props.devise}
                        digit={props.digit}
                        posid={props.posid}
                        apiError={apiError}
                        success={success}
                        showBtnRefund={showBtnRefund}
                        register={register}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        from={props.from}
                        btnDetailArticle={btnDetailArticle}
                        messageErrorDetailItem={messageErrorDetailItem}
                        setMessageErrorDetailItem={setMessageErrorDetailItem}
                        allCommandes={props.allCommandes} 
                        setAllCommandes={props.setAllCommandes}
                    />
        :
            false
        }
    </>
    )
}

export default OrderFormNext;