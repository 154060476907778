import React, {useState,useEffect} from "react";
import {FiltreSearchBar} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import ControlInventoryQuantityAdmin from './ControlInventoryQuantityAdmin'

const ControlInventoryCountAdmin = (props) => {
    const t = useTranslate()
    const [goodCount,setGoodCount]=useState([])
    const [wrongCount,setWrongCount]=useState([])
    const [divisionData,setDivisionData]=useState(props.divisionInfo)

    useEffect(function () {
        let good = []
        let wrong = []
        //on recrée 2 nouveau tableau d'infos, un contenant les comptes correctes, l'autre contenant les mauvais comptes 
        Object.keys(divisionData).length!=0 && Object.entries(divisionData).map(([key,value])=>{
            //on initalise base qui sera le comptage numéro 1 pour pouvoir comparer avec les autres comptages dans le cas où il y a plusieurs comptages
            //valid lui est initialisé à true, si il y a une erreur entre 2 staff, il passera à false, le comptage sera alors attribué au tableau des mauvais comptes 
            let base
            let valid = true
            
            props.divisionRhInfo && props.divisionRhInfo.length!=0 && props.divisionRhInfo.map((rh,ind)=>{
                if(ind==0){
                    //on initialise base à la valeur du premeir comptage 
                    base = (value['rh'+rh.id]) ? value['rh'+rh.id] : 0
                }else{
                    //on vérifie si il y a eu un d'autre comptage
                    if(value.hasOwnProperty('rh'+rh.id)){
                        //si autre comptage, on compare les autres comptage au premier comptage
                        //si un seul des autres comptages est différent du premmier comptage, on passe valid à false
                        if(value['rh'+rh.id] != base){
                            valid = false
                        }
                    }else{
                        //Si d'autres personnes doivent compter mais qu'ils n'ont pas encore compté
                        valid = false
                    }
                }
            })
            if(valid==true){
                good.push(value)
                
            }else{
                wrong.push(value)
                
            }
        })
        setGoodCount(good)
        setWrongCount(wrong)
    }, [props.divisionRhInfo,props.divisionInfo,divisionData])

    return (
        <div>
            <FiltreSearchBar dataToFiltre={props.divisionInfo} setFilteredData={setDivisionData} reset={true} />
            <div className="table-responsive">
                <table
                    className="table"
                >
                    <thead>
                        <tr>
                            <th scope="col">{t('article')}</th>
                            <th scope="col">{t('binsNumber')}</th>
                            {(props.divisionRhInfo && props.divisionRhInfo.length!=0) ? 
                                props.divisionRhInfo.map((item)=>{
                                        return (
                                            <th key={item.id} scope="col">
                                                {item.name+' '+item.firstname}
                                            </th>
                                        )
                                    })
                                :
                                    false
                            }
                            {(props.stock) &&
                                <th>{t('actualStock')}</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {(wrongCount.length!=0) && wrongCount.map((value,index)=>{
                            let binsNum = (value.bins==null) ? '-' : (value.bins.fifth!=null) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth+'-'+value.bins.fifth : (value.bins.fourth!=null) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth : (value.bins.third!=null) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third : (value.bins.second!=null) ? value.bins.first+'-'+value.bins.second : value.bins.first
                            return(
                                <tr key={'wrong'+index} style={{backgroundColor:'#fccfcf'}}>
                                    <td>{value.article_sku+' - '+value.article_reference+' - '+value.article_name}</td>
                                    <td className="text-center">{binsNum}</td>
                                    {(props.divisionRhInfo && props.divisionRhInfo.length!=0) ? 
                                        props.divisionRhInfo.map((item)=>{
                                            return (
                                                <ControlInventoryQuantityAdmin 
                                                    key={item.id}
                                                    divisionInfo={props.divisionInfo}
                                                    setDivisionInfo={props.setDivisionInfo}
                                                    rhId={item.id}
                                                    quantityCount={value['rh'+item.id]}
                                                    countId={value['count'+item.id]}
                                                    divisionId={value.inventaire_division_id}
                                                    binsId={(value.bins_id!=null) ? value.bins_id : ''}
                                                    articleId={value.article_id}
                                                    setRefresh={props.setRefresh}
                                                />
                                            )
                                        })
                                    :
                                        <td></td>}
                                    {props.stock ? (
                                        props.stock[value.article_id] !== undefined && props.stock[value.article_id] !== null ? (
                                            <td 
                                                className="text-center" 
                                            >
                                                {props.stock[value.article_id]}
                                            </td>
                                        ) : (
                                            <td className="text-center">-</td>
                                        )
                                    ) : null}
                                </tr>
                            )
                        })
                    }
                    {(goodCount.length !== 0) && goodCount.map((value, ind) => {
                        let binsNum = value.bins == null ? '-' : 
                            value.bins.fifth != null ? `${value.bins.first}-${value.bins.second}-${value.bins.third}-${value.bins.fourth}-${value.bins.fifth}` :
                            value.bins.fourth != null ? `${value.bins.first}-${value.bins.second}-${value.bins.third}-${value.bins.fourth}` :
                            value.bins.third != null ? `${value.bins.first}-${value.bins.second}-${value.bins.third}` :
                            value.bins.second != null ? `${value.bins.first}-${value.bins.second}` :
                            value.bins.first;

                        let lastCount = undefined;

                        return (
                            <tr key={'good' + ind} style={{ backgroundColor: '#d9fcd2' }}>
                                <td>{value.article_sku+' - '+value.article_reference+' - '+value.article_name}</td>
                                <td className="text-center">{binsNum}</td>

                                {/* Partie DivisionRhInfo */}
                                {(props.divisionRhInfo && props.divisionRhInfo.length !== 0) ? (
                                    props.divisionRhInfo.map((item) => {
                                        lastCount = value['rh' + item.id];
                                        return (
                                            <ControlInventoryQuantityAdmin 
                                                key={item.id}
                                                divisionInfo={props.divisionInfo}
                                                setDivisionInfo={props.setDivisionInfo}
                                                rhId={item.id}
                                                quantityCount={value['rh' + item.id]}
                                                countId={value['count' + item.id]}
                                                divisionId={value.inventaire_division_id}
                                                binsId={value.bins_id != null ? value.bins_id : ''}
                                                articleId={value.article_id}
                                                setRefresh={props.setRefresh}
                                            />
                                        );
                                    })
                                ) : (
                                    <td></td>
                                )}
                                {props.stock ? (
                                    props.stock[value.article_id] !== undefined && props.stock[value.article_id] !== null ? (
                                        <td 
                                            className="text-center" 
                                            style={lastCount !== props.stock[value.article_id] ? { backgroundColor: '#ffffbb' } : {}}
                                        >
                                            {props.stock[value.article_id]}
                                        </td>
                                    ) : (
                                        <td className="text-center">-</td>
                                    )
                                ) : null}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ControlInventoryCountAdmin;
