import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from 'axios'

const RemoveItemsFromStock = (props) => {
    const t = useTranslate();
   
    const [zone,setZone] = useState([])
    const [loadingZone,setLoadingZone] = useState(false)

    useEffect(function () {
        if(props.selectedSection){
            setLoadingZone(true)
            const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/zone/getall/"+props.selectedSection
            ).then((response) => {
                setZone(response.data.datas)
                setLoadingZone(false)
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
                swal.fire(messageError, "", "error");
                setLoadingZone(false)
            })
        }
    }, [props.selectedSection])

    return(
        <div className="mt-4">
            {(props.documentType=='billing') &&
                <div className="alert alert-danger">
                    {t('warninStockMsgFacture')}
                </div>
            }
            <div>
                <div className='fw-bold'>{props.documentType=='returnNote' ? t('itemsReinstate') : props.documentType=='billing' ? t('removeItemFromStock') : t('collectAllItem')+':'}</div>
                <div className="form-group position-relative">
                    <label>{t('selectSection')}</label>
                    <select disabled={Object.keys(props.company).length==0 || props.validButton} className="form-control form-control-sm" onChange={(e)=>props.setSelectedSection(e.target.value)}>
                        <option value="">-- Select --</option>
                        {props.company.section && props.company.section.map(value=>{
                            return(
                                <option key={value.id} value={value.id}>{value.name}</option>
                            )
                        })}
                    </select>
                    {Object.keys(props.company).length==0 ?  <div className="spinner-border spinner-border-sm" style={{position:'absolute', top:'55%'}}></div> : false}
                </div>
                {props.selectedSection && 
                    <div className="form-group position-relative">
                        <label>{t('selectAStorageArea')}</label>
                        <select disabled={loadingZone || props.validButton} className="form-control form-control-sm" onChange={(e)=>props.setSelectedZone(e.target.value)}>
                            <option value="">-- Select --</option>
                            {zone.map(zone=>{
                                if(zone.havebins==0){
                                    return(
                                        <option key={zone.id} value={zone.id}>{zone.name}</option>
                                    )
                                }
                            })}
                        </select>
                        {loadingZone ?  <div className="spinner-border spinner-border-sm" style={{position:'absolute', top:'55%'}}></div> : false}
                    </div>
                }
            </div>
        </div>
    )
}

export default RemoveItemsFromStock;