import React, {useState} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso, ExportComptabilite,ModalErrorsInvoicingRecords} from '@gull'
import axios from 'axios';
import swal from "sweetalert2";
import { Modal } from "react-bootstrap";

const ExportFormCompta = (props) => {
    const t = useTranslate();
    const [validButton,setValidButton] = useState(false)
    const [show,setShow] = useState(false)
    const [valueDate,setValueDate] = useState({
        begin:'',
        end:''
    })

    const handleChange = (e,name) => {
        setValueDate({...valueDate,[name]:e.target.value})
    };

    const getAccountingRecords = () => {
        setValidButton(true)
        const test = axios.get(process.env.REACT_APP_API_URL+"api/compta/sage/controle_compta/"+valueDate.begin+'/'+valueDate.end
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
                setValidButton(false)
            }else{  
                if(response.data.datas.length!=0){
                    //Dans le cas où l'array est rempli, il y a des erreurs dans les écritures comptable qu'il faut afficher à l'utilisateur
                    setShow(response.data.datas)
                }else{
                    swal.fire({text:t('allAccountingEntriesAreCorrect'),icon: "success"});
                }
                setValidButton(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
            setValidButton(false)
        })
        
    };

    return(<>
        <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('errorInvoicingRecordsMsg')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalErrorsInvoicingRecords errorsInvoicingRecords={show}/>
            </Modal.Body>
        </Modal>
        <CardPerso header="Export">
            <div className="row mb-2">
                <div className="col-12 form-group">
                    <label>{t('beginningPeriod')}</label>
                    <input value={valueDate.begin} type="date" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'begin')}/>
                </div>
                <div className="col-12 form-group">
                    <label>{t('endPeriod')}</label>
                    <input value={valueDate.end} type="date" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'end')}/>
                </div>
            </div>
            <div className='row mb-1' style={{marginLeft:'0.1em', marginRight:'0.1em'}}>
                <ExportComptabilite disabled={(valueDate.begin=='' || valueDate.end=='') ? true : false} setReload={props.setReload} title={t('saleExport')} type="button" url={"api/compta/sage/export_ventes/"+valueDate.begin+'/'+valueDate.end}></ExportComptabilite>
            </div>
            <div className='row' style={{marginLeft:'0.1em', marginRight:'0.1em'}}>
                <ExportComptabilite title={t('thirdPartyExport')} type="button" url={"api/compta/sage/export_tiers"}></ExportComptabilite>
            </div>
            <div className='row' style={{marginLeft:'0.1em', marginRight:'0.1em'}}>
                <button 
                    disabled={(valueDate.begin=='' || valueDate.end=='' ||validButton) ? true : false} 
                    className='btn btn-sm btn-info' 
                    onClick={()=>getAccountingRecords()}
                >
                    {validButton ? <div className="spinner-border spinner-border-sm"></div> : t('reviewOfAccountingRecords')}
                </button>
            </div>
        </CardPerso>
    </>
    )
}

export default ExportFormCompta;