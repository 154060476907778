import React, {useState} from 'react';
import { Modal } from "react-bootstrap";

const BreadCrumbWms = (props) =>{
    const [showModal,setShowModal]=useState(false) 

    const closeModal = () =>{
        setShowModal(false)
    }

    const openModal = () =>{
        if(props.modal!=null && props.modal!='') setShowModal(true)
    }

    return(
        <>
            <Modal show={showModal} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Memo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: props.modal }} />
                </Modal.Body>
            </Modal>
            <div onClick={() => openModal()}>
                {props.path1 &&
                    <span>{props.path1}</span>
                }
                {props.path2 &&
                    <span>{'/'+props.path2}</span>
                }
                {props.path3 &&
                    <span>{'/'+props.path3}</span>
                }
                {props.path4 &&
                    <span>{'/'+props.path4}</span>
                }
                {props.path5 &&
                    <span>{'/'+props.path5}</span>
                }
            </div>
        </>
    )
}

export default BreadCrumbWms;