import React, {useState,useRef,useContext,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso, MatchCsv, Loading,AddMarkExportForm,BtnImportModel} from "@gull";
import { CSVReader } from 'react-papaparse';
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import { useCookies} from 'react-cookie';

const useLoadCustomFields = () => {
    const t = useTranslate()
    const [loadingCustomFields,setLoadingCustomFields]=useState(true)
    const [customFields,setCustomFields]=useState([])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/all/article"
            ).then((response) => {
                setLoadingCustomFields(false)
                setCustomFields(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                setLoadingCustomFields(false)
                if(error.response==undefined){
                    swal.fire(t('unknowError'), "", "error");
                }
                else if(error.response.status==403){
                    swal.fire(t('forbiddenGeneral'), "", "error");
                }
                else {
                    swal.fire(t('unknowError'), "", "error");
                }
                
            })
        })()
    }, [])

    return [loadingCustomFields,customFields]
}

const MatchInternalIdsForm = () => {
    const t = useTranslate()
    const [errorCsv,setErrorCsv] = useState(false)
    const [csvHeader,setCsvHeader] = useState([])
    const [csvData,setCsvData] = useState([])
    // const [preSendError,setPreSendError] = useState(false)
    //const selectMatch = useRef(new Array())
    const [fileName,setFileName] = useState('');
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [resetCsv,setResetCsv] = useState(false)
    const {authParam} = useContext(IsAuthContext);
    const [loadingCustomFields,customFields] = useLoadCustomFields()
    const [selectedValueToMatch,setSelectedValueToMatch]=useState('') 
    const [selectedColumnBase,setSelectedColumnBase]=useState(false) 
    const [columnIndToMatch,setColumnIndToMatch]=useState(false)
    const [cookies, setCookie] = useCookies(['lang']);
    const [addMark,setAddMark]=useState(true) 

    let optionMatchCsvModelDownload = {
        [t('reference')] : '',
        [t('itemName')] : '',
        [t('sku')] : '',
    }

    let optionMatchCsv = [
        t('reference'),
        t('itemName'),
        t('sku')
    ]
    let convert = {
        [t('reference')] : 'reference',
        [t('itemName')] :'name',
        [t('sku')] :'sku'
    }

    const handleOnDrop = (data,file) => {
        setApiError(false)
        setErrorCsv(false)
        //setPreSendError(false)
        setResetCsv(false)
        setFileName(file.name)

        //On calcule le poids du fichier en mégaoctets
        const fileSizeInMegabytes = file.size / (1024 * 1024);
    
        if(fileSizeInMegabytes <= 7) {
        
            if(data.length>process.env.REACT_APP_CSV_IMPORT_LIMIT){
                setErrorCsv(t('limitCsv',{limit: process.env.REACT_APP_CSV_IMPORT_LIMIT}))
            }
            else if(data[0].data.length!=0){
                setCsvHeader(data[0].data)
                if(data[data.length-1].data.length==1){
                    data.pop()
                }
                setCsvData(data)
                // let verifDoublon = new Array()
                // let valid=true
                // selectMatch.current.forEach((r)=>{
                //     if(r.value!='no'){
                //         if(!verifDoublon.includes(r.value)){
                //             verifDoublon.push(r.value)
                //         }
                //         else{
                //             valid=false
                //         }
                //     }
                // })
                // if(!valid){
                //     setPreSendError(t('doublonCsv'))
                // }
            }
            else setErrorCsv(t('noDataInCsv'))
        }else{
            setErrorCsv(t('fileCsvToLarge'))
        }
    };

    const handleOnError = (err, file, inputElem, reason) => {
        setErrorCsv(t('unknowError'))
        setCsvHeader([])
        setFileName('')
    };
    
    const handleOnRemoveFile = (data) => {
        setApiError(false)
        setErrorCsv(false)
        setCsvHeader([])
        //setPreSendError(false)
        setFileName('')
        //selectMatch.current = []
    };

    const goImport = async () => {
        setValidButton(true)
        setApiError(false)
        
        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/retreive_internalid",{
            data: csvData,
            match_column: columnIndToMatch,
            match_database: selectedColumnBase,
            mark:addMark,
            lang: (cookies.lang) ? cookies.lang : 'fr'
        }).then((response) => {
            if(!response.data.valid){
                return(
                    swal.fire({
                        icon: 'error',
                        title: t('unknowError')
                    })
                ) 
            }
            else{
                return(
                    swal.fire({
                        icon: 'success',
                        title: t('csvWillBeReady')
                    })
                ) 
            }
        }).catch((error) => {
            if(error=='end_session') return;
            return(
                swal.fire({
                    icon: 'error',
                    title: t('unknowError')
                })
            ) 
        })
        setValidButton(false)
    }

    const handleSelectToMatch = (valueToMatch,columnIndex) =>{
        if(selectedValueToMatch == valueToMatch){
            setSelectedValueToMatch('')
        }else{
            setSelectedValueToMatch(valueToMatch)
            setColumnIndToMatch(columnIndex)
        }
    }

    const handleChange = (e) =>{
        setSelectedColumnBase(e.target.value)
    }
    
    if(loadingCustomFields){
        return (<Loading></Loading>);
    }

    return (
        <div className="row">
            <div className="col-12">
                <CardPerso header={t('matchInternalId')}>
                    <div className="text-end mb-1">
                        <BtnImportModel
                            modele={//Passer false si aucun modle à télécharger
                                {
                                    fileName:t('template')+' '+t('matchInternalId'),
                                    data:[
                                        optionMatchCsvModelDownload
                                    ]
                                }
                            }
                        />
                    </div>
                    <CSVReader
                        onDrop={handleOnDrop}
                        onError={handleOnError}
                        addRemoveButton
                        onRemoveFile={handleOnRemoveFile}
                        isReset={resetCsv}
                        config={{
                            delimiter:authParam.company.advanceparams.csv_separator,
                            encoding:authParam.company.advanceparams.csv_encode
                        }}
                    >
                        <span>{t('dropCsv')}</span>
                    </CSVReader>
                    <div className="mt-3">
                       
                        {(csvHeader.length!=0) &&
                            <div>
                                <div className="mb-3">{t('selectColumnFileToMatch')}</div>
                                {csvHeader.map((value,index)=>{
                                    let styleBtn = (selectedValueToMatch == value) ? 'btn btn-info' : 'btn btn-outline-info'
                                    return(
                                        <button key={index} className={styleBtn} style={{marginRight:'0.5rem', marginBottom:'0.5rem'}} onClick={()=>handleSelectToMatch(value,index)}>{value}</button>
                                    )
                                })}
                            </div>
                        }
                        {(selectedValueToMatch!='') &&
                            <div className="mt-3">
                                <div className="mb-3 d-flex">
                                    {t('selectColumnBaseToMatch')}&nbsp;&nbsp;
                                    <AddMarkExportForm setAddMark={setAddMark} addMark={addMark} />
                                </div>
                                <select className="form-control" onChange={(e)=>handleChange(e)}>
                                    <option value={false}>-- Select --</option>
                                    {optionMatchCsv.map((value,ind)=>{
                                        return(<option key={ind} value={convert[value]}>{value}</option>)
                                    })}
                                </select>
                            </div>
                        }
                         {/* {(preSendError) &&
                            <div className="alert alert-danger text-center mt-3">{preSendError}</div>
                        } */}
                        {(apiError) ? <div className="alert alert-danger mt-3 text-center" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                        {(selectedColumnBase && selectedValueToMatch!='') &&
                            <button className="btn btn-primary mt-3" onClick={()=>goImport()}>{validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}</button>
                        }
                        {(errorCsv) &&
                            <div className="alert alert-danger text-center">{errorCsv}</div>
                        }  
                    </div>
                </CardPerso>    
            </div>
        </div>
    );
}

export default MatchInternalIdsForm;
