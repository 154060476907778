import React, {useState, useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { CustomizeInterfaceBtn,FiltresCustomisation,BtnFavoriteList,BtnFavoriteCreate} from '@gull';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import { Modal } from "react-bootstrap";
import EachDataFilter from './CompArticleFilterSearch2/EachDataFilter'

const ArticleFilterSearch2 = (props) => {
    const t = useTranslate();
    const [customFilter,setCustomFilter]=useState(false) 
    const [relance,setRelance]=useState(Date.now()) 
    const [validButton,setValidButton] = useState({
        filtre:false,
        favorite:false
    });
    const [favoriteFilterActived,setFavoriteFilterActived]=useState(false) 
    const { authParam } = useContext(IsAuthContext);
    const maxConditionnement = (authParam.company.advanceparams.max_conditionnement!='' && authParam.company.advanceparams.max_conditionnement!=null) ? +authParam.company.advanceparams.max_conditionnement : 0
    const suppPrice = authParam.company.advanceparams.hasOwnProperty('prix_supplementaire') ? authParam.company.advanceparams.prix_supplementaire : []
    let fields = props.customFields ? props.customFields.map(value => value.name) : false

    let packagingListData = []
    for (let i=0; i<maxConditionnement; i++){
        let numeroEnumere = i+1
        packagingListData.push('name_'+numeroEnumere)
        packagingListData.push('reference_'+numeroEnumere)
        packagingListData.push('quantity_'+numeroEnumere)
    }

    let priceSuppListData = []
    suppPrice.map((value,index)=>{
        let numero = index+1
        priceSuppListData.push('price_'+value['designation_'+numero])
    })

    let allHardData = [...props.hardData,...packagingListData,...priceSuppListData]

    useEffect(function () {
        let initCustomFilter
        let allFilter = props.initAllFilter ? { ...props.initAllFilter } : {}

        if(allFilter.hasOwnProperty('havestock') && Array.isArray(allFilter.havestock)) allFilter.havestock=''

        if(props.from && props.from == 'facturation'){
            //pour la facturation, on a réduit les possibilité de filtrage 
            initCustomFilter = {details:['firstlevelcat','secondlevelcat','thirdlevelcat','fourthlevelcat','fifthlevelcat','marque','fournisseur']}
        }else if(props.customInterface.user_conf){
            initCustomFilter = props.customInterface.user_conf
            props.customInterface.user_conf.details.map(value=>
                allFilter[value]=[]
            )

        }else if(props.customInterface.default_conf){
            initCustomFilter = props.customInterface.default_conf
            props.customInterface.default_conf.details.map(value=>
                allFilter[value]=[]
            )
        }

        setCustomFilter(initCustomFilter)
        props.setSendData(allFilter)

    }, [props.customInterface,relance])

    let apiAdress 
    switch(props.moduleName){
        case 'items':
            apiAdress = 'api/erp/article/list'
            break;
        case 'purchaseOrder':
            apiAdress = 'api/erp/po/onewithlist/'+props.id
            break;
        case 'shipmentVoucher':
            apiAdress = 'api/erp/shipment/onewithlist/'+props.id
            break;
        case 'rppsl':
            apiAdress = 'api/erp/rppsl/get_all'
            break;
        case 'stockItem':
            apiAdress = 'api/erp/article/list_with_stock'
            break;
        case 'filtrePromo':
            apiAdress = 'api/erp/promotion/get_article_of_promotion/'+props.id
            break;
        case 'filtrePromoSecond':
            apiAdress = 'api/erp/promotion/get_article_of_promotion_second/'+props.id
            break;
        case 'fideliteItem':
            apiAdress = 'api/erp/fidelite/get_one/'+props.id
            break;
        case 'stockItemPos':
            apiAdress = 'api/erp/article/list_with_zone_stock'
            break;
        case 'internalRestockException':
            apiAdress = 'api/pos/reassort_interne/all_exception'
            break;
        case 'itemsInvoicing':
            apiAdress = 'api/erp/article/stock_in_company'
            break;
    }

    const onSubmit = async (data=false) => {
        setValidButton({...validButton,filtre:true})
        let obj={}

        if(props.from=='inventaire') obj['havestock']='oui'

        if(data){
            obj = data
        } 
        else {
            let keys = Object.keys(props.sendData);
            for(var i = 0; i < keys.length; i++){
                if(Array.isArray(props.sendData[keys[i]])){
                    obj[keys[i]]=[]
                    props.sendData[keys[i]].forEach(value=>obj[keys[i]].push(value.id))
                }
                else{
                    obj[keys[i]]=props.sendData[keys[i]]
                }
            }
            props.setFiltre(obj)
        }
        if(props.moduleName=='stockItemPos') obj.section=props.posid
        
        const response = await axios.post(process.env.REACT_APP_API_URL+apiAdress+"/50/1",obj
        ).then((response) => {
            setValidButton({...validButton,filtre:false})
            switch(props.moduleName){
                case 'items':
                    props.setDataTable(response.data.datas)
                    break;
                case 'purchaseOrder':
                    props.setDataTable(response.data.list)
                    break;
                case 'shipmentVoucher':
                    props.setDataTable(response.data.list)
                    break;
                case 'rppsl':
                    props.setDataTable(response.data.datas)
                    break;
                case 'stockItem':
                    props.setDataTable(response.data.datas)
                    break;
                case 'filtrePromo':
                    props.setDataTable(response.data.datas)
                    break;
                case 'filtrePromoSecond':
                    props.setDataTable(response.data.datas)
                    break;
                case 'fideliteItem':
                    props.setDataTable(response.data.datas)
                    break;
                case 'stockItemPos':
                    props.setDataTable(response.data.datas)
                    break;
                case 'internalRestockException':
                    props.setDataTable(response.data.datas)
                    break;
                case 'itemsInvoicing':
                    props.setDataTable(response.data.datas)
                    break;
            }
            
            props.setSizeDatas(response.data.count)
            props.setCurrentPage(1)
            if(props.from=='inventaire' && !data){
                //utilisé dans l'inventaire pour afficher le btn d'ajout d'inventaire
                props.setShowAddBtn(false)
            }
            if( props.setClearTable){
                props.setClearTable(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            setValidButton({...validButton,filtre:false})
            swal.fire(t('unknowError'), "", "error");
            if( props.setClearTable){
                props.setClearTable(false)
            }
        })
    }

    const handleEditFavorite = async () =>{
        setValidButton({...validButton,favorite:true})
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/filtre/edit/"+favoriteFilterActived, {
            filtre:props.sendData
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                props.setToast(true)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setValidButton({...validButton,favorite:false})
    }

    if(props.initAllFilter){
        var allFilter = props.initAllFilter;

        let keysToReset = Object.keys(props.sendData).filter(key=> key != Object.keys(allFilter).find(value=>value===key))

        keysToReset.map(value=>
            allFilter[value]=[]
        )
        
        if(!props.addItemPromoBtn){
            allFilter.sortField='internalid'
            allFilter.sortOrder='asc'
        }
    }
    
    const handleReset = () => {
        //Ce bloc permet de remettre à l'initial les filtres si jamais on a récupéré un filtrage provenant des favoris
        //car depuis les favori on peut rajouter des filtres qui n'était pas dans la configuration initiale
        if(favoriteFilterActived) setRelance(Date.now())
        
        if(!favoriteFilterActived) props.setSendData(allFilter)

        props.setFiltre({})
        if(props.from=='inventaire'){
            onSubmit({havestock:'oui'}) 
        }else{
            onSubmit({}) 
        }
        
        if(props.from=='inventaire'){
            //utilisé dans l'inventaire pour disabled le btn d'ajout d'inventaire
            props.setShowAddBtn(true)
        }
        props.setClearTable(true)//permet de réinitisalier tout le tableau surtout le sort 
        setFavoriteFilterActived(false)//Permet de d'afficher le btn ajouter au favori et non celui qui permet de modifier un filtre
    }

    const deleteFilter = (id,nameInput) => {
        props.setSendData({...props.sendData,[nameInput]:props.sendData[nameInput].filter(value=>value.id!=id)})
    }
    
    return(<>
        <Modal size="xl" show={props.showModalCustomFiltre} onHide={() => props.setShowModalCustomFiltre(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('customizationInterface')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FiltresCustomisation 
                    interface={props.interface} 
                    fields={fields ? fields.concat(allHardData) : allHardData} 
                    setReload={props.setReload} 
                    setSendData={props.setSendData}
                    setCustomInterface={props.setCustomInterface} 
                    moduleName={props.moduleName}
                />
            </Modal.Body>
        </Modal>
        <div className="row mb-3">
            <div className="col" style={{fontSize:'1rem'}}>
                <CustomizeInterfaceBtn  setShow={props.setShowModalCustomFiltre}/>&nbsp;&nbsp; 
                <BtnFavoriteList 
                    tableName={props.tableName} 
                    onSubmit={onSubmit} 
                    filterItemsPromo={props.filterItemsPromo}
                    setFiltre={props.setFiltre} 
                    setSendData={props.setSendData} 
                    setFavoriteFilterActived={setFavoriteFilterActived} 
                    setCustomFilter={setCustomFilter}
                />
            </div>
        </div>
        <div className="row">
            {(customFilter) &&
                customFilter.details.map((filtreName, index) =>{
                    let existCustomField = props.customFields.find(fieldCustom => fieldCustom.name == filtreName)
                    if(allHardData.includes(filtreName) || existCustomField){
                        return(
                            <div key={index} className="col-lg-3 col-md-4 col-6 mb-3">
                                <div className="form-group">
                                    <label className="col-form-label col-form-label-sm">
                                        {
                                            (filtreName==='name')? t('itemName') : 
                                            (filtreName==='price')? t('priceTtc') :
                                            (filtreName==='indice')? t('prmpIndice') :
                                            (filtreName==='image')? t('imageLink') :
                                            (filtreName==='quota_commande')? t('quantity') :
                                            (filtreName==='commande')? t('orderDate') :
                                            (filtreName==='tgc')? t('tgc', {taxe:authParam.company.nomenclature_tgc}) :
                                            (filtreName==='expedition')? t('expectedDeliveryDate') :
                                            (filtreName==='reception')? t('expectedReceiptDate') :
                                            (filtreName==='user')? t('receivedBy') :
                                            (filtreName.startsWith('name_'))? t('packagingName')+' '+filtreName.split('_')[1] :
                                            (filtreName.startsWith('reference_'))? t('barcodePackaginglist')+' '+filtreName.split('_')[1] :
                                            (filtreName.startsWith('quantity_'))? t('packagedQuantity')+' '+filtreName.split('_')[1] :
                                            (filtreName.startsWith('price_') && filtreName!='price_b2b')? t('price')+' '+filtreName.split('_')[1] :
                                            t(filtreName) ? t(filtreName) :
                                            filtreName
                                        }
                                    </label>
                                    {(allHardData.includes(filtreName)) ? 
                                        <div>                  
                                            <div className="col-12">
                                            {props.sendData[filtreName].map((value,ind)=>
                                                <span key={ind} className="badge badge-primary bg-primary mb-1" style={{cursor:'pointer'}} onClick={() => deleteFilter(value.id,filtreName)}>{value.label} <FontAwesomeIcon icon={faTimesCircle} /></span>
                                            )}
                                            </div>
                                            {(props.liaisons && props.liaisons.length!=0 && filtreName===Object.keys(props.liaisons).find(value=>value===filtreName)) ?
                                                <EachDataFilter 
                                                    list={[{id:"",name:t('emptyValue')},...props.liaisons[filtreName]]} 
                                                    sendData={props.sendData} 
                                                    setSendData={props.setSendData} 
                                                    inputName={filtreName}
                                                    selectType={true}
                                                /> 
                                            :
                                                <EachDataFilter 
                                                    list={[{id:"",name:t('emptyValue')}]} 
                                                    sendData={props.sendData} 
                                                    setSendData={props.setSendData} 
                                                    inputName={filtreName}
                                                    selectType={false}
                                                />
                                            }
                                        </div>
                                    :
                                        <div>
                                            <div className="col-12">
                                                {props.sendData[filtreName].map((filtre,filtre_ind)=>
                                                    <span key={filtre_ind} className="badge badge-primary bg-primary mb-1" style={{cursor:'pointer'}} onClick={() => deleteFilter(filtre.id,filtreName)}>{filtre.label} <FontAwesomeIcon icon={faTimesCircle} /></span>
                                                )}
                                            </div>
                                            {props.customFields && props.customFields.map((value,index)=>
                                                <div key={index}>
                                                    {(value.name === filtreName && !value.free) ?
                                                        <EachDataFilter 
                                                            list={[{id:"",name:t('emptyValue')},...value.list.map((val,ind)=>({name:val,id:val}))]} 
                                                            sendData={props.sendData} 
                                                            setSendData={props.setSendData} 
                                                            inputName={filtreName} 
                                                            selectType={true}
                                                        /> 
                                                    :
                                                    (value.name === filtreName && value.free) && 
                                                        <EachDataFilter 
                                                            list={[{id:"",name:t('emptyValue')}]} 
                                                            sendData={props.sendData} 
                                                            setSendData={props.setSendData} 
                                                            inputName={filtreName} 
                                                            selectType={false}
                                                        /> 
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    }
                                </div>
                            </div>  
                        )
                    }
                })
            }
        </div>
        {
            props.children
        }
        {props.addItemPromoBtn ?
            <>
                <button disabled={props.loading} className="btn btn-primary mt-3" onClick={() => props.filterItemsPromo(false)}>
                    {props.loading ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('addSelection')}
                </button>
                <button disabled={props.loading || props.disabledAddSelection} type="button" className="btn btn-warning mt-3" style={{marginLeft: '10px'}} onClick={() => props.resetFiltreItemsToPromo()}>
                    Reset
                </button>
            </>
        :
            <>
                <button disabled={validButton.filtre} className="btn btn-primary mt-3" onClick={() => onSubmit()}>
                    {validButton.filtre ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
                <button type="button" disabled={validButton.filtre} className="btn btn-warning mt-3" style={{marginLeft: '10px'}} onClick={() => handleReset()}>
                    {validButton.filtre ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : 'Reset'}
                </button>
            </>
        }
        {Object.keys(props.sendData).length!=0 && <BtnFavoriteCreate tableName={props.tableName} sendData={props.sendData} setToast={props.setToast}/>}
        {favoriteFilterActived && 
            <button className="btn btn-success mt-3" style={{marginLeft: '10px'}} onClick={()=>handleEditFavorite()}>
                {validButton.favorite ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('editFavorite')}
            </button>
        }
    </>)
};

export default ArticleFilterSearch2;