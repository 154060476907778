import React, {useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'

const SelectSectionObjectif = (props) => {
    const t = useTranslate();

    useEffect(function () {
        if(props.defaultValue!=0) props.setForSection(props.defaultValue)
    }, [])

    const loadObjectif = (e) => {
        props.setForSection(false)
        props.setShowTabsContain({//on réinitialise le tab contain sur la valeur par defaut
            today:true
        })
        if(e.target.value!='0'){
            props.setForSection(e.target.value);
        }
    }

    return(
        <>
            <div className="breadcrumb">
                <select value={props.forSection} className="form-control" onChange={(e) => loadObjectif(e)}>
                    <option value="0">-- Select --</option>
                    {props.sections.map((section)=>{ 
                        if(section.type!='ecommerce') return(<option key={section.id} value={section.id}>{section.name}</option>)
                    })}
                </select>
            </div>
            <div className="separator-breadcrumb border-top"></div>
        </>
    )
}

export default SelectSectionObjectif;