import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FormatedPrice } from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { modePaiementDefaultAll,modePaiementFidelite } from 'app/modePaiementDefaultAll';
import DetailGiftCard from "../../form/PaymentForm/Component/PaymentLeftComp/DetailGiftCard";

const LinePaymentRecord = (props) =>{
    const t =useTranslate()
    const [newPaymentMode,setNewPaymentMode]=useState('')
    const [loading,setLoading]=useState(false)
    const [loadingGiftCard,setLoadingGiftCard]=useState(false) 
    const [showGiftCardForm,setShowGiftCardForm]=useState(false) 
    const [giftCardVal,setGiftCardVal]=useState('')
    const [giftCardId,setGiftCardid]=useState(false)
    const [giftCardError,setGiftCardError]=useState(false) 
    const [infosGiftCard,setInfosGiftCard]=useState({})
    const [showBtnValid,setShowBtnValid]=useState(false) 

    const [priceValue,setPriceValue]=useState(props.value.montant) 
    const [showBtnModifPrice,setShowBtnModifPrice]=useState(false) 
    const [validButton,setValidButton]=useState(false) 
    const [editPayRecordError,setEditPayRecordError]=useState(false) 

    const idsLocalStorage=(props.from=='pos' || (props.from=='commande' && props.module=='pos')) ? localStorage.getItem("pos").split(',') : [0,0]

    useEffect(function () {
        let modePay = (modePaiementFidelite.includes(props.value.mode)) ? t('loyalty') : props.value.mode
        setNewPaymentMode(modePay)
    }, [])

    useEffect(()=>{//pour le focus sur l'input scan giftcard
        const input = document.querySelector('#giftCardScanChange');
        if(input!=null && newPaymentMode == 'giftcard'){
            setTimeout(()=>{
                input.focus()
            },400)
            
        }

    },[newPaymentMode])

    const submitNewPaymentMode = async (modePay) =>{
        setLoading(true)
        let urlParam = (props.from=='pos') ? idsLocalStorage[1]+"/"+idsLocalStorage[0] : props.caisseIdent+'/'+props.pos_id

        let obj = {
            id:props.ticketId,
            modif_paiement_record_mode:props.value.id,
            paiement_mode:modePay
        }

        if(modePay=='giftcard'){ 
            obj['gift_card_id']=giftCardId
        }

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+urlParam,obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{  
                if(props.module=='pos'){ 
                    if(props.setDataTicket){
                        if(props.from=='pos'){
                            //maj des infos du data table
                            let salesHisto = props.dataTicket.map(value=>value.id)
                            let indexToEdit = salesHisto.indexOf(props.value.ticket_id)
                            let newData = props.dataTicket.filter(value=>value.id!=props.value.ticket_id)

                            newData.splice(indexToEdit,0, response.data.data)
                            props.setDataTicket(newData)
                        }else if(props.from=='commande'){
                            //maj des infos du data table
                            let salesHisto = props.dataTicket.map(value=>value.ticket.id)
                            let indexToEdit = salesHisto.indexOf(props.value.ticket_id)
                            let justLineToEdit = props.dataTicket.filter(value=>value.ticket.id==props.value.ticket_id)
                            let newData = props.dataTicket.filter(value=>value.ticket.id!=props.value.ticket_id)
                            let lineToEdit = justLineToEdit[0]
                            lineToEdit = {...lineToEdit,ticket:response.data.data}

                            newData.splice(indexToEdit,0, lineToEdit)
                            props.setDataTicket(newData)
                        }
                    }else if(props.setDataSaleOrder){
                        props.setDataSaleOrder({...props.dataTicket,ticket:response.data.data})
                    }
                }
                
                //maj des infos de la modal
                props.setAllPaymentRecords(response.data.data.paiement_records)
                setLoading(false)
                setShowGiftCardForm(false)
                setGiftCardVal('')
                setGiftCardid('')
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setLoading(false)
    }

    const getGiftCardPay = async (e) =>{
        e.preventDefault();

        setLoadingGiftCard(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/gift_card/get_by_reference/"+giftCardVal
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setGiftCardError(messageError)
            }
            else{   
                if(response.data.data.sections_avaible.includes(+props.posid)){
                    setGiftCardid(response.data.data.id)
                    setInfosGiftCard(response.data.data)

                    if(response.data.data.disponible == 0){//on vérifie si les fonds sont disponibles sur la carte cadeau
                        setGiftCardError(t('noFundAvailable'))

                    }else{
                        if(priceValue!=''){//si priceValue est une chaine vide, c'est qu'on est dans le cas d'un ajout d'un nouveau paiement sans avoir encore renseigné le montant
                            if(+response.data.data.disponible >= +priceValue){
                                if(props.value.id!=null) setShowBtnValid(true)
                                
                            }else if(+response.data.data.disponible < +priceValue){
                                setGiftCardError(t('noEnoughFund'))
                            }
                        }
                    }
                }else{//dans le cas où la gift card n'est pas valid pour ce commerce 
                    setGiftCardError(t('notValidGiftCard'))
                }
            }
            setLoadingGiftCard(false)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoadingGiftCard(false)
    }

    const submitApiModifPrice = async (obj) =>{
        setValidButton(true)
        const response = await axios.put(process.env.REACT_APP_API_URL+"api/pos/ticket/late_edit_ticket_paiement/"+props.ticketId, obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setShowBtnModifPrice(false)
                props.setAllPaymentRecords(response.data.data.paiement_records)
                props.setDisabledAddPayLine(false)
                setShowGiftCardForm(false)
                setGiftCardVal('')
                setGiftCardid('')

                let totalPaiement = 0
                response.data.data.paiement_records.map(item=>{
                    totalPaiement+=item.montant
                })
                let totalRemboursement = 0
                response.data.data.remboursement_records.map(item=>{
                    totalRemboursement+=item.montant
                })
                if(props.totalAchat && props.setAlertDifferenceTotal && (totalPaiement!=0 && Math.abs(+props.totalAchat) != +totalPaiement || totalRemboursement!=0 && +totalRemboursement != Math.abs(+props.totalAchat))){ 
                    props.setAlertDifferenceTotal(true)
                }else{
                    props.setAlertDifferenceTotal(false)
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setValidButton(false)
    }

    const submitModifPrice = async () =>{
        setEditPayRecordError(false)
        let obj = {}

        if(props.value.id!=null){
            //Edition du montant d'un paiement deja existant
            if(priceValue!=''){
                obj['paiement_records']=props.value.id
                obj['paiement_montant']=priceValue
                
                submitApiModifPrice(obj)
            }else{
                setEditPayRecordError(t('allFieldsRequired'))
            }
        }else{
            //Ajout d'un nouveau paiement  
            //l'id est égale à null
            if(priceValue!='' && newPaymentMode!=''){
                obj['paiement_montant']=priceValue
                obj['paiement_mode']=newPaymentMode
                if(newPaymentMode == 'giftcard') obj['paiement_gift_card_id']=giftCardId
    
                if(newPaymentMode == 'giftcard'){
                    if(infosGiftCard.disponible >= +priceValue){
                        submitApiModifPrice(obj)
                    }else{
                        setGiftCardError(t('noEnoughFund'))
                    }
                }else{
                    submitApiModifPrice(obj)
                }
            }else{
                setEditPayRecordError(t('allFieldsRequired'))
            }
        }
    }

    const handleChangePaymentMode = (e) =>{
        setNewPaymentMode(e.target.value)
        
        if(e.target.value == 'giftcard'){
            setShowGiftCardForm(true)
        }else{
            setShowGiftCardForm(false)
            //on exécute le submit si le paiement qu'on édit n'est pas un paiement qu'on vient de rajouter via le boutton add
            if(props.value.id!=null) submitNewPaymentMode(e.target.value)
        }
    }

    const handleChange = (e,name) =>{
        if(name=="giftcardchange"){
            setGiftCardVal(e.target.value)
            setGiftCardid(false)
            setInfosGiftCard({})
            setGiftCardError(false)
        }else{
            if(!showBtnModifPrice) setShowBtnModifPrice(true)
            setGiftCardError(false)
            setPriceValue(e.target.value.replace(/\D/g,''))
        }
    }

    const cancelModif = () =>{
        if(props.value.id!=null){
            //Si on annule une modification de prix, on masque les bouttons et en remet le prix initial 
            setShowBtnModifPrice(false)
            setPriceValue(props.value.montant) 
        }else{
            //Si on annule l'ajout d'un paiment, on supprime cette ligne
            let arr=[]
            props.allPaymentRecords.map(value=>{
                if(value.id!=null) arr.push(value)
            })
            props.setAllPaymentRecords(arr)
            props.setDisabledAddPayLine(false)
        }
    }
    
    return(<>
            <tr className="position-relative">
                <td style={{width:'70%'}}>
                    <select disabled={(props.module=='pos' && props.value.pos_id!=idsLocalStorage[0]) || newPaymentMode==t('loyalty')} value={newPaymentMode} className="form-control" type="text" onChange={(e)=>handleChangePaymentMode(e)}>
                        <option value="">-- SELECT --</option>
                        {props.currentShop.modepaiement.map((item,ind)=>{
                            return(
                                <option key={ind} value={item}>
                                    {(modePaiementDefaultAll.includes(item)) ?
                                        t(item)
                                    :
                                        item
                                    }
                                </option>
                            )
                        })}
                         {/* Si le mode de paiement renseigné dans le back n'existe plus dans le commerce */}
                        {(newPaymentMode!='' && !props.currentShop.modepaiement.includes(newPaymentMode)) &&
                            <option value={newPaymentMode}>
                                {(modePaiementDefaultAll.includes(newPaymentMode)) ?
                                    t(newPaymentMode)
                                :
                                    newPaymentMode
                                }
                            </option>
                        }
                    </select>

                    <span className="text-muted">{(props.value.gift_card!=undefined && props.value.gift_card!=null) ? "Ref : "+props.value.gift_card.reference : false}</span>
                    {(editPayRecordError) ? <div className="text-danger fw-bold mt-2">{editPayRecordError}</div> : false}
                    
                    {(showGiftCardForm) ?
                        <form onSubmit={(e)=>getGiftCardPay(e)} className="position-relative">
                            <div className="form-group mt-2">
                                <label htmlFor="giftcard">{t('scanGiftCard')}</label>
                                <input autoComplete="off" id='giftCardScanChange' className='form-control'  value={giftCardVal} type="text" onChange={(e)=>handleChange(e,'giftcardchange')} />
                                {(giftCardError) ? <div className="text-danger fw-bold mt-2">{giftCardError}</div> : false}
                            </div>
                            {(Object.keys(infosGiftCard).length!=0) ? 
                                <div className="text-primary mb-2">
                                    <span className="mb-1">{t('disponible')+': '}</span><strong className="d-inline-block">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={infosGiftCard.disponible} />}</strong><br />
                                    <span className="mb-2">{t('clientAssocie')+': '}</span><strong>{infosGiftCard.client.name+' '+infosGiftCard.client.firstname}</strong><br />
                                    <span className="position-relative">
                                        <DetailGiftCard 
                                            infosGiftCard={infosGiftCard}
                                            devise={props.devise}
                                            digit={props.digit}
                                        />

                                        {showBtnValid && !giftCardError ? <button disabled={loading} className='btn btn-sm btn-primary changeModePayPosition' onClick={()=>submitNewPaymentMode('giftcard')}>{loading ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}</button>:false}
                                    </span>
                                </div> 
                            : 
                                false
                            }
                            {loadingGiftCard ?
                                <div className="spinnerScanGiftCard"><div className="spinner-border spinner-border-sm"></div></div>
                            :
                                false
                            }
                        </form>
                    :
                        false
                    }
                </td>
                <td className="text-end">
                    {loading ? <span ><div className="spinner-border spinner-border-sm"></div>&nbsp;</span> : false}
                    {(props.module=='pos') ? 
                        <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.montant} />
                    :
                        <input type="text" className="form-control" value={priceValue} onChange={(e)=>handleChange(e,'price')}/>
                    }
                    {(props.value.id==null || showBtnModifPrice) ?
                        <div className="mt-1 d-flex">
                            <button className="btn btn-sm btn-danger" onClick={()=>cancelModif()}>{t('btnCancel')}</button>
                            <button className="btn btn-sm btn-primary" onClick={()=>submitModifPrice()}>{validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}</button>
                        </div>
                    :
                        false
                    }
                </td>
            </tr>
        </>
    )    
}

export default LinePaymentRecord;