import React,{useState, useEffect,useRef, useContext} from "react";
import { ActionClientBarLeft, FormatedPrice } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import {montantGiftCard} from 'app/montantGiftCard';
import { IsAuthContext } from 'app/App';

const GiftCardForm = (props) =>{
    const t =useTranslate()

    const [inputGiftCard,setInputGiftCard]=useState('')
    const [inputAmount,setInputAmount]=useState('')
    const [inputClient,setInputClient]=useState('')
    const [invalidInput,setInvalidInput] = useState({searchClient:true})
    const [validButton,setValidButton]=useState(false)
    const searchInputClient = useRef('')
    const [load,setLoad]=useState({
        client:false,
    })
    const [error,setError]=useState(false)
    const [clientId, setClientId ]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    
    const { authParam } = useContext(IsAuthContext);
    const modulePos = authParam.modules.find(value=>value.name=='pos')
    const clientRequired = (modulePos.params.client.active && !Array.isArray(modulePos.params.client.params) && modulePos.params.client.params.hasOwnProperty('client_required') && modulePos.params.client.params.client_required) ? true : false

    useEffect(function () {
        if(props.cookies.current_ticket){
            if(Object.keys(props.currentTicket).length!=0){
                if(props.currentTicket.client!=null){
                    setClientId(props.currentTicket.client_id)
                }
            }
        }else if(props.valueInputClient!=''){
            setInputClient(props.valueInputClient)
            setClientId(props.clientId)
            setInvalidInput({searchClient:false})
        }

    }, [props.currentTicket])

    useEffect(function () {
        //Si props.showGiftCardFidelite est passé en props, on est dans le cas d'un ajout d'un carte cadeau depuis le module de fidélité 
        if(props.showGiftCardFidelite){
            setInputAmount(props.showGiftCardFidelite)
        }

    }, [props.showGiftCardFidelite])

    const noRedInput = () => {
        setInputClient('')
        setInvalidInput(false)
    }

    const handleChange = (e,name) =>{
        if(name=='giftcard'){
            setInputGiftCard(e.target.value)

        }else if(name=='amount'){
            if(props.digit==0){
                //Si les montant à afficher ne nécéssite pas d'afficher de décimale, on autorise l'écriture que de chiffres entiers 
                setInputAmount(e.target.value.replace(/\D/g,''))
            }else{
                setInputAmount(e.target.value.replace(/[^0-9\.]/g, ''))
            }
        }
    }

    const handleClick = (montant) =>{
        setInputAmount(montant)
    }    

    const handleSubmit = async () =>{
        setValidButton(true)
        setError(false) 
        //Dans le cas où le fait de renseigner le client n'est pas obligatoire
        //on envoie null au back sinon on envoie l'id du client 
        let identifiantClient = (clientId) ? clientId : null

        let data ={
            gift_card_reference: inputGiftCard,
            gift_card_montant: inputAmount,
            gift_card_client_id: identifiantClient,//client titulaire de la gift card
        }

        if(props.cookies.current_ticket){
            data['id']=props.cookies.current_ticket
            //Si il existe deja un ticket en cours, on prend les infos du client depuis le ticket
        }else{
            //Si il n'y a pas de ticket on détermine le titulaire de la gift card comment étant l'acheteur dans le cas où le client est required
            data['client_id']=identifiantClient//client qui fait l'achat
            if(props.cashierId) data['rh_id']=props.cashierId
        }

        let currentTicketCookie

        if((clientRequired && inputClient=='') ||  inputGiftCard=='' || inputAmount=='' ){
            setError(true) 
            setValidButton(false)

        }else{
            const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{   
                    props.setCurrentTicket(response.data.data)
                    currentTicketCookie=response.data.data.id
                    props.closeGiftCardModal()
                    props.setShowModalFidelite && props.setShowModalFidelite(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
    
            if(!props.cookies.current_ticket && props.setCookie){
                props.setCookie("current_ticket",currentTicketCookie,{ path: "/", expires: props.expire_date }) 
            }
            
            setInputGiftCard('')
            setInputAmount('')
            setInputClient('')
            setError(false) 
            setValidButton(false)
        }
    }

    return(
        <div className="row">
            <div className="col-10 mx-auto">
                <input value={inputGiftCard} placeholder={t('scanGiftCardHere')} type="text" className="form-control mb-3" onChange={(e)=>handleChange(e,'giftcard')}/>
                <input disabled={props.showGiftCardFidelite} value={inputAmount} placeholder={t('amount')} type="text" className="form-control mb-3" onChange={(e)=>handleChange(e,'amount')}/>
                {(!props.showGiftCardFidelite) &&
                    <div className="row">
                        {authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && 
                            authParam.company.advanceparams.ventilation_espece.billet.map((value,ind)=>(
                                <div 
                                    key={ind} 
                                    className="col-4 mb-3 text-center"
                                >
                                    <button 
                                        className="btn btn-dark" 
                                        onClick={()=>handleClick(value)}
                                    >
                                        {<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value} />}
                                    </button>
                                </div>
                                
                            ))
                        }
                    </div>
                }
                {(modulePos.params.client.active) &&
                    <div className="row">
                        <ActionClientBarLeft 
                            setValueInputClient={setInputClient}
                            valueInputClient={inputClient}
                            invalidInput={invalidInput}
                            setInvalidInput={setInvalidInput}
                            noRedInput={noRedInput}
                            load={load}
                            setLoad={setLoad}
                            style="col-9 position-relative"
                            from='giftcard'
                            module='pos'//à passer pour obligatoirement pour connaitre les options de son module 
                            clientId={clientId}
                            setClientId={setClientId}

                            posid={props.posid}
                            cookies={props.cookies}
                            currentTicket={props.currentTicket}
                            setCurrentTicket={props.setCurrentTicket}
                            setCookie={props.setCookie}
                            setClientNameGift={props.setClientNameGift}
                            searchInputClient={searchInputClient}
                        />
                    </div>
                }
                {(error) ? <div className="alert alert-danger mt-3">{t('allFieldsRequired')}</div> : false}
                <button disabled={validButton} onClick={()=>handleSubmit()} className="btn btn-primary mt-4">
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </div>
        </div>
    )
}

export default GiftCardForm;