import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'

const useLoad = (document) =>{
    const t = useTranslate();
    const [loadingListMail,setLoadingListMail]=useState(true)
    const [loadingTemplate,setLoadingTemplate]=useState(true)
    const [apiError,setApiError] = useState(false)
    const [emailConfig,setEmailConfig] = useState(false) 
    const [showForm,setShowForm] = useState(false)
    const [sendMailId,setSendMailId] = useState(false)
    const [emailContain,setEmailContain] = useState(t('defaultMail'))

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/mail/get_all_mail_config"
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                    setLoadingListMail(false)
                }else{
                    let confMail 
                    if(document.other_company!=null){
                        confMail = response.data.datas.filter(value=>value.other_company_id==document.other_company.id)
                    }else{
                        confMail = response.data.datas.filter(value=>value.other_company_id==null && value.section_id==null)
                    }
                    if(confMail){
                        if(confMail.length==1){
                            setShowForm(true)
                            setSendMailId(confMail[0].id)
                        }
                        setEmailConfig(confMail)
                    }
                    setLoadingListMail(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                setApiError(t(errorName))
                setLoadingListMail(false)
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/facture_option/get_all_options/"+document.type
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                    setLoadingTemplate(false)
                }else{
                    let tri_options=response.data.datas.filter(value=>value.type==document.type)
                    let configuration_email = tri_options.find(value=>value.cible=='mail')
                    if(configuration_email) setEmailContain(configuration_email.value)
                    setLoadingTemplate(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                setApiError(t(errorName))
                setLoadingTemplate(false)
            })
        })()
    }, [])

    return [loadingListMail,loadingTemplate,apiError,setApiError,emailConfig,showForm,setShowForm,sendMailId,setSendMailId,emailContain,setEmailContain]
}

const SendEmailForm = (props) => {
    const t = useTranslate();
    const [loadingListMail,loadingTemplate,apiError,setApiError,emailConfig,showForm,setShowForm,sendMailId,setSendMailId,emailContain,setEmailContain] = useLoad(props.document)
    const [validButton,setValidButton] = useState(false)
    const [inputMail,setInputMail] = useState('')

    const handleChange = (e,isfor) => {
        if(isfor=='mail') setInputMail(e.target.value)
        if(isfor=='emailcontain') setEmailContain(e.target.value)
    };

    const convertHtmlToText = (html) => {
        return html.replaceAll('<br />', '').replaceAll('<br>', '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setApiError(false)

        let allEmail = inputMail.split(',')

        let mailNoGood=true
        allEmail.forEach(value=>{
            if(!value.includes('@')) mailNoGood=false
        })

        let occurrences = emailContain.match(/\[bouton\]/g);       
        let textNoGood=true
        if (!occurrences || occurrences.length !== 1) textNoGood=false

        if(!mailNoGood){
            setApiError(t('errDestinataireEmail'))
        }else if(!textNoGood){
            setApiError(t('mustHave1Bouton'))
        }else{
            setValidButton(true)

            let obj = {
                destinataires:allEmail,
                url:process.env.REACT_APP_FREEBIRD_URL_EMAIL+"session/document/download/"+props.document.id+"/"+props.document.token,
                corps:emailContain
            }
            const response  = await axios.post(process.env.REACT_APP_API_URL+"api/mail/mail_b2b/"+props.document.id+"/"+sendMailId, obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
                else{
                    props.closeModal()
                }
            }).catch((error) => {
                if(error=='end_session') return;
                if(error.response==undefined){
                    setApiError(t('unknowError'))
                }
                else if(error.response.status==403){
                    setApiError(t('forbiddenGeneral'))
                }
                else {
                    setApiError(t('unknowError'))
                }
            })
            setValidButton(false)
            setInputMail('')
        }
    };

    const handleSelect = (e) => {
        if(e.target.value!='0'){
            let reduce = emailConfig.find(value=>value.id==e.target.value)
            setSendMailId(reduce.id)
            setShowForm(true)
        }
        else{
            setSendMailId(false)
            setShowForm(false)
        }
    }

    const addMail = (mail) => {
        let temp = inputMail;
        let str=(temp.length!=0) ?',' : '';
        str+=mail;
        temp+=str
        setInputMail(temp)

    }

    const OtherMails = ({mail_relance}) => {
        let mails = mail_relance.split(',')
        return(<>
            {mails.map((value,ind)=>
                <span key={ind}><button type="button" className="btn btn-sm btn-outline-primary" onClick={() => addMail(value)}>{value}</button>&nbsp;&nbsp;</span>
            )}
        </>)
    }

    return(<>
        {(loadingListMail || loadingTemplate) ?
            <span className='d-flex justify-content-center mt-3'><div className="spinner-border spinner-border-sm"></div></span>
        :
            <>
                {!emailConfig && <div className='alert alert-danger mb-2 row '><span className='text-center fw-bold'>{t('noEmailAdressConfigured')}</span></div>}
                {emailConfig.length>1 &&
                    <select className="form-control" value={sendMailId} onChange={(e) => handleSelect(e)}>
                        <option value="0">-- Select --</option>
                        {emailConfig.map(value=>
                            <option key={value.id} value={value.id}>{value.address}</option>
                        )}
                    </select>
                }
                {showForm &&
                    <form className="mt-3" onSubmit={(e)=>handleSubmit(e)}>
                        <textarea className="form-control" rows={10} value={convertHtmlToText(emailContain)} onChange={(e) => handleChange(e,'emailcontain')}></textarea>
                        <div className="form-group">
                            <label className='mb-2'>{t('recipient')+'(s)*'}</label><br/>
                            {props.document.client.email!=null &&
                                <><button type="button" className="btn btn-sm btn-outline-primary" onClick={() => addMail(props.document.client.email)}>{props.document.client.email}</button>&nbsp;&nbsp;</>
                            }
                            {props.document.client.infos.mail_relance &&
                                <OtherMails mail_relance={props.document.client.infos.mail_relance} />
                            }
                            <input disabled={!emailConfig} type="text" value={inputMail} placeholder='Emails (,)...' className='form-control mt-2' onChange={(e)=>handleChange(e,'mail')} />
                            <label>{t('addMoreRecipientsMsg')}</label>
                        </div>
                        {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                        <button disabled={!emailConfig || validButton || inputMail==''} className="btn btn-primary mt-3">
                            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('send')}
                        </button>
                    </form>
                }
            </>
        }
    </>
    )
}

export default SendEmailForm;