import React, {useState,useEffect,useContext, lazy, Suspense} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso,ProfilActionButton,Loading,DeleteActionButton,ColumDataTableReorganize,CarouselImage,FormatedPrice,ProfilParentRestockButton,articleColumnGenerate,customFieldsColumnGenerate,priceSuppColumnGenerate,enumereColumnGenerate,articleEditionDatatable,articleDataGenerator,SelectConvertItemsList,ExportArticleButton,AddMarkExportForm} from "@gull";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import axios from 'axios'
import swal from "sweetalert2"
import { IsAuthContext } from 'app/App';
import { useCookies} from 'react-cookie';
import {scrollXDataTable} from 'app/scrollXDataTable';
import { Modal } from "react-bootstrap";
import {articleListErp} from 'app/columnsArticleListeDataTable'

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columnShow.length)
    }, [window.screen.width,props.columnShow.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            defaultSortDirection={props.defaultSortDirection}
            hover
            condensed
            responsive
        />
    );
};

const ItemsListDetailTable = (props) => {
    const t = useTranslate();
    const [perPage,setPerPage]=useState(50)

    const [cookies, setCookie] = useCookies(['article_fields_List_Article','list_d_article_detail_reorganize']);
    const [lang, setLang] = useCookies(['lang']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    const { authParam } = useContext(IsAuthContext);
    const moduleErp = authParam.modules.find(value=>value.name=='erp')
    const maxConditionnement = (authParam.company.advanceparams.max_conditionnement!='' && authParam.company.advanceparams.max_conditionnement!=null) ? +authParam.company.advanceparams.max_conditionnement : 0
    const suppPrice = authParam.company.advanceparams.hasOwnProperty('prix_supplementaire') ? authParam.company.advanceparams.prix_supplementaire : []
    let fields = props.customFields ? props.customFields.map(value => value.name) : false

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let cookieLecture = ['internalid','name','reference','price','action','quantity','zone','bins'];
    if(cookies.article_fields_List_Article){
        let columnShowVar = cookies.article_fields_List_Article;
        cookieLecture = columnShowVar.split(',')
    }
    const [columnShow,setColumnShow] = useState(cookieLecture)   

    //partie qui concerne l'export et permet d'exporter le tableau dans l'ordre dans lequel il est affiché 
    const [headerColumnShowExport,setHeaderColumnShowExport]=useState('') 
    const [addMark,setAddMark]=useState(true) 
    useEffect(function () {
        let headerColumnShowExportArr = [] 
        
        columnOrganize.map(value=>{
            if(columnShow.includes(value.dataField)) headerColumnShowExportArr.push(value.dataField)
        })
        setHeaderColumnShowExport(headerColumnShowExportArr.join(',').replace('action,',''))
    }, [columnShow,columnOrganize])

    let headerAllExport = (cookies.list_d_article_detail_reorganize) ? cookies.list_d_article_detail_reorganize.replace('action,masque,','') : 'all'
    //End

    //Pour le carousel
    const [showCarousel,setShowCarousel]=useState(false) 
    const [modalItemName,setModalItemName]=useState('') 
    const [modalImage,setModalImage]=useState('') 

    const deleteListeArticle = async (articleDetailId) => {
        const response  = await axios.delete(process.env.REACT_APP_API_URL+"api/wms/article_list_detail/"+articleDetailId
        ).then((response) => {
            let listArticle = {...props.articleListInfo}
            let updateList = props.articleListInfo.article_list_detail.filter(value=>value.id!=articleDetailId)
            listArticle.article_list_detail = updateList

            props.setArticleListInfo(listArticle)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const openModalCarousel = (image,itemName) =>{
        setModalImage(image)
        setModalItemName(itemName)
        setShowCarousel(true)
    }

    const closeModalCarousel = () =>{
        setModalImage('')
        setModalItemName('')
        setShowCarousel(false)
    }

    let datas = new Array
    Object.keys(props.articleListInfo).length!=0 && props.articleListInfo.article_list_detail.map((value,ind)=>{
        let binsName = value.bins==null ? '' :
        (value.bins.fifth) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth+'-'+value.bins.fifth : 
        (value.bins.fourth) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth : 
        (value.bins.third) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third : 
        (value.bins.second) ? value.bins.first+'-'+value.bins.second : 
        value.bins.first 

        let obj = {
            masque:'',
            internalid: value.article.internalid,
            pr : value.article.pr,
            id: value.article.id,
            devise:(value.article.devise_achat!=null) ? value.article.devise_achat : '',//digit={(value.article.devise_achat!='XPF') ? 2 : 0}
            quantity:value.quantity,
            zone:value.zone.name,
            bins:binsName,
            action:
                <span className="d-flex justify-content-between">
                    <ProfilActionButton  path={'/erp/article/edit/'+value.article.id} openWindow={true}/> 
                    {(value.article.parent_name!=null && value.article.parent_name!='' && value.article.havestock) ? <ProfilParentRestockButton value={value} optionModule={moduleErp} path={'/erp/automatic-restock/parent-item/'+value.article.id}/> : false}
                    <DeleteActionButton deleteFunctionArgument={value.id} actionType={'typeRemoveX'} nameType={'remove'} toDelete={value.article.name} customClickEvent={deleteListeArticle}/>
                </span>,
            index: ind + 1,
        }
        articleDataGenerator(obj,value.article,t,openModalCarousel)
        props.customFields.forEach((item,index)=>{
            if(value.article.custom_fields){
                obj[item.name]=value.article.custom_fields[item.name]
            }
        })

        for (let i=0; i<maxConditionnement; i++){
            let numeroEnumere = i+1
            obj['name_'+numeroEnumere]= (value.article.hasOwnProperty('name_'+numeroEnumere) && value.article['name_'+numeroEnumere]!=null) ? value.article['name_'+numeroEnumere] : ''
            obj['reference_'+numeroEnumere]= (value.article.hasOwnProperty('reference_'+numeroEnumere) && value.article['reference_'+numeroEnumere]!=null) ? value.article['reference_'+numeroEnumere] : ''
            obj['quantity_'+numeroEnumere]= (value.article.hasOwnProperty('quantity_'+numeroEnumere) && value.article['quantity_'+numeroEnumere]!=null) ? value.article['quantity_'+numeroEnumere] : ''
        }

        suppPrice.map((item,index)=>{
            let numero = index+1
            let margePrixSupp = (value.article.hasOwnProperty('margeN_'+item['designation_'+numero]) && value.article['margeN_'+item['designation_'+numero]]!=null && value.article['margeN_'+item['designation_'+numero]]!=0) ? value.article['margeN_'+item['designation_'+numero]] : 0

            let margePercentPrixSupp = (value.article.hasOwnProperty('margeP_'+item['designation_'+numero]) && value.article['margeP_'+item['designation_'+numero]]!=null && value.article['margeP_'+item['designation_'+numero]]!=0) ? value.article['margeP_'+item['designation_'+numero]] : 0


            obj['price_'+item['designation_'+numero]]= (value.article.hasOwnProperty('price_'+item['designation_'+numero]) && value.article['price_'+item['designation_'+numero]]!==null && value.article['price_'+item['designation_'+numero]]!=='') ? value.article['price_'+item['designation_'+numero]] : ''

            obj['price_'+item['designation_'+numero]+'_ttc']= (value.article.hasOwnProperty('price_'+item['designation_'+numero]+'_ttc') && value.article['price_'+item['designation_'+numero]+'_ttc']!==null && value.article['price_'+item['designation_'+numero]+'_ttc']!=='') ? value.article['price_'+item['designation_'+numero]+'_ttc'] : ''

            obj['margeN_'+item['designation_'+numero]]= margePrixSupp

            obj['margeP_'+item['designation_'+numero]]= margePercentPrixSupp
        })
        datas.push(obj)
    })

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: datas.length
    };

    let columnsInit = [
        {
            dataField: "action",
            text: "Actions",
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
        },
        {
            dataField: "internalid",
            text: t('internalId'),
            editable: false,
            hidden: (columnShow.includes("internalid")) ? false : true,
            sort: false,
        },
        {
            dataField: "quantity",
            text: t('quantity'),
            hidden: (columnShow.includes("quantity")) ? false : true,
            sort: false,
        },
        {
            dataField: "zone",
            text: 'Zone',
            hidden: (columnShow.includes("zone")) ? false : true,
            sort: false,
        },
        {
            dataField: "bins",
            text: 'Bins',
            hidden: (columnShow.includes("bins")) ? false : true,
            sort: false,
        },
        {
            dataField: "pr",
            text: t('pr'),
            hidden: (columnShow.includes("pr")) ? false : true,
            formatter: (rowData) => (<FormatedPrice langRegion={'fr-FR'} devise={authParam.company.devise} digit={2} priceToFormat={rowData} />),
        },
    ];

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns=[
        ...columnsInit,
        ...articleColumnGenerate(articleListErp,columnShow,headerSortingStyle,articleEditionDatatable,props.optionsDataTable,props.ourOptionsDataTable,t,authParam),
        ...enumereColumnGenerate(maxConditionnement,columnShow,headerSortingStyle,articleEditionDatatable,t,authParam),
        ...priceSuppColumnGenerate(suppPrice,columnShow,headerSortingStyle,articleEditionDatatable,t,authParam),
        ...customFieldsColumnGenerate(props.customFields,columnShow,headerSortingStyle,articleEditionDatatable,t,authParam)
    ]

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.list_d_article_detail_reorganize){
            let cookieLecture = cookies.list_d_article_detail_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{//on boucle sur toutes les colonnes existante sur le cookie de réorganisation 
                columns.map(item=>{//on vérifie dans l'array columns si les colonnes qu'il contient sont présent dans le cookie 
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle colonne à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    arrCol.push(item);
                }
            })
            columnCookie = arrCol

            if(arrCol.length !== cookieLecture.length){ 
                //Si des données articles ont été ajoutées ou si une configuration d'access à été faite pour rajouter ou retirer des colonnes, le cookie de réorganisation sera mis à jour 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("list_d_article_detail_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [props.customFields,props.articleListInfo])

    useEffect(function () {
        if(cookies.article_fields_List_Article){
            let cookieColumnShow = cookies.article_fields_List_Article.split(',')
            let columnsDatas = [] //contient toutes les dataFields de columns
            let arrColumnShow = [] //contient toutes les données sans doublons
            let arrColumnShowDoublon = [] //contient les doublons
            let arrColumnShowIntrus = [] // continent les fausse données

            columns.map(item=>{
                columnsDatas.push(item.dataField)
            })

            cookieColumnShow.map(value=>{
                //on vérifie si le cookie contien des doublons
                if(!arrColumnShow.includes(value)){
                    arrColumnShow.push(value)
                }else{
                    //Si value existe dejà c'est qu'il y a un doublon 
                    arrColumnShowDoublon.push(value)
                }
                //on vérifie si les données du cookies sont des données qui exisent dans le tableau columns
                if(!columnsDatas.includes(value)) arrColumnShowIntrus.push(value)
                
            })

            if(arrColumnShowDoublon.length!=0 || arrColumnShowIntrus.length!=0){ 
                //Si le cookie contient des doublons et des données qui n'existe pas en meme temps
                let uniformData = arrColumnShow.filter(value=> !arrColumnShowIntrus.includes(value))

                setCookie("article_fields_List_Article",uniformData.join(),{ path: "/", expires: expire_date })
            }
        }
    }, [])

    const handleToggleColumns = (column,indexToEdit) => {
        if(column.toggle){
            var newColumnShow = columnShow.filter(value=>value!=column.dataField)

            //Modification pour la mise à jour des status hidden de chaque colonnes
            let dataColumn = columnOrganize.filter(value=>value.dataField!=column.dataField)
            let newData = column
            newData.hidden = true

            dataColumn.splice(indexToEdit,0, newData)

            setColumnOrganize(dataColumn)
            //End

            setColumnShow(newColumnShow)
            
        }
        else{
            var newColumnShow = [...columnShow,column.dataField]

            //Modification pour la mise à jour des status hidden de chaque colonnes
            let dataColumn = columnOrganize.filter(value=>value.dataField!=column.dataField)
            let newData = column
            newData.hidden = false

            dataColumn.splice(indexToEdit,0, newData)

            setColumnOrganize(dataColumn)
            //End

            setColumnShow(newColumnShow)
        }
        setCookie("article_fields_List_Article",newColumnShow.join(),{ path: "/", expires: expire_date }) 
    }

    return (<>
        <Modal show={showCarousel} onHide={()=>closeModalCarousel()}>
            <Modal.Header closeButton>
                <Modal.Title>{modalItemName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CarouselImage images={modalImage} />
            </Modal.Body>
        </Modal>
        <CardPerso header={Object.keys(props.articleListInfo).length!=0 ? t('listOfItems')+' : '+props.articleListInfo.name : t('listOfItems')+' :'}>
            <small>
                <ToolkitProvider
                keyField="index"
                data={datas}
                columnToggle
                columns={columnOrganize}
                >
                {(propst) => (
                    <>
                        <div className="row mb-3 d-flex justify-content-between">
                            <div className="col d-flex align-items-baseline">
                                <ColumDataTableReorganize 
                                    columnOrganize={columnOrganize}
                                    setColumnOrganize={setColumnOrganize}
                                    setCookie={setCookie}
                                    cookieName='list_d_article_detail_reorganize'
                                    { ...propst.columnToggleProps } 
                                    executWhenToggle={handleToggleColumns}
                                    showColumnOrganize={showColumnOrganize}
                                    setShowColumnOrganize={setShowColumnOrganize}
                                    btnSwitch={true}//pour affiche ou non le btn swith
                                    initSwith={false}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                                />&nbsp;&nbsp;&nbsp;
                                <ExportArticleButton filtre={{}} lang={lang.lang} addMark={addMark} header={headerColumnShowExport} title={t('downloadCSVSelection')} type="button" url={"api/wms/article_list/csvlist/"+props.id}></ExportArticleButton>&nbsp;&nbsp;&nbsp;
                                <ExportArticleButton filtre={{}} lang={lang.lang} addMark={addMark} header={headerAllExport} title={t('downloadCSVAll')} type="button" url={"api/wms/article_list/csvlist/"+props.id}></ExportArticleButton>&nbsp;&nbsp;&nbsp;
                                <AddMarkExportForm setAddMark={setAddMark} addMark={addMark} />
                            </div>
                            {props.convertIntoList &&
                                <div className="col-5">
                                    <SelectConvertItemsList
                                        listeArticle={props.articleListInfo}
                                        from='erp'
                                    />
                                </div>
                            }
                        </div>
                        {columnOrganize.length!=0 &&
                            <div className="position-relative">
                                <CustomDataTable 
                                    propst={propst}
                                    paginationOptions={paginationOptions}
                                    columnShow={columnShow}
                                    defaultSortDirection="asc"
                                />
                            </div>
                        }
                    </>
                )}
                </ToolkitProvider>
            </small>
        </CardPerso>
        </>
    );
}

export default ItemsListDetailTable;


