import React, {useState,useEffect} from "react";
import { Modal } from "react-bootstrap";
import { useTranslate } from 'react-redux-multilingual'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const ColumDataTableReorganize = (props) =>{
    const t = useTranslate();
    const [newColumnOrga,setNewColumnOrga]=useState([]) 
    const [switchAction,setSwitchAction]=useState(props.initSwith) 
    const [searchValue,setSearchValue] = useState('')
    
    useEffect(function () {
        if(props.showColumnOrganize){
            const input = document.querySelector('#colomnInput');
            if(input) input.focus()
        }
        
    }, [props.showColumnOrganize])

    useEffect(function () {
        if(searchValue!==''){
            let filteredList = props.columnOrganize.filter(value=>value.text.toLowerCase().includes(searchValue.toLowerCase()))
            setNewColumnOrga(filteredList)
        }else{
            setNewColumnOrga(props.columnOrganize)
        }

    }, [props.columnOrganize,searchValue])

    const handleChange = (e) => {
        setSearchValue(e.target.value)
    };

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        
        //ici on met à jour la nouvelle position dans la modal
        const column = Array.from(newColumnOrga);
        const [reorderedItem] = column.splice(result.source.index, 1);
        column.splice(result.destination.index, 0, reorderedItem);

        setNewColumnOrga(column);

        //ici on met à jour la nouvelle position dans le datatable 
        let arr = []
        column.map(value=>
            arr.push(value.dataField)
        )

        if(props.setColumnOrganize) props.setColumnOrganize(column)
        if(props.setCookie) props.setCookie(props.cookieName,arr.join(),{ path: "/", expires: expire_date }) 
    }  

    const closeModal = () =>{
        props.setShowColumnOrganize(false)
        setSearchValue('')
        if(props.btnSwitch) setSwitchAction(false)
    }

    const handleToggle = () => {
        setNewColumnOrga(props.columnOrganize)
        setSwitchAction(!switchAction)
        setSearchValue('')//Quand on switch entre la sélection de l'affichage ou le drag and drop, on réinitialise la recherche si il y en a une pour eviter les erreurs d'affichage 
    };
    
    return(<>
            <Modal show={props.showColumnOrganize} onHide={() => closeModal()}>
                <Modal.Header closeButton className="d-flex flex-wrap">
                    <Modal.Title>
                        {t('reorganizeColum')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {props.exportBtn && <button className="btn btn-dark" onClick={()=>props.exportSubmit()}>{t('downloadCSV')}</button>}
                    </Modal.Title><br />
                    {(props.btnSwitch) &&
                        <Form>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label={t('modifColumnPosition')}
                                onChange={()=>handleToggle()}
                            />
                        </Form>
                    }
                </Modal.Header>
                <Modal.Body>
                    {(!switchAction) ?
                        <div>
                            <div className="alert alert-info">{t('clickToHideOrShow', {param:t('column').toLowerCase()})}</div>
                            <div className="mt-1 bt-1 form-group">
                                <input autoComplete="off" id='colomnInput' placeholder={t('tableSearch')} type="text" className="form-control" onChange={(e)=>handleChange(e)} />
                            </div>
                            <ul style={{paddingLeft:"0", listStyle: 'none'}}>
                                {
                                newColumnOrga
                                    .map(column => ({
                                        ...column,
                                        toggle: (props.toggles) ? props.toggles[column.dataField] : {}
                                    }))
                                    .map((column,ind) => {
                                        if(column.dataField!='actions' && column.dataField!='action' && column.dataField!='masque' && column.dataField!='selectBtn'){
                                            let columnNameList = props.columnOrganize.map(value=>value.dataField)
                                            let realIndex = columnNameList.indexOf(column.dataField)

                                            return(
                                                <div className="row"  key={ column.dataField }>
                                                    <li
                                                        type="button"
                                                        className={ `btn btn-sm ${column.toggle ? "btn-info" : "btn-outline-primary"} mb-1 p-2` }
                                                        data-toggle="button"
                                                        aria-pressed={ column.toggle ? 'true' : 'false' }
                                                        onClick={ 
                                                            () => props.executWhenToggle(column,realIndex) 
                                                        }
                                                    >
                                                        <big>
                                                            { (column.text) && column.text.replace('*','') }
                                                        </big>&nbsp;&nbsp;
                                                        <span>{(column.toggle) ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}</span>
                                                    </li>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    :
                        <div>
                            <div className="alert alert-info">{t('dragAndDropToChangePosition', {param:t('column').toLowerCase()})}</div>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="column">
                                    {(provided) => (
                                        <ul  {...provided.droppableProps} ref={provided.innerRef} style={{paddingLeft:"0", listStyle: 'none'}}>
                                            {newColumnOrga.map((value, index) =>{
                                                if(value.dataField!='action' && value.dataField!='masque' && value.dataField!='actions'  && value.dataField!='selectBtn'){
                                                    if(!props.toggles){//Dans le cas où on ne peut pas masquer les colonnes, on retourne toutes les colonnes
                                                        return(
                                                            <Draggable key={"column"+value.dataField} draggableId={"column"+value.dataField} index={index}>
                                                                {(provided) => (
                                                                    <div className="row">
                                                                        <li 
                                                                            ref={provided.innerRef} 
                                                                            {...provided.draggableProps} 
                                                                            {...provided.dragHandleProps}
                                                                            className="btn btn-sm btn-primary mb-1 p-2"
                                                                        >
                                                                            <big>
                                                                                {value.text}
                                                                            </big>
                                                                        </li>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }else{//Dans le cas où on ne peut masquer les colonnes, on retourne que les colonnes visibles
                                                        if(props.toggles[value.dataField]){
                                                            return(
                                                                <Draggable key={"column"+value.dataField} draggableId={"column"+value.dataField} index={index}>
                                                                    {(provided) => (
                                                                        <div className="row">
                                                                            <li 
                                                                                ref={provided.innerRef} 
                                                                                {...provided.draggableProps} 
                                                                                {...provided.dragHandleProps}
                                                                                className="btn btn-sm btn-primary mb-1 p-2"
                                                                            >
                                                                                <big>
                                                                                    {value.text}
                                                                                </big>                                            
                                                                            </li>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        }
                                                    }
                                                }
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            {(!props.noButton) && <div className={`btn btn-sm ${props.showColumnOrganize ? 'btn-primary' : 'btn-outline-primary'}`} onClick={()=>props.setShowColumnOrganize(true)}>{t('colonnes')}</div>}
        </>
    )
}

export default ColumDataTableReorganize;

