import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from '../../../app/App';
import { espace_mille } from '../../../app/espace_mille';

const ObjectifSectionChart = (props) => {
  const t = useTranslate()
  const { authParam } = useContext(IsAuthContext);
  let devise = authParam.company.devise;
  let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;
  let obj;

  switch(props.isFor){
    case 'ca':
      obj={
        real: props.real.ca,
        ...(props.data && {objectif: props.data.ca}),
        unit: devise
      }
      break;
    case 'cattc':
      obj={
        real: props.real.cattc,
        ...(props.data && {objectif: props.data.cattc}),
        unit: devise
      }
      break;
    case 'pm':
        obj={
          real: props.real.pm,
          ...(props.data && {objectif: props.data.pm}),
          unit: devise
        }
        break;
    case 'pmttc':
        obj={
          real: props.real.pmttc,
          ...(props.data && {objectif: props.data.pmttc}),
          unit: devise
        }
        break;
    case 'iv':
        obj={
          real: props.real.iv,
          ...(props.data && {objectif: props.data.iv}),
          unit: t('items')
        }
        break;
    case 'tf':
        obj={
          real: (props.real.nbr_ticket!=0 && props.data && props.data.entre!=0) ? Math.round(props.real.nbr_ticket*100/Math.round(props.data.entre/2)) : 0,
          ...(props.data && {objectif: props.data.tf}),
          unit: "%"
        }
        break;
  }


  const options = {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          export: {
            csv: {
              columnDelimiter: authParam.company.csv_separator,
            }
          }
        }
      },
      colors: (obj.real>obj.objectif || !props.data) ? ['#49d761', '#0060fe'] : ['#fe0000', '#0060fe'],
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: "rounded",
          columnWidth: "75%"
        }
      },
      dataLabels: {
        enabled: false
      },

      ...(props.title && {title: {
        text: props.title,
        align: "center",
        margin: 10,
        offsetY: 20,
        style: {
          fontSize: "15px",
          fontWeight: "bold",
          color: "#263238"
        }
      }}),
    
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
        lineCap: "round"
      },
      series: (props.tabFilter && props.tabFilter=='period') ? [
        {
          name: t('realise'),
          data: [obj.real]
        },
      ] : [
        {
          name: t('realise'),
          data: [obj.real]
        },
        {
          name: t('objectifs'),
          data: [obj.objectif]
        }
      ],
      xaxis: {
        categories: [espace_mille(obj.real,digit)+' '+obj.unit, espace_mille(obj.objectif,digit)+' '+obj.unit]
      },
      fill: {
        opacity: 0.5,
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return espace_mille(val,digit)+' '+obj.unit
          }
        }
      }
  };

  if(props.isFor!='tf' && props.isFor!='iv'){
    options['yaxis'] = {
      title: {
        text: obj.unit
      },
      labels: {
        formatter: function (val) {
          return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(val);
        }
      }
    }
  }else{
    options['yaxis'] = {
      title: {
        text: obj.unit
      }
    }
  }

  return (
      <Chart
        options={options}
        series={options.series}
        type={options.chart.type}
      />
  );
}

export default ObjectifSectionChart;
