
import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { OverlayTrigger,Popover,ButtonToolbar } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

const DescriptionRecords = (props) => {
    const t = useTranslate();

    let dataDescription;
    
    switch (props.value.description) {
        case 'updatePoRecords':
            dataDescription = t('updatePoRecords')+' : '+props.value.po.name
            break;
        case 'insertPoRecords':
            dataDescription = t('insertPoRecords')+' : '+props.value.po.name
            break;
        case 'updateShipmentRecords':
            dataDescription = t('updateShipmentRecords')+' : '+props.value.shipment.numero_shipment
            break;
        case 'insertShipmentRecords':
            dataDescription = t('insertShipmentRecords')+' : '+props.value.shipment.numero_shipment
            break;
        case 'entreStockRecords':
            if(props.value.shipment){
                dataDescription = <>{t('po')+' : '+props.value.po.name}<br />{t('shipmentVoucher')+' : '+props.value.shipment.numero_shipment}</>
            }
            else if(props.value.po){
                dataDescription = <>{t('po')+' : '+props.value.po.name}</>
            }
            break;
        case 'simpleMouvement':
            dataDescription = <>{t('simpleMove')}</>
            break;
        case 'cancelEntreStockRecords':
            if(props.value.shipment){
                dataDescription = <>{t('po')+' : '+props.value.po.name}<br />{t('shipmentVoucher')+' : '+props.value.shipment.numero_shipment}</>
            }
            else if(props.value.po){
                dataDescription = <>{t('po')+' : '+props.value.po.name}</>
            }
            break;
        case 'ajustStock':
            dataDescription = (props.value.quota<0) ? t('stockRemove') : t('stockIntegration');
            break;
        case 'toPickedUp':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('collected')}</>
            break;
        case 'toPickedDown':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('inLivraison')}</>;
            break;
        case 'toDeposedUp':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('outLivraison')}</>
            break;
        case 'toDeposedDown':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('deposed')}</>;
            break;
        case 'toAjust':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('toCorrectionDeposed')}</>;
            break;
        case 'venteTicket':
            dataDescription = <>{t('vente')+' : '+props.value.ticket.bar}</>;
            break;
        case 'retourTicket':
            dataDescription = t('retour');
            break;
        case 'commandeChangeCollectZone':
            dataDescription = <>{t('commandeChangeCollectZone')+' : '+props.value.commande.bar}</>;
            break;
        case 'commandeOut':
            dataDescription = <>{t('commandeOut')+' : '+props.value.commande.bar}</>;
            break;
        case 'commandePut':
            dataDescription = <>{t('commandePut')+' : '+props.value.commande.bar}</>;
            break;
        case 'commandeCancelledOut':
            dataDescription = <>{t('commandeCancelledOut')} <br /> {props.value.commande.bar}</>;
            break;
        case 'commandeCancelledPut':
            dataDescription = <>{t('commandeCancelledPut')} <br /> {props.value.commande.bar}</>;
            break;
        case 'commandeRemovePut':
            dataDescription = <>{t('commandeRemovePut')} <br /> {props.value.commande.bar}</>;
            break;
        case 'commandeRemoveOut':
            dataDescription = <>{t('commandeRemoveOut')} <br /> {props.value.commande.bar}</>;
            break;
        case 'inventaireZone':
            dataDescription = t('inventaireZone');
            break;
        case 'inventaireBins':
            dataDescription = t('inventaireBins');
            break;
        case 'inventaireImport':
            dataDescription = t('inventaireImport');
            break;
        case 'reassortInterne':
            dataDescription = <>{t('internalRestock')}</> ;
            break;
        case 'factureCreate':
            dataDescription = <>{t('billing')+' : '+props.value.facture.bar}</>;
            break;
        case 'billingCreate':
            dataDescription = <>{t('billing')+' : '+props.value.facture.bar}</>;
            break;
        case 'avoirCreate':
            dataDescription = <>{t('creditNote')+' : '+props.value.facture.bar}</>;
            break;
        case 'ajustImport':
            dataDescription = <>{t('ajustImport')}</>;
            break;
        case 'resolveShopNegative':
            dataDescription = <>{t('resolveShopNegative')}</>;
            break;
        case 'massMouvement':
            dataDescription = <>{t('massMouvement')}</>;
            break;
        case 'insertToRecords':
            dataDescription = <>{t('insertToRecords')+' : '+props.value.to.reference}</>;
            break;
        case 'quotationCreate':
            dataDescription = <>{t('quotationCreate')+' : '+props.value.facture.bar}</>;
            break;
        case 'purchaseOrderB2bCreate':
            dataDescription = <>{t('purchaseOrderB2bCreate')+' : '+props.value.facture.bar}</>;
            break;
        case 'poB2bCollectedUp':
            dataDescription = <>{t('poB2bCollectedUp')}<br />{props.value.facture.bar}</>;
            break;
        case 'poB2bCollectedDown':
            dataDescription = <>{t('poB2bCollectedDown')}<br />{props.value.facture.bar}</>;
            break;
        case 'cancelledPoB2bCollectedUp':
            dataDescription = <>{t('cancelledPoB2bCollectedUp')}<br />{props.value.facture.bar}</>;
            break;
        case 'cancelledPoB2bCollectedDown':
            dataDescription = <>{t('cancelledPoB2bCollectedDown')}<br />{props.value.facture.bar}</>;
            break;
        case 'outStock':
            dataDescription = <>{t('outStock')}<br />{props.value.facture.bar}</>;
            break;
        case 'rnReceipt':
            dataDescription = <>{t('rnReceipt')}<br />{props.value.facture.bar}</>;
            break;
        case 'deliveryNoteCreate':
            dataDescription = <>{t('deliveryNote')+' : '+props.value.facture.bar}</>;
            break;
        case 'creditNoteCreate':
            dataDescription = <>{t('creditNoteCreate')+' : '+props.value.facture.bar}</>;
            break;
        case 'billingBuyCreate':
            dataDescription = <>{t('billingBuyCreate')+' : '+props.value.facture.bar}</>;
            break;
        case 'returnNoteCreate':
            dataDescription = <>{t('returnNoteCreate')+' : '+props.value.facture.bar}</>;
            break;
        case 'cancelledOutStock':
            dataDescription = <>{t('cancelledOutStock')+' : '+props.value.facture.bar}</>;
            break;
        case 'cancelledPoB2bCollectedDown':
            dataDescription = <>{t('cancelledPoB2bCollectedDown')+' : '+props.value.facture.bar}</>;
            break;
        case 'cancelledPoB2bCollectedUp':
            dataDescription = <>{t('cancelledPoB2bCollectedUp')+' : '+props.value.facture.bar}</>;
            break;
        case 'changeDocument':
            dataDescription = <>{t('changeDocument')+' : '+props.value.facture.bar}</>;
            break;
        default:
            dataDescription = <>''</>;
    }

    const popoverRight = (
        <Popover id={'pop'+props.value.id} title="Popover right" className='p-3'>
            {(props.value.memo!=null) &&
                <div dangerouslySetInnerHTML={{__html: props.value.memo}}></div>
            }
        </Popover>
    );


    return(<>
        {dataDescription}
        {(props.value.memo!=null) &&
            <>
                <br/>
                <ButtonToolbar>
                    <OverlayTrigger 
                        trigger="click" 
                        rootClose
                        placement="right"
                        overlay={popoverRight}
                    >
                        <span style={{cursor:'pointer'}}><FontAwesomeIcon icon={faQuestionCircle} /></span>
                    </OverlayTrigger>
                </ButtonToolbar>
            </>
        }
    </>)
}

export default DescriptionRecords;