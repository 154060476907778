import React, {useState,useContext,useEffect} from "react";
import {  useHistory } from "react-router-dom";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import {CurrencyLineForm} from '@gull'
import swal from "sweetalert2";
import axios from 'axios'
import { IsAuthContext } from 'app/App';

const CaisseForm = (props) =>{
    const t = useTranslate()
    const { authParam } = useContext(IsAuthContext)
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    const [apiError, setApiError] = useState(false)
    const [validButton, setValidButton] = useState(false)
    const history = useHistory();

    let devise = authParam.company.devise;
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    const [detailCash,setDetailCash] = useState({})
    const [total,setTotal] = useState(0)

    useEffect(function () {
        if(authParam.company.advanceparams.hasOwnProperty('ventilation_espece')){
            let obj = {}
            authParam.company.advanceparams.ventilation_espece.billet.map(billet=>{
                obj[billet]={
                    nbr:0,
                    multiple:+billet
                }
            })
            authParam.company.advanceparams.ventilation_espece.piece.map(piece=>{
                obj[piece]={
                    nbr:0,
                    multiple:+piece
                }
            })

            setDetailCash(obj)
        }
    }, [])

    useEffect(function () {
        let totalCumul = 0
        Object.entries(detailCash).map(([monnaie,valeur])=>{
            totalCumul += (+detailCash[monnaie].nbr * +monnaie)
        })
        setTotal(totalCumul)
    }, [detailCash])

    var colorTotal = (!props.fondCaisse && total == 280000) ? "text-success" : (total == props.fondCaisse) ? "text-success" : "text-danger"
    var totalSwal = (props.fondCaisse && total != props.fondCaisse) ? total-props.fondCaisse : total
    var titleSwal = (props.fondCaisse && total != props.fondCaisse) ? t('confirmDifferenceFondCaisse') : props.swalTitle

    const onSubmit= async (e) => {
        e.preventDefault()
        setValidButton(true)
        setApiError(false)
        
        let obj = {
            isfor: props.isfor,
            detail_mode_paiement:{
                espece:{
                    total:total,
                    prevu:props.fondCaisse
                }
            },
        }

        if(authParam.company.advanceparams.hasOwnProperty('config_open_close_pos') && authParam.company.advanceparams.config_open_close_pos=="repartition_auto_fond_caisse"){
            let fond_caisse_ventilation = {}

            Object.entries(detailCash).map(([monnaie,detail])=>{
                fond_caisse_ventilation[detail.multiple]=detail.nbr
            })

            obj['fond_caisse_ventilation']=fond_caisse_ventilation
        }

        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/pos/pos/"+props.posid+"/"+props.caisseIdentId, obj
            ).then((response) => {
                if(!response.data.valid){
                    setValidButton(false)
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'<br/>')
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
                else{
                    let localContenu={
                        open: response.data.data.open,
                        posid: response.data.data.id,
                        name: response.data.data.section.name,
                        logo: response.data.data.section.logo,
                        caisse_ident:props.getValues('caisse_ident')
                    }
                    if(props.isfor=='open'){
                        localStorage.setItem('pos',response.data.data.id+','+props.caisseIdentId)
                        localStorage.setItem('infosPos',JSON.stringify(localContenu))
                        history.push('/pos/'+props.posid)

                    }else{
                        localStorage.removeItem('pos')
                        localStorage.removeItem('infosPos')
                        history.push('welcome/welcome/')
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                setValidButton(false)
                if(!error.response){
                    swal.fire(t('unknowError'), "", "error");
                }
                else if(error.response.status===403){
                    swal.fire(t('forbiddenGeneral'), "", "error");
                }
                else {
                    swal.fire(t('unknowError'), "", "error");
                }
            }
        )
        
    }

    const sendForm = () => {
        var form = document.getElementById('formOpen');
        form.click();
    }

    const calcSum= (e,monnaie) => {
        setDetailCash({...detailCash,[monnaie]:{
            nbr:e.target.value,
            multiple:detailCash[monnaie].multiple
        }})
    }

    const onfocusAction = (inputId) =>{
        let element = document.getElementById(inputId)
        if(element) element.select()
    }

    return(<>
        {(Object.keys(detailCash).length!=0) ?
            <form onSubmit={(e)=>onSubmit(e)}>
                <>
                    {authParam.company.advanceparams.ventilation_espece.billet.map(billet=>{
                        return(
                            <CurrencyLineForm
                                key={billet}
                                devise={devise}
                                currency={billet}
                                onfocusAction={onfocusAction}
                                detailCash={detailCash}
                                calcSum={calcSum}
                                suppId=''
                            />
                        )
                    })}
                    {authParam.company.advanceparams.ventilation_espece.piece.map(piece=>{
                        return(
                            <CurrencyLineForm
                                key={piece}
                                devise={devise}
                                currency={piece}
                                onfocusAction={onfocusAction}
                                detailCash={detailCash}
                                calcSum={calcSum}
                                suppId=''
                            />
                        )
                    })}
                </>
                <div className="offset-8 col-4">
                    <p className="font-weight-bold" style={{float:"right"}}><strong>
                        <span className={colorTotal}>{new Intl.NumberFormat('fr-FR').format(total)} {devise}</span>
                    </strong></p>
                </div>
                {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                <button
                    disabled={validButton}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        swal
                        .fire({
                            title: titleSwal+" "+new Intl.NumberFormat('fr-FR').format(totalSwal)+' '+devise+' '+t('ofFondCaisse')+" ?",
                            text: "",
                            icon: "warning",
                            type: "question",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: t('btnYes'),
                            cancelButtonText: t('btnNo'),
                            allowOutsideClick: false,
                        })
                        .then((result) => {
                            if (result.value) {
                                sendForm()
                            }
                        });
                    }}
                >
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
                <button className="d-none" id="formOpen" >Envoi</button>
            </form>
        :
            t('noExistingCurrency')
        }
    </>
        
    )
}

export default CaisseForm;